import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BaseUrl from "../BaseUrl";
import PersonAddAltSharpIcon from "@mui/icons-material/PersonAddAltSharp";
import ClearIcon from "@mui/icons-material/Clear";
import LogoMark from "../images/logo11.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { Stack, Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
const Menubar = () => {
  const themes = useTheme();
  const [isOpenMenuBar, setIsOpenMenuBar] = useState(false);
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  const location = useLocation();
  const tabs = JSON.parse(localStorage.getItem("tabs"));
  // console.log("tabs: ", tabs);

  const isSuperUser = JSON.parse(localStorage.getItem("is_superuser"));
  // console.log("isSuperUser: ", typeof isSuperUser);

  const isUserIcon = (isSuperUser) => {
    if (isSuperUser === 1) {
      return true;
    } else {
      return false;
    }
  };

  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundColor: "#1c2427",
        padding: "0 1rem",
        position: "sticky",
        top: 0,
        zIndex: 99999,
        // backgroundColor: "red",
      }}
    >
      {/* 
      {
        (isSmall||isMedium)
      } */}

      {!isSmall || !isMedium ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 20,
              //  border: "2px solid blue"
            }}
          >
            {tabs.map((item) => {
              return (
                <div key={item.value} style={{}}>
                  <div
                    style={{
                      fontSize: "0.85rem",
                      color:
                        location.pathname === `/${item.value}`
                          ? "#d6ff41"
                          : "white",
                      cursor: "pointer",
                      borderBottom:
                        location.pathname === `/${item.value}`
                          ? "2px solid #d6ff41"
                          : "none",
                    }}
                    onClick={() => navigate(`/${item.value}`)}
                  >
                    {item.label}
                  </div>
                </div>
              );
            })}
      
          </div>

          {/* ########################### */}
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
              // border: "2px solid green",
            }}
          >
            <img
              height={"40px"}
              src={LogoMark}
              alt=""
              style={{ marginRight: 10,}}
            />
            {isUserIcon(isSuperUser) ? (
              <PersonAddAltSharpIcon
                sx={{ color: "#d6ff41", cursor: "pointer", fontSize: "30px" }}
                onClick={() => navigate("/users")}
              />
            ) : null}

            <LogoutIcon
              onClick={() => navigate("/logout")}
              title="Logout"
              sx={{ color: "#d6ff41", cursor: "pointer" }}
            />
          </div>
        </div>
      ) : (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                // border: "2px solid green",
              }}
            >
              <img
                height={"40px"}
                src={LogoMark}
                alt=""
                style={{ marginRight: 10, cursor: "pointer" }}
              />
              {/* <PersonAddAltSharpIcon
              sx={{ color: "#d6ff41", cursor: "pointer", fontSize: "30px" }}
              onClick={() => navigate("/users")}
            />

            <LogoutIcon
              onClick={() => navigate("/logout")}
              title="Logout"
              sx={{ color: "#d6ff41", cursor: "pointer" }}
            /> */}
            </div>
            <div>
              {!isOpenMenuBar ? (
                <MenuIcon
                  sx={{ color: "#d6ff41", marginTop: "28%" }}
                  onClick={() => {
                    setIsOpenMenuBar(!isOpenMenuBar);
                  }}
                />
              ) : (
                <ClearIcon
                  sx={{ color: "#d6ff41", marginTop: "28%" }}
                  onClick={() => {
                    setIsOpenMenuBar(!isOpenMenuBar);
                  }}
                />
              )}
            </div>
          </div>
          {isOpenMenuBar && (
            <div
              style={{
                borderTop: "1px solid #3E5056",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                padding: "2%",
              }}
            >
              <div
                style={{
                  // display: "flex",
                  // gap: 20,
                  //  border: "2px solid blue"
                  // textAlign: "center",
                }}
              >
                {tabs.map((item) => {
                  return (
                    <div key={item.value} style={{}}>
                      <div
                        style={{
                          // width:"200px",
                          fontSize: "0.85rem",
                          color:
                            location.pathname === `/${item.value}`
                              ? "#d6ff41"
                              : "white",
                          cursor: "pointer",
                          borderBottom:
                            location.pathname === `/${item.value}`
                              ? "none"
                              : "none",
                        }}
                        onClick={() => navigate(`/${item.value}`)}
                      >
                        {item.label}
                      </div>
                    </div>
                  );
                })}
              </div>
              {isUserIcon(isSuperUser) ? (
                <PersonAddAltSharpIcon
                  sx={{ color: "#d6ff41", cursor: "pointer", fontSize: "30px" }}
                  onClick={() => navigate("/users")}
                />
              ) : null}

              <LogoutIcon
                onClick={() => navigate("/logout")}
                title="Logout"
                sx={{ color: "#d6ff41", cursor: "pointer" }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Menubar;
