import "./DateInput.css"; // Import your CSS file for additional styling

const DateInput = ({
  type,
  value,
  onChange,
  min,
  max,
  setIsSubmit,
  setDATA
}) => {
  // Destructure props here
  // console.log("value inside date input: ", value);
  return (
      <input
        className="custom-date-picker"
        onChange={(e) => {
          onChange(e.target.value);
          setIsSubmit(false);
          setDATA([]);
        }}
        style={{
          outline: "none",
          border: "1px solid #3E5056",
          backgroundColor: "#1c2427",
          padding: "0.1rem 0.4rem",
          fontSize: "0.75rem",
          color: "white",
          borderRadius: "5px",
        }}
        type={type}
        value={value}
        min={min}
        max={max}
      />
  );
};

export default DateInput;
