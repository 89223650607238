import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
function Cascading({
  kpiFilterList,
  setDefaultKpiFilter2,
  setDisabledFlag,
  setFetchCallTrends,
  setFetchCallCamp,
  setIsSubmit,
  setFetchCall,
  setFetchCallLine,
  setFetchCallBaseline,
  setFetchCallFunnel,
  defaultKpiFilter2,
  setFetchCallMatchStack,
  defaultKpiFilter1,
  setDefauLabelKpiFilter2,
  defaulLabelKpiFilter2,
  setDefaultKpiFilter1,
  dropDownClick,
  setDropDownClick,
}) {
  const [showDescription, setShowDescription] = useState(false);
  const [clickIndex, setClickIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState();
  const [kpiValue, setKpiValue] = useState(kpiFilterList[0]?.kpis[0]?.label);
  const [kpiIndex, setKpiIndex] = useState(0);
  const [kpiValuesForApi, setKpiValuesForApi] = useState("");
  const jsonData = kpiFilterList;
  const [isHovered, setIsHovered] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  // console.log("kpiFilterList: ", kpiFilterList);

  function handleOnChange(e) {
    const searchText = e.target.value.toLowerCase(); // Convert search text to lower case
    setSearchText(e.target.value); // Preserve the original case for display purposes

    if (jsonData && jsonData[clickIndex] && jsonData[clickIndex].kpis) {
      const data = jsonData[clickIndex].kpis.filter((ele) => {
        return ele.label.toLowerCase().includes(searchText); // Convert label to lower case for comparison
      });
      setSearchData(data);
    } else {
      // console.warn("Invalid jsonData or clickIndex");
    }
    if (e.target.value === "") {
      setSearchData([]);
    }
  }

  useEffect(() => {
    setKpiValue(kpiFilterList[0]?.kpis[0]?.label);
  }, [kpiFilterList]);

  useEffect(() => {
    setDefauLabelKpiFilter2(kpiFilterList[0]?.label);
  }, []);

  const toggleDropDown = () => {
    setDropDownClick(!dropDownClick);
    setShowDescription(false);
  };

  // console.log("kpi filter list", kpiFilterList);

  const selectOption = (index, value) => {
    setShowDescription(true);
    setClickIndex(index);
  };

  const handleClick = (index, value) => {
    if (showDescription === true) {
      selectOption(index, value);
    } else {
      setShowDescription(true);
    }
  };

  return (
    <div style={{ position: "absolute", zIndex: 999999, top: -6.2 }}>
      <div onClick={toggleDropDown}>
        <div
          style={{
            width: "150px",
            border: "1px solid #3E5056",
            display: "flex",
            borderRadius: "4px",
            justifyContent: "space-between",
            whiteSpace: "nowrap",
            alignItems: "center",
            cursor: "pointer",
            color: "white",
            fontSize: "0.85rem",
            padding: "0.1rem 0.5rem",
            backgroundColor:
              defaulLabelKpiFilter2 === kpiFilterList[0]?.label
                ? "#d6ff41"
                : "",
          }}
        >
          <div
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "90%",
            }}
          >
            {defaulLabelKpiFilter2}
          </div>
          {dropDownClick ? (
            <ArrowDropUpIcon onClick={toggleDropDown} />
          ) : (
            <ArrowDropDownIcon onClick={toggleDropDown} />
          )}
        </div>
      </div>
      {dropDownClick && (
        <div style={{ display: "flex" }}>
          <div
            style={{
              border: "1px solid #3E5056",
              backgroundColor: "#1C2427",
              color: "white",
              fontSize: "0.85rem",
              padding: "3%",
              maxHeight: "250px",
              overflowX: "scroll",
              minWidth: "150px",
            }}
          >
            {jsonData.map((ele, index) => (
              <Box
                key={index}
                onClick={() => {
                  handleClick(index, ele.value);
                  if (ele.label === jsonData[clickIndex]?.label) {
                    setShowDescription(!showDescription);
                  } else {
                    selectOption(index, ele.value);
                  }
                }}
                sx={{
                  cursor: "pointer",
                  backgroundColor: index === clickIndex ? "#d6ff41" : "",
                  color: index === clickIndex ? "black" : "",
                  display: "flex",
                  justifyContent: "space-between",
                  "&:hover": {
                    backgroundColor:
                      index === clickIndex ? "#d6ff41" : "#3e5056",
                  },
                }}
              >
                <div>{ele.label}</div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {clickIndex === index && showDescription ? (
                    <div>
                      <div>-</div>
                    </div>
                  ) : (
                    <div>
                      <div>+</div>
                    </div>
                  )}
                </div>
              </Box>
            ))}
          </div>
          {showDescription && (
            <Box
              sx={{
                border: "1px solid #3E5056",
                backgroundColor: "#1C2427",
                color: "white",
                fontSize: "0.85rem",
                padding: "3%",
                maxHeight: "250px",
                overflowY: "scroll",
                minWidth: "250px",
                "&::-webkit-scrollbar": {
                  width: "6px",
                },

                "&::-webkit-scrollbar-track": {
                  background: "#1C2427",
                },

                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "gray",
                  borderRadius: "10px",
                },
              }}
            >
              <Box
                sx={{
                  border: "1px solid #3E5056",
                  width: "100%",
                  // padding: "2%",
                  marginBottom: "6px", // Added for spacing between the search box and the results
                  // borderRadius: "4px", // Rounded corners for the search box
                  backgroundColor: "#2C2C2C", // Background color for the search box
                  // height: "14%",
                  borderRadius: "4px",
                }}
              >
                <input
                  type="search"
                  value={searchText}
                  onChange={handleOnChange}
                  placeholder="Search"
                  style={{
                    width: "100%", // Make the input take the full width of the box
                    // padding: "8px", // Padding inside the input
                    border: "none", // Remove default border
                    outline: "none", // Remove outline on focus
                    borderRadius: "4px", // Rounded corners for the input
                    // fontSize: "1rem", // Font size for the input text
                    color: "#FFFFFF", // Text color
                    backgroundColor: "#1c2427", // Transparent background to match box
                  }}
                />
              </Box>

              <Box>
                {(searchData.length > 0
                  ? searchData
                  : jsonData[clickIndex]?.kpis
                )?.map((ele, index) => (
                  <Box
                    key={index}
                    onClick={() => {
                      setSelectedValue(ele.label);
                      setDropDownClick(false);
                      setShowDescription(false);
                      setKpiIndex(index);
                      setKpiValue(ele.label);
                      setDefaultKpiFilter2(ele.value);
                      setDisabledFlag(true);
                      setFetchCallTrends(true);
                      setFetchCallCamp(true);
                      setIsSubmit(true);
                      setFetchCall(true);
                      setFetchCallLine(true);
                      setFetchCallBaseline(true);
                      setFetchCallFunnel(true);
                      setFetchCallMatchStack(true);
                      setDefauLabelKpiFilter2(ele.label);
                    }}
                    sx={{
                      cursor: "pointer",
                      backgroundColor:
                        kpiIndex === index && kpiValue === ele.label
                          ? "#d6ff41"
                          : "",
                      color:
                        kpiIndex === index && kpiValue === ele.label
                          ? "black"
                          : "",
                      "&:hover": {
                        backgroundColor:
                          kpiIndex === index && kpiValue === ele.label
                            ? "#d6ff41"
                            : "#3e5056",
                      },
                    }}
                  >
                    {ele.label}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </div>
      )}
    </div>
  );
}

export default Cascading;
