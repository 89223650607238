import { remove } from 'ol/array';
import React, { useState, useRef, useEffect,useContext } from 'react';
// import appContext from '../context/appContext';
const CascaderFocusWrapper = ({ children, componentId,isFocused, setIsFocused }) => {
  
  // const context = useContext(appContext);
  // const {setdisableBtn}=context;
  // const [] = useState(false);
  useEffect(() => {
    const elements = document.getElementsByClassName('no-cursor');
  
    for (let i = 0; i < elements.length; i++) {
      if (isFocused) {
        elements[i].style.cursor = 'none';
        elements[i].style.pointerEvents = 'none';
      } else {
        elements[i].style.removeProperty('cursor');
        elements[i].style.removeProperty('pointer-events');
      }
    }
  }, [isFocused]);
  
  const wrapperRef = useRef(null);
 
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsFocused(false);
      }
    };
 
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
 
  return (
    <span
      ref={wrapperRef}
      style={{
        position: 'relative',
        display: 'block',
        zIndex: isFocused ? 1000 : 1,
        width: 'fit-content',
        minWidth: '100%'
      }}
      onFocus={() => setIsFocused(true)}
    >
      {isFocused && (
        <span
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            backdropFilter: 'blur(2px)',
            WebkitBackdropFilter: 'blur(2px)',
            zIndex: -1,
            pointerEvents: 'none'
          }}
        />
      )}
      <span style={{
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        backgroundColor: isFocused ? 'transparent' : 'transparent',
        isolation: 'isolate'
      }}>
        {children}
      </span>
    </span>
  );
};
 
export default CascaderFocusWrapper;
 