import React, { useState, useEffect, useRef } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
// import configDriven from "../ConfirgDriven";

const Cascader = ({
  data,
  searchValue,
  setSearchValue,
  selectedPath,
  setSelectedPath,
  setIdList,
  pathName,
  level = 0,
  st = 10000,
  setGetBrand,
  setIsCascaderVisible,
  setIsFocused

}) => {
//   const displayNames = JSON.parse(localStorage.getItem("displayNames"));

//   const returnDisplayName = (id) => {
//     if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
//       return displayNames[id];
//     } else {
//       return id;
//     }
//   };
  const expandedCategoryRef = useRef(null);
  const timerRef = useRef(null);
  const rootRef = useRef(null);
  const child = useRef(null);
  const span = useRef(null);
  const [isBottom, setIsBottom] = useState(false);
  const [flag, setFlag] = useState(false);
  const [style, setStyle] = useState({});
  const [height, setHeight] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedExpandIcon, setSelectedExpandIcon] = useState(null);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [hit, setHit] = useState(false);
  const [hover, setHover] = useState(false);

  const handleCategoryButtonClick = (categoryId, category, event) => {
    
    console.log("path-name",pathName);
    console.log("categoryy ", category);

    if (category.access == 1) {
      setGetBrand(category);
      setIsCascaderVisible(false);
      setIsFocused(false);
      console.log("categoryID: ", categoryId);

      event.stopPropagation();
      setSelectedExpandIcon((prevCategory) =>
        prevCategory === categoryId ? null : categoryId
      );

      // expandedCategoryRef.current = event.currentTarget;
      const pathSegments = categoryId.split("-");
      const newPath = pathSegments.map((segment, index) =>
        pathSegments.slice(0, index + 1).join("-")
      );

      setIdList(newPath);
      setSearchValue(pathName);

      if (categoryId !== selectedCategory) {
        setSelectedCategory(null);
        setSelectedExpandIcon(null);
        setSelectedPath(newPath);
        setSelectedCategory(categoryId);
        setSelectedExpandIcon(categoryId);
      } else {
        setSelectedExpandIcon(null);
        setSelectedPath((prevSelectedPath) => {
          if (prevSelectedPath.length >= newPath.length) {
            return newPath.slice(0, pathSegments.length);
          }
          return newPath;
        });
        setSelectedCategory((prevSelectedCategory) => {
          const prevPathSegments = prevSelectedCategory
            ? prevSelectedCategory.split("-")
            : [];
          if (prevPathSegments.length >= pathSegments.length) {
            return pathSegments.join("-");
          }
          return prevSelectedCategory;
        });
      }
    }
  };

  const handleExpandIconHover = (categoryId, event) => {
    console.log("CatergoryId on hover hoveredCategorycheckpos ", categoryId);

    setHover(true);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setHoveredCategory(categoryId);
    setSelectedExpandIcon(categoryId);

    expandedCategoryRef.current = event.currentTarget;

    setSelectedCategory(categoryId);
  };

  const handleExpandIconLeave = (event) => {
    timerRef.current = setTimeout(() => {
      // setFlag(false)
      setHover(false);
      setHoveredCategory(null);
      setSelectedExpandIcon(null);
      setSelectedCategory(null);
      //   console.log(event.target?.parentNode)
      //   rootRef.current=event.target.parentNode;
    }, 0);
  };

  useEffect(() => {
    console.log("hoveredCategorycheckpos ", hoveredCategory);

    const checkPosition = () => {
      if (!rootRef.current || hoveredCategory == null) return;

      const store = rootRef.current;
      const bottomBoundary = store.getBoundingClientRect().bottom;
      const childDivs = document.querySelectorAll(`.${hoveredCategory}`);
      const hoverParent = document.querySelectorAll(`#${selectedCategory}`);
      console.log(
        "root Div ",
        store,
        " ",
        selectedCategory,
        "   ",
        hoverParent[0]
      );
      console.log("Max height:", bottomBoundary);
      console.log("Child Divs for hovered category:", childDivs);

      // Reset styles for previously hovered elements
      childDivs.forEach((div) => {
        div.style.position = "absolute";
        const topPosition = hoverParent[0].getBoundingClientRect().top;
        // div.style.top = `${topPosition}px`;
        div.style.border = ""; // Reset custom border
        if (div.style.right !== "") {
          // div.style.top = "unset";
        }
        div.style.right = "";
        // div.style.top=`${hoverParent[0]?.getBoundingClientRect()?.top()}px`
        // div.style.left = `${hoverParent[0].getBoundingClientRect().right}px`;

        // div.style.position=div.style.position=='fixed'?'':div.style.position;
        // div.style.bottom = ''; // Reset positioning
      });

      childDivs.forEach((div) => {
        const divBottom = div.getBoundingClientRect().bottom;
        const divTop = div.getBoundingClientRect().top;
        const divRight = div.getBoundingClientRect().right;
        const divWidth = div.getBoundingClientRect().width;
        const parentWidth = store.getBoundingClientRect().width;
        const parentLeft = store.getBoundingClientRect().left;
        const parentTop = store.getBoundingClientRect().top;

        const topPosition = hoverParent[0].getBoundingClientRect().top;

        console.log(
          "Comparing bottom positions:",
          div,
          "    ",
          hoverParent,
          "   ",
          topPosition,
          "   ",
          divTop
        );

        let rightValue = window.innerWidth - parentLeft + 20 - 2 - 1 - 1 - 1;
        const divHeight = window.innerHeight - divTop;
        // Check if the child div exceeds the root container or window height

        console.log(
          "DivHeight ",
          divHeight,
          "  ",
          divBottom,
          " ",
          window.innerHeight
        );

        if (divBottom > window.innerHeight) {
          div.style.height = `${divHeight}px`; // Set the height to fit within the viewport
          div.style.overflowY = "scroll"; // Allow vertical scrolling if content exceeds the height
          div.style.overflowX = "hidden";
          rightValue = window.innerWidth - parentLeft - 2;

          // divBottom > st ||
          // div.style.overflowY='auto';
          // div.style.border = '2px solid orange';
          // div.style.top = `${div.getBoundingClientRect().top}px`;
          // div.style.bottom = '0px'; // Adjust position to bottom
          // div.style.border = '2px solid orange'; // Add custom border
        }
        
        // Check if the child div exceeds the window's width (right boundary)
        if (divRight > window.innerWidth) {
          // div.style.top = `${topPosition}px`;
          // To align the div to the left border of its parent

          div.style.position = "fixed";
          div.style.left = "";
          div.style.right = `${rightValue}px`;
          // div.style.top = `${divBottom - div.getBoundingClientRect().top - 10}px`;
          
          // div.style.top = `${div.getBoundingClientRect().top}px`;

          div.style.zIndex = "99999"; // Ensure higher z-index to prevent overlap issues
          // rootRef.current.style.backgroundColor = 'gray'; // Example background change
          // div.style.border=`solid red 2px`;

          console.log("Div touched the right boundary:", div);
          // setHit(true); // Flag that right boundary has been hit
        }
      });

      // Update height for level 1 categories if not already set
      if (level == 0) {
        if (height == 0) {
          setHeight(
            childDivs.length > 0
              ? childDivs[childDivs.length - 1].getBoundingClientRect().bottom
              : st
          );

          console.log(
            "asfoihasifjhask;jfhasjdhf",
            childDivs,
            "  ",
            level,
            st,
            height
          );
        }
      }

      // Iterate through each hovered child div to adjust styling based on its position
      childDivs.forEach((div) => {
        const divBottom = div.getBoundingClientRect().bottom;
        const divRight = div.getBoundingClientRect().right;
        const divWidth = div.getBoundingClientRect().width;
        const parentWidth = store.getBoundingClientRect().width;
        const parentLeft = store.getBoundingClientRect().left;
        const parentTop = store.getBoundingClientRect().top;

        const topPosition = hoverParent[0].getBoundingClientRect().top;

        console.log(
          "Comparing bottom positions:",
          store,
          parentWidth,
          " ",
          window.innerWidth,
          " ",
          parentLeft,
          " ",
          topPosition,
          "    ",
          divBottom
        );

        const rightValue = window.innerWidth - parentLeft + 20 - 2 - 1 - 1 - 1;
        // Check if the child div exceeds the root container or window height
        if (divBottom > st || divBottom > window.innerHeight) {
          // div.style.top = `${div.getBoundingClientRect().top}px`;
          // div.style.bottom = '0px'; // Adjust position to bottom
          // div.style.border = '2px solid orange'; // Add custom border
        }
        // div.style.top = `${topPosition}px`;
        // Check if the child div exceeds the window's width (right boundary)
        if (divRight > window.innerWidth) {
          // div.style.top = `${topPosition}px`;
          // To align the div to the left border of its parent

          div.style.position = "fixed";
          div.style.left = "";
          div.style.right = `${rightValue}px`;
          // div.style.top = `${divBottom - div.getBoundingClientRect().top - 10}px`;
          div.style.top = `${topPosition}px`;

          div.style.zIndex = "99999"; // Ensure higher z-index to prevent overlap issues
          // rootRef.current.style.backgroundColor = 'gray'; // Example background change
          // div.style.border=`solid red 2px`;

          console.log("Div touched the right boundary:", div);
          // setHit(true); // Flag that right boundary has been hit
        }
      });

      setFlag(true); // Set flag once all adjustments are done
    };

    // Perform initial position check
    checkPosition();
  }, [rootRef.current, child.current, span.current, hoveredCategory]);

  // const theme = localStorage.getItem("theme");

  // const darkThemeColors = {
  //   background: "#d6ff41",
  //   textSelected: "black",
  //   textUnselected: "white",
  //   backgroundSelected: "#D6FF41",
  //   backgroundExpandIcon: "gray",
  //   backgroundHitFalse: "#3e5056",
  //   textExpandIcon: "#D6FF41",
  // };

  // const lightThemeColors = {
  //   background: "#0a2472",
  //   textSelected: "white",
  //   textUnselected: "#0a2472",
  //   backgroundSelected: "#093649",
  //   backgroundExpandIcon: "#ADE8F4",
  //   backgroundHitFalse: "#f7feff",
  //   textExpandIcon: "#093649",
  // };

  // const colors = theme === "dark" ? darkThemeColors : lightThemeColors;

  return (
    <div style={{ position: "relative" }}>
      <div
        ref={rootRef}
        style={{
          paddingLeft: "2px",
          // backgroundColor: colors.background,
          overflowY: "auto",
          whiteSpace: "nowrap",
          minWidth: "calc(100% + 20px)",
          zIndex: "99999",
          overflow: "visible",
        }}
        className="root text-background"
      >
        {data?.map((category) => (
          <div key={category.id} style={{ marginBottom: "0px", width: "100%" }}>
            <div style={{ display: "flex", widtth:'100%'}}>
              <div
                className={`cascader-item cm-filter-title 
    ${
      selectedPath?.includes(category.id) ? "selected-path" : "unselected-path"
    } 
    ${selectedExpandIcon === category.id ? "expand-icon-selected" : ""} 
    ${hit === false ? "hit-false" : ""}
    ${category.access === 0 ? "no-access" : "access"}`}
                id={`${category.id}`}
                onMouseEnter={(e) => handleExpandIconHover(category.id, e)}
                onClick={(event) =>
                  handleCategoryButtonClick(category.id, category, event)
                }

                style={{marginBottom:0}}
              >
                <span
                  className="cm-filter-title"
                  style={{
                    flexGrow: 1,
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "2px",
                    marginBottom:0
                  }}
                >
                  {/* {returnDisplayName(category.id.split("-").pop())} */}
                  {category.name}
                </span>
                {category?.children?.length > 0 && (
                  <span
                    ref={span}
                    style={{
                      cursor: "pointer",
                      textAlign: "right",
                      font: "small",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onMouseEnter={(e) => handleExpandIconHover(category.id, e)}
                    className={`expand-icon 
                    ${
                      selectedPath?.includes(category.id)
                        ? "text-selected"
                        : "text-expand-icon"
                    } 
                    ${
                      selectedExpandIcon === category.id && hover === true
                        ? "arrow-icon-hovered"
                        : ""
                    }`}
                  >
                    {selectedExpandIcon === category.id && hover == false ? (
                      <KeyboardArrowLeftOutlinedIcon />
                    ) : (
                      <KeyboardArrowRightOutlinedIcon />
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {selectedExpandIcon &&
        data?.find((cat) => cat.id === selectedExpandIcon)?.children?.length >
          0 &&
        expandedCategoryRef.current && (
          <div
            ref={child}
            onMouseEnter={(e) => handleExpandIconHover(selectedExpandIcon, e)}
            style={{
              position: "absolute",
              left: "calc(100% + 20px)",
              top: `${expandedCategoryRef.current?.offsetTop}px`,
              zIndex: 1000 + level,
            }}
            className={selectedExpandIcon || ""}
          >
            <Cascader
              data={data.find((cat) => cat.id === selectedExpandIcon).children}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              selectedPath={selectedPath}
              setSelectedPath={setSelectedPath}
              setIdList={setIdList}
              pathName={pathName}
              level={level + 1}
              st={level == 1 ? height : st}
              setGetBrand={setGetBrand}
              setIsCascaderVisible={setIsCascaderVisible}
              setIsFocused={setIsFocused}
            />
          </div>
        )}
    </div>
  );
};

const CascaderWrapper = ({ data, match, setGetBrand,setIsFocused }) => {
  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
    return id;
    // if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
    //   return displayNames[id];
    // } else {
    //   return id;
    // }
  };

  console.log("MATCH cascader",match);
  const [searchValue, setSearchValue] = useState("");
  const [isCascaderVisible, setIsCascaderVisible] = useState(false);
  const [selectedPath, setSelectedPath] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const cascaderRef = useRef(null);
  const searchBarRef = useRef(null);
  const [idList, setIdList] = useState([]);
  const [nameList, setNameList] = useState([]);
  const [pathName, setPathName] = useState("");
  const [showToolTip, setShowToolTip] = useState(false);

  function flattenDataToObject(data) {
    let result = {};

    function flatten(item) {
      result[item?.id] = item?.name; 
      if (item?.children) {
        item?.children?.forEach(flatten);
      }
    }

    data?.forEach(flatten);
    return result;
  }

  const flattenedData = flattenDataToObject(data);
  console.log("flattenedData",flattenedData)

  function getTeaList(idList, flattenedData) {
    return idList?.map((key) => flattenedData[key]);
  }

  useEffect(() => {
    const result = getTeaList(idList, flattenedData);
    setNameList(result);
  }, [idList]);

  useEffect(() => {
    if (nameList) {
      console.log("nameList",nameList)
      const names = nameList.join(" / ");
      setPathName(names);
      setSearchValue(names);
    } else {
      setPathName("");
      setSearchValue("");
    }
  }, [nameList]);

  useEffect(() => {
    
    const pathSegments = match?.split("-");
    const newPath = pathSegments?.map((segment, index) =>
      pathSegments.slice(0, index + 1).join("-")
    );
    setSelectedPath(newPath);
    
    const result = getTeaList(newPath, flattenedData);
    setNameList(result);

    if (nameList) {
      const names = nameList.join(" / ");
      setPathName(names);
      setSearchValue(names);
    } else {
      setPathName("");
      setSearchValue("");
    }
  }, []);

  const toggleCascaderVisibility = () => {
    setIsCascaderVisible(!isCascaderVisible);
  };

  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    setSearchValue(searchTerm);
    if (searchTerm.trim() === "") {
      setSearchResults([]);
      setSearchValue("");
      setIsDropDownVisible(false);
    } else {
      const matchingResults = findMatchingResults(data, searchTerm);
      setSearchResults(matchingResults);
      setIsDropDownVisible(true);
    }
  };

  const handleSearchResultClick = (result) => {
    console.log("results ", result);
    const findCategoryById = (data, id) => {
      for (let category of data) {
        if (category.id === id && category.access == 0) return null;
        if (category.id === id && category.access == 1) {
          return category;
        }
        if (category.children) {
          const found = findCategoryById(category.children, id);
          if (found) {
            return found;
          }
        }
      }
      return null;
    };

    const fullCategory = findCategoryById(data, result.id);
    console.log("fullCategory ", fullCategory);
    if (fullCategory != null) {
      setGetBrand(fullCategory);

      console.log("resultss ", result);
      const pathSegments = [];
      const idSegments = result.id.trim().toLowerCase().split("-");
      for (let i = idSegments.length; i > 0; i--) {
        pathSegments.push(idSegments.slice(0, i).join("-"));
      }

      result.path.forEach((segment) => {
        const segments = segment.trim().toLowerCase().split("-");
        for (let i = segments.length; i > 0; i--) {
          pathSegments.push(segments.slice(0, i).join("-"));
        }
      });

      const n = pathSegments.length / 2;
      pathSegments.splice(-n);

      const pathName = result.path.join(" / ");
      setSearchValue(pathName);
      setSelectedPath(pathSegments);
      setIsDropDownVisible(false);
    }
  };

  const findMatchingResults = (data, searchTerm) => {
    const matchingResults = [];
    const traverse = (children, path) => {
      children.forEach((item) => {
        const currentPath = path
          ? `${path} / ${returnDisplayName(item?.id.split("-").pop())}`
          : returnDisplayName(item?.id.split("-").pop());
        if (
          item.access == 1 &&
          returnDisplayName(item?.id)
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) {
          matchingResults.push({ path: currentPath.split(" / "), id: item.id });
        }
        if (item.children) {
          traverse(item.children, currentPath);
        }
      });
    };
    traverse(data, "");
    return matchingResults;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropDownVisible(false);
      }
      if (cascaderRef.current && !cascaderRef.current.contains(event.target)) {
        if (
          !searchBarRef.current ||
          !searchBarRef.current.contains(event.target)
        ) {
          if (!event.target.classList.contains("cascader-toggle-button")) {
            setIsCascaderVisible(false);
          }
        }
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  // const theme = localStorage.getItem("theme");

  return (
    <div style={{ position: "relative" }}>
      <div ref={cascaderRef}>
        <div style={{ display: "flex", alignchildren: "center" }}>
        <div style={{ position: "relative", display: "flex" }}>
  <div style={{ position: "relative" }}>
    <input

      ref={searchBarRef}
      id="search-bar"
      className="text-colors background-colors cm-filter-title"
      type="text"
      title={searchValue}
      value={searchValue}
      onChange={handleSearchInputChange}
      placeholder="Select"
      autocomplete="off"
      style={{
        width: "100%",
        position: "relative",
        border: "1px solid #3e5056",
        borderRadius: "4px",
        padding: "2px",
        maxWidth:'11vw',
        paddingRight: "20px", // Make space for the arrow
        outline: "none",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}
      onMouseEnter={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
    />
    <button
      onClick={toggleCascaderVisibility}
      className="text-colors cm-filter-title cascader-toggle-button"
      style={{
        position: "absolute",
        right: "2px",
        top: "50%",
        transform: "translateY(-50%)",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        padding: "0",
        display: "flex",
        alignItems: "center",
        zIndex: "1"
      }}
    >
      {isCascaderVisible ? (
        <KeyboardArrowUpOutlinedIcon />
      ) : (
        <KeyboardArrowDownOutlinedIcon />
      )}
    </button>
  </div>
</div>
        </div>

        {isCascaderVisible && (
          <div
            className="theme-color"
            ref={cascaderRef}
            style={{
              position: "absolute",
              // backgroundColor: "#1C2427",
              borderRadius: "0px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              zIndex: 1000,
              // border:"1px solid red"
            }}
          >
            <Cascader
              data={data}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              selectedPath={selectedPath}
              setSelectedPath={setSelectedPath}
              setIdList={setIdList}
              pathName={pathName}
              setGetBrand={setGetBrand}
              setIsCascaderVisible={setIsCascaderVisible}
              setIsFocused={setIsFocused}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CascaderWrapper;
