import { useEffect, useState } from "react";

import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import { Stack, Typography } from "@mui/material";

const Input = ({
  disabled,
  editMode,
  rangeValue,
  min,
  max,
  fillFlag,
  percent,
  incUnit,
  channelName,
  tabName,
  setRePayload,
  // rePayload,
  refresh,
  setRefresh,
  setChanged,
}) => {
  const [Value, setValue] = useState(rangeValue);
  const [inputChange, setInputChange] = useState(false);

  useEffect(() => {
    setValue(rangeValue);
  }, [rangeValue]);

  const handler = (e) => {
    if (fillFlag) {
      setValue(e.target.value);
      setInputChange(true);
      setRefresh(true);
    }
  };

  const handleBlur = () => {
    // console.log(channelName, tabName, Value);
    setRePayload((rePayload) => ({
      ...rePayload,
      [channelName + "-" + tabName]: Value,
    }));

    setChanged((prevState) => [...prevState, inputChange]);
  };

  const refreshHandler = () => {
    setInputChange(false);
    setValue(rangeValue);
    setRePayload((rePayload) => {
      const updatedPayload = { ...rePayload };
      delete updatedPayload[channelName + "-" + tabName];
      return updatedPayload;
    });

    setChanged((prevState) => prevState.slice(0, prevState.length - 1));
  };

  useEffect(() => {
    if (!refresh) {
      refreshHandler();
    }
  }, [refresh]);

  return (
    <Stack direction={"row"} gap={2} alignItems={"center"} 
    // sx={{border:"2px solid red"}}
    >
      {fillFlag ? (
        <input
          disabled={disabled}
          value={Value}
          onChange={handler}
          onBlur={handleBlur}
          style={{
            width: 60,
            outline: "none",
            border: "1px solid #3E5056",
            backgroundColor: inputChange ? "#d6ff41" : "#1c2427",
            borderRadius: "4px",
            color: inputChange ? "black" : "white",
            fontSize: "0.75rem",
            padding: "0.1rem 0.2rem",
           
          }}
        />
      ) : (
        <Stack direction={"row"} gap={2}>
          <Typography sx={{ fontSize: "0.9rem" }}>
            {/* {Value.toFixed(0)} {incUnit} */}
            {Value.toFixed(0)}
          </Typography>
          <Typography sx={{ fontSize: "0.9rem" }}>
            ({percent.toFixed(2)}%)
          </Typography>
        </Stack>
      )}
      {inputChange && (
        <RefreshSharpIcon
          onClick={refreshHandler}
          fontSize="small"
          sx={{
            cursor: "pointer",
            color: "#d6ff41",
            pointerEvents: editMode ? "all" : "none",
            opacity: editMode ? 1 : 0.7,
          }}
        />
      )}

      {fillFlag ? (
        <Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography sx={{ fontSize: "0.75rem" }}>{min}</Typography>
            <Typography sx={{ fontSize: "0.75rem" }}>{max}</Typography>
          </Stack>

          <input
            type="range"
            className="form-range"
            value={Value}
            step={0.5}
            min={min}
            max={max}
            onChange={handler} 
            onBlur={handleBlur}
            id={ editMode&& "disabledRange"}
            style={{
              cursor: "pointer",
              pointerEvents: editMode ? "all" : "none",
              // opacity: editMode ? 1 : 0.7,
              
            }}
            disabled={editMode ? false : true}
          />
        </Stack>
      ) : null}
    </Stack>
  );
};

export default Input;
