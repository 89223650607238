import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Topbar from "../../Components/Topbar";
import Brand from "./Brand";
import Loader from "../../Components/Loader";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Dashboard = () => {
  const SlideVisible = 4;
  const [Count, setCount] = useState(SlideVisible);
  const [loader, setLoader] = useState(false);
  const [Brands, setBrands] = useState([]);
  const [SelectedBrands, setSelectedBrands] = useState('');

  useEffect(() => {
    setLoader(true);
    axios
      .post("dashboard/", {})
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setBrands(response.data.data);
          setLoader(false);
        }
      })
      .catch((data) => {
        setLoader(false);
      });

    return () => { };
  }, []);

  return (
    <>
      <Topbar BrandName="" />
      {loader ? <Loader /> : null}

      <ToastContainer theme="colored" />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    Welcome to Skewb
                    {/* , {localStorage.getItem("NameSkewb")} */}
                  </h1>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center flex-row-reverse p-0">
                  <div className="page-title-right">
                    {/* <form className="app-search d-none d-lg-block">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control main-search"
                          placeholder="Search..."
                        />
                        <span className="ri-search-line"></span>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid main-content-w">
            <div className="row align-items-center">
              {Count > SlideVisible ? (
                <div
                  className="welcome-left-arrow d-flex align-items-center justify-content-center"
                  onClick={() => setCount(Count - SlideVisible)}
                >
                  <i className="ri-arrow-left-s-line brand-left-arrow"></i>
                </div>
              ) : null}

              {Brands.map((item, index) => {
                if (index >= Count - SlideVisible && index < Count) {
                  return (
                    <Brand key={item.brand_short_code + index} data={item} SelectedBrands={SelectedBrands} setSelectedBrands={setSelectedBrands} />
                  );
                } else {
                  return null;
                }
              })}

              {Brands.length > Count ? (
                <div
                  className="welcome-right-arrow d-flex align-items-center justify-content-center"
                  onClick={() => setCount(Count + SlideVisible)}
                >
                  <i className="ri-arrow-right-s-line brand-right-arrow"></i>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
