import { useState, useEffect, useRef } from "react";
import { Box, Stack, Typography, SvgIcon, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

// icons mui
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

import Card from "./Card";
import Sidebar from "./Sidebar";

// charts

import ColumnChart from "../../ChartsV2/ColumnChart";
import LineChart from "../../ChartsV2/LineChart";
import StackChart from "../../ChartsV2/StackChart";
import WaterfallChart from "../../ChartsV2/WaterfallChart";

const Select = styled("select")(({ theme }) => ({
  outline: "none",
  padding: 0,
  // fontSize: theme.typography.h5.fontSize,
}));

const Option = styled("option")(({ theme }) => ({
  // fontSize: theme.typography.h5.fontSize,
}));

const PopUp = ({ onClose, children, popRef }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 999,
      }}
    >
      <Box
        ref={popRef}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          width: "50vw",
          height: "60vh",
          // background: "red",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 99999999999,
          transform: "translate(-50%, -50%)",
        }}
        onClick={onClose}
      >
        <Box
          sx={{
            background: "#1c2427",
            padding: "1rem",
            borderRadius: "8px",
            width: "50vw",
            height: "100%",
            border: "1px solid gray",
            position: "relative",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <CloseIcon
            onClick={onClose}
            sx={{
              position: "absolute",
              fontSize: "2rem",
              padding: "0.3rem",
              right: -15,
              top: -10,
              color: "yellow",
              zIndex: 9999,
              backgroundColor: "#1c2427",
              border: "1px solid gray",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          />
          {children}
        </Box>
      </Box>
    </Box>
  );
};

const Sum = ({ hierarchy, checked, setAllChannelsFun }) => {
  const [activeTab, setActiveTab] = useState(0);

  // chart data
  const [chartData, setChartData] = useState(hierarchy[0]?.optchartdict);
  const [optSummary, setOptSummary] = useState(hierarchy[0]?.optsummarydict);
  const [chartType, setChartType] = useState("");
  const [channelMovementActive, setChannelMovementActive] = useState(false);
  const [lineFilter, setLineFilter] = useState("");
  const [lineChartData, setLineChartData] = useState({});
  const [showPopUp, setShowPopUp] = useState(false);
  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);
  const [popHeight, setPopHeight] = useState(0);
  const [waterfallHeight, setWaterfallHeight] = useState(divHeight);

  const popRef = useRef(null);

  // console.log("popHeight: ", popHeight);

  useEffect(() => {
    const updatePopHeight = () => {
      if (popRef.current) {
        const newHeight = popRef.current.clientHeight;
        setPopHeight(newHeight);
      }
    };

    updatePopHeight(); // Initial update
    window.addEventListener("resize", updatePopHeight);

    return () => {
      window.removeEventListener("resize", updatePopHeight);
    };
  }, [showPopUp, setShowPopUp]);

  const tabs = [
    { label: "Brand Hierarchy", index: 0 },
    { label: "Summary", index: 1 },
    { label: "Charts", index: 2 },
  ];

  useEffect(() => {
    const update = () => {
      const Height = divHeight - (divHeight * 1) / 100;
      // console.log(Height);
      setWaterfallHeight(Height);
    };

    update();
  }, [divHeight]);

  // console.log("divH", divHeight);

  useEffect(() => {
    const updateDivHeight = () => {
      if (divRef.current) {
        const newHeight = divRef.current.clientHeight;
        setDivHeight(newHeight);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, [activeTab, showPopUp]);
  // console.log("type chart", chartType);

  const Tab1 = () => {
    setActiveTab(0);
  };

  const Tab2 = () => {
    setActiveTab(1);
  };

  const Tab3 = () => {
    setActiveTab(2);
  };

  useEffect(() => {
    if (chartData?.length > 0) {
      setChartType(chartData[0]?.type);
    }
  }, [chartData]);

  useEffect(() => {
    const selectedChart = chartData.find((chart) => chart.type === "line");

    if (selectedChart) {
      setLineChartData(selectedChart);
      setLineFilter(selectedChart.data[0].filter);
    }
  }, [chartData]);

  // .............................

  const handleChartChange = (e) => {
    setChartType(e.target.value);
  };

  useEffect(() => {
    if (chartType === "line") {
      setChannelMovementActive(true);
    } else {
      setChannelMovementActive(false);
    }
  }, [chartType]);

  const renderLineChartForPopUp = () => {
    if (lineChartData.data) {
      const selectedChart = lineChartData.data.find(
        (chart) => chart.filter === lineFilter
      );

      return (
        <Box>
          <LineChart
            data={selectedChart}
            ChartHeight={popHeight - 40}
            ChartWidth={"100%"}
          />
        </Box>
      );
    }
  };
  const renderLineChart = () => {
    if (lineChartData.data) {
      const selectedChart = lineChartData.data.find(
        (chart) => chart.filter === lineFilter
      );

      return (
        <Box>
          <LineChart
            data={selectedChart}
            ChartHeight={divHeight}
            ChartWidth={"100%"}
          />
        </Box>
      );
    }
  };

  const channelMovementFilters = () => {
    const selectedChart = chartData.find((chart) => chart.type === "line");

    return (
      <>
        {selectedChart.data.map((data, index) => {
          return (
            <Option key={index} value={data.filter}>
              {data.filter}
            </Option>
          );
        })}
      </>
    );
  };

  const handleChartClick = () => {
    setShowPopUp(true); // Open the pop-up when clicking on the chart
  };

  const handlePopUpClose = () => {
    setShowPopUp(false); // Close the pop-up when clicking on it
  };

  const chartRender = () => {
    if (chartData?.length > 0) {
      const selectedChart = chartData.find((chart) => chart.type === chartType);
      if (selectedChart) {
        switch (selectedChart.type) {
          case "waterfall":
            return (
              <WaterfallChart
                data={selectedChart}
                ChartHeight={waterfallHeight}
                ChartWidth={"100%"}
              />
            );
          case "column":
            return (
              <ColumnChart
                data={selectedChart}
                ChartHeight={divHeight}
                ChartWidth={"100%"}
              />
            );
          case "stack":
            return (
              <StackChart
                data={selectedChart}
                ChartHeight={divHeight}
                ChartWidth={"100%"}
              />
            );
          case "line":
            return renderLineChart();
          default:
            return null;
        }
      }
    }

    return <Box component={"span"}>Error occurred :(</Box>;
  };

  const chartRenderForPopUp = () => {
    if (chartData?.length > 0) {
      const selectedChart = chartData.find((chart) => chart.type === chartType);
      if (selectedChart) {
        switch (selectedChart.type) {
          case "waterfall":
            return (
              <WaterfallChart
                data={selectedChart}
                ChartHeight={popHeight - 40}
                ChartWidth={"100%"}
              />
            );
          case "column":
            return (
              <ColumnChart
                data={selectedChart}
                ChartHeight={popHeight}
                ChartWidth={"100%"}
              />
            );
          case "stack":
            return (
              <StackChart
                data={selectedChart}
                ChartHeight={popHeight}
                ChartWidth={"100%"}
              />
            );
          case "line":
            return renderLineChartForPopUp();
          default:
            return null;
        }
      }
    }

    return <Box component={"span"}>Error occurred :(</Box>;
  };

  const iconHierarchy =
    activeTab === 0 ? (
      <SvgIcon
        component={RemoveTwoToneIcon}
        sx={{
          cursor: "pointer",
          color: "white",
          fontSize: "1.5rem",
          fontWeight: 700,
        }}
      />
    ) : (
      <SvgIcon
        component={AddTwoToneIcon}
        sx={{
          cursor: "pointer",
          color: "white",
          fontSize: "1.5rem",
          fontWeight: 700,
        }}
      />
    );

  const iconSummary =
    activeTab === 1 ? (
      <SvgIcon
        component={RemoveTwoToneIcon}
        sx={{
          cursor: "pointer",
          color: "white",
          fontSize: "30px",
          fontWeight: 700,
        }}
      />
    ) : (
      <SvgIcon
        component={AddTwoToneIcon}
        sx={{
          cursor: "pointer",
          color: "white",
          fontSize: "1.5rem",
          fontWeight: 700,
        }}
      />
    );
  const iconCharts =
    activeTab === 2 ? (
      <SvgIcon
        component={RemoveTwoToneIcon}
        sx={{
          cursor: "pointer",
          color: "white",
          fontSize: "1.5rem",
          fontWeight: 700,
        }}
      />
    ) : (
      <SvgIcon
        component={AddTwoToneIcon}
        sx={{
          cursor: "pointer",
          color: "white",
          fontSize: "1.5rem",
          fontWeight: 700,
        }}
      />
    );

  return (
    <Stack
      justifyContent={"space-between"}
      sx={{
        borderRadius: "1rem",
        overflow: "hidden",
        height: "100%",
        width: checked ? "66%" : "76%",
      }}
    >
      {/* zeroth- 1 part */}
      <Stack
        backgroundColor={"bgColor"}
        onClick={Tab1}
        p="1rem"
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ cursor: "pointer", height: "10%" }}
      >
        <Typography variant="h6" fontWeight={700}>
          {tabs[0].label}
        </Typography>
        <Box
          sx={{
            color: "white",
          }}
        >
          {activeTab !== 0 && iconHierarchy}
        </Box>
      </Stack>
      {/* zeroth- 1 part end*/}
      {/* ................................................ */}
      {/* zeroth- 2 part */}

      {true && (
        <Box
          backgroundColor={"bgColor"}
          sx={{
            height: "68%",
            display: activeTab !== 0 ? "none" : "block",
          }}
        >
          <Sidebar
            hierarchy={hierarchy}
            setAllChannelsFun={setAllChannelsFun}
            setOptSummary={setOptSummary}
            setChartData={setChartData}
          />
        </Box>
      )}

      {/* zeroth- 2 part end */}

      {/* ........................................................................ */}
      {/* first part */}
      <Stack
        backgroundColor={"bgColor"}
        onClick={Tab2}
        p="1rem"
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ cursor: "pointer", height: "10%" }}
      >
        <Typography variant="h6" fontWeight={700}>
          {tabs[1].label}
        </Typography>
        <Box
          sx={{
            color: "white",
          }}
        >
          {activeTab !== 1 && iconSummary}
        </Box>
      </Stack>
      {/* first part */}

      {/* 2nd part */}
      {activeTab === 1 && (
        <Stack
          backgroundColor={"bgColor"}
          justifyContent={"center"}
          sx={{
            height: "68%",
            width: "100%",
            padding: "1rem",
          }}
        >
          <Box
            sx={{
              paddingRight: "0.5rem",
              rowGap: "1rem",
              width: "100%",
              height: "100%",
              // border: "1px solid yellow",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "4px",
              },

              "&::-webkit-scrollbar-track": {
                background: "#1C2427",
              },

              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "gray",
                borderRadius: "10px",
              },
            }}
          >
            {optSummary.map((card, index) => (
              <Card
                key={index}
                header={card.header}
                spends={card.value}
                footnote={card.footnote}
              />
            ))}
          </Box>
        </Stack>
      )}
      {/* 2nd part */}

      {/* 3rd part */}
      <Stack
        backgroundColor={"bgColor"}
        onClick={Tab3}
        p="1rem"
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ cursor: "pointer", height: "10%" }}
      >
        <Typography variant="h6" fontWeight={700}>
          {tabs[2].label}
        </Typography>
        <Box
          sx={{
            color: "white",
            cursor: "pointer",
          }}
        >
          {activeTab !== 2 && iconCharts}
        </Box>
      </Stack>
      {/* 3rd part */}

      {/* 4th part */}
      {activeTab === 2 && (
        <Stack
          backgroundColor={"bgColor"}
          p="1rem"
          sx={{
            height: "68%",
            width: "100%",
            // display: activeTab !== 2 ? "none" : "block",
          }}
        >
          {/* charts */}
          <Box sx={{ height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                // border: "4px solid blue",
                height: "10%",
              }}
            >
              <Box
                sx={{
                  minWidth: "40%",
                  // border: "1px solid yellow",
                }}
              >
                <Select
                  value={chartType}
                  onChange={handleChartChange}
                  sx={{
                    width: "100%",
                    color: "white",
                    backgroundColor: "#1c2427",
                    padding: "0.2rem 0",
                    border: "1px solid #3e5056",
                    fontSize: ".75rem",
                    borderRadius: "5px",
                    height: "100%",
                  }}
                >
                  {chartData.map((chart, index) => {
                    return (
                      <Option key={index} value={chart.type}>
                        {chart.name}
                      </Option>
                    );
                  })}
                </Select>
              </Box>
              {channelMovementActive ? (
                <Box sx={{ width: "40%" }}>
                  <Select
                    sx={{
                      width: "100%",
                      color: "white",
                      backgroundColor: "#1c2427",
                      padding: "0.2rem 0",
                      borderRadius: "5px",
                      fontSize: ".75rem",
                      height: "100%",
                    }}
                    onChange={(e) => setLineFilter(e.target.value)}
                  >
                    {channelMovementFilters()}
                  </Select>
                </Box>
              ) : null}
              <Tooltip
                title={
                  <Typography sx={{ fontSize: ".8rem", color: "white" }}>
                    Full Screen
                  </Typography>
                }
                placement="right"
                arrow
              >
                <SvgIcon
                  component={FullscreenIcon}
                  sx={{
                    cursor: "pointer",
                    color: "white",
                    width: "10%",
                    fontSize: "30px",
                  }}
                  onClick={handleChartClick}
                />
              </Tooltip>
            </Box>

            {chartData?.length > 0 ? (
              <Box ref={divRef} sx={{ height: "90%" }}>
                {chartRender()}
              </Box>
            ) : (
              <Box component={"span"}>Error occurred :(</Box>
            )}
          </Box>
        </Stack>
      )}
      {/* 4th part */}
      {showPopUp && chartData?.length > 0 && (
        <PopUp popRef={popRef} onClose={handlePopUpClose}>
          {chartRenderForPopUp()}
        </PopUp>
      )}
    </Stack>
  );
};

export default Sum;
