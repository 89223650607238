import axios from "axios";
import BaseUrl from "./BaseUrl";

const instance = axios.create({
  baseURL: BaseUrl+":8000/",
  headers: {
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("TokenSkewb")}`;
      },
    },
  },
});

export default instance;
