import React from 'react'

const SaveResultAlert = (props) => {
  // console.log(props.Parameter)

  const d = new Date()
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const currentDate = d.getDate() + ' ' + monthNames[d.getMonth()] + ' ' + d.getFullYear() + ' | ' + d.getHours() + ':' + d.getMinutes() + ' hrs'

  return (
    <>
      <div
        className="modal fade bs-save-result-modal-center show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel "
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content ">
            <div className="modal-body model-result-popup ">
              <div className="row">
                <div className="col-sm-12" onClick={() => props.setSaveResult(false)}>
                  <i
                    className="ri-close-fill model-close f-right"
                    data-bs-dismiss="modal "
                    aria-label="Close "
                  ></i>
                </div>
              </div>
              {
                // props.saveResponse === ''
                true ? <>
                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <h3 className="model-result-popup-h3 ">Name :</h3>
                    </div>
                    <div className="col-sm-7 ">
                      <input
                        className="form-control "
                        type="text "
                        placeholder="Plan name"
                        value={props.resultName}
                        onChange={(e) => props.setResultName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <h3 className="model-result-popup-h3 ">Optimized for :</h3>
                    </div>
                    <div className="col-sm-7 ">
                      <h3 className="model-result-popup-h3 ">
                        {
                          props.optimizationType === 'budget' ? 'Fixed Budget' : 'Target Growth'
                        } - {props.optimizationTypeValue}
                      </h3>
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <h3 className="model-result-popup-h3 ">Date &amp; Time :</h3>
                    </div>
                    <div className="col-sm-7 ">
                      <h3 className="model-result-popup-h3 ">
                        {currentDate}
                      </h3>
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-sm-5 ">
                      <h3 className="model-result-popup-h3 ">User :</h3>
                    </div>
                    <div className="col-sm-7 ">
                      <h3 className="model-result-popup-h3 ">{localStorage.getItem('NameSkewb')}</h3>
                    </div>
                  </div>
                  <div className="row align-items-center ">
                    <div className="col-12 text-center">
                      <button onClick={() => props.saveOptimisation()} className="btn btn-modal-primary">Save</button>
                    </div>
                  </div>
                </> : <>
                  <h1 style={{ textAlign: 'center', color: 'lime' }}>{props.SaveResponse}</h1>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SaveResultAlert