import React from "react";
import { Stack, useTheme, useMediaQuery } from "@mui/material";

import Table from "./Table";
import Footer from "./Footer";

const Channels = ({
  DATA,
  headerTabNames,
  Length,
  editMode,
  setEditMode,
  setRePayload,
  rePayload,
  fetchCall,
  refresh,
  setRefresh,
  defaultView,
  changed,
  setChanged,
  setClickSort,
  setSortAlpha,
  toggleStates,
  handleToggleChange,
  clickSort
}) => {
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
  return (
    <Stack
      sx={{
        width: isSmall || isMedium ? "100%" : "69%",
        height: "100%",
        backgroundColor: "bgColor",
        borderRadius: "20px",
        padding: "1rem 1rem 0 1rem",
        // border: "2px solid green",
        marginTop: isSmall || isMedium ? "2.5%" : "",
        // overflowX:"scroll",
        // height:"px"
      }}
    >
      <Stack
        sx={{
          height: "90%",
          // border: "2px solid red",
          width:( isSmall || isMedium)?"100%":"100%",
          overflowX:(isSmall||isMedium) ?"scroll":"none",
        }}
      >
        <Table
          DATA={DATA}
          headerTabNames={headerTabNames}
          Length={Length}
          editMode={editMode}
          setEditMode={setEditMode}
          setRePayload={setRePayload}
          rePayload={rePayload}
          fetchCall={fetchCall}
          refresh={refresh}
          setRefresh={setRefresh}
          defaultView={defaultView}
          setChanged={setChanged}
          setClickSort={setClickSort}
          setSortAlpha={setSortAlpha}
          handleToggleChange={handleToggleChange}
          toggleStates={toggleStates}
          clickSort={clickSort}
        />
      </Stack>
      <Footer
        fetchCall={fetchCall}
        setRePayload={setRePayload}
        refresh={refresh}
        setRefresh={setRefresh}
        changed={changed}
      />
    </Stack>
  );
};

export default Channels;
