import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import axiosDownload from "../../axiosDownload";
import LineChart from "./LineChart";
import Loader from "../../Components/Loader";
import { useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Main = () => {
  const location = useLocation();
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const [loader, setLoader] = useState(false);
  const [ChannelAll, setChannelAll] = useState([]);
  const [SelectedChannel, setSelectedChannel] = useState("");
  const [ChartTitle, setChartTitle] = useState("");
  const [ROCData, setROCData] = useState({});
  const [Summary, setSummary] = useState([]);

  const [Notes, setNotes] = useState('');

  useEffect(() => {
    setLoader(true);
    axios
      .post("get_adstock_channels/", { brand: Analyse.Brand, kpi: Analyse.KPI })
      .then((response) => {
        // console.log(response);
        if (response.data.error !== 1) {
          setChannelAll(response.data.data);
          setSelectedChannel(response.data.data[0].key);
          setNotes(response.data.notes)
        } else {
          setChannelAll([]);
          setSelectedChannel('');
          setNotes('')
          toast.error(response.data.erroMsg);
        }
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  }, [Analyse.KPI, Analyse.Brand]);

  useEffect(() => {
    setLoader(true);
    if (SelectedChannel) {
      setROCData({});
      axios
        .post("get_adstock/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          channel: SelectedChannel,
        })
        .then((response) => {
          if (response.data.error === 1) {
            setChartTitle("");
            setROCData({});
            setSummary([]);
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setChartTitle(response.data.data.title);
            setROCData(response.data.data);
            setSummary(response.data.data.summary);
            setLoader(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [SelectedChannel, Analyse.Brand, Analyse.KPI]);

  const DownloadData = () => {
    setLoader(true);
    axiosDownload
      .post("get_adstock_channels/", {
        data: ROCData
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);

      }).catch((data) => {
        setLoader(false);
      });
  }

  return (
    <>
      {loader ? <Loader /> : null}

      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {/* Channel Efficacy ROC */}
                    {
                      (Analyse && Analyse.Menubar) ?
                        Analyse.Menubar.map((item) => {
                          return ('/' + item.key === location.pathname) ? item.value : null
                        })
                        : null
                    }
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid main-content-w">
            <div className="row">
              <div className="col-xl-9">
                <div className="card card-min-height align-items-sm-center">
                  <div className="row w-100">
                    <div className="col-sm-3 channel-card">
                      <h3 className="data-spends my-2" style={{ marginLeft: '20px' }}>Channels</h3>
                      <div className="d-flex flex-column channel-list ">
                        {ChannelAll.map((item) => {
                          return (
                            <Link
                              key={item.key}
                              className={
                                SelectedChannel === item.key
                                  ? "channels-link active"
                                  : "channels-link"
                              }
                              onClick={() => setSelectedChannel(item.key)}
                              to=""
                              role="tab"
                            >
                              {item.key}
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-sm-9 brand-card ef-drivers">
                      {Object.keys(ROCData).length !== 0 ? (
                        <LineChart
                          ChartTitle={ChartTitle}
                          series={ROCData.series}
                          xunits={ROCData.xunits}
                          plotBands={ROCData.plotBands}
                          labels={ROCData.labels}
                          yunits={ROCData.yunits}
                          DownloadData={DownloadData}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="card card-min-height">
                  <div className="card-body brand-card">
                    <div className="row align-items-center justify-content-sm-between">
                      <h3 className="data-spends my-2">Summary</h3>
                      <div className="spends-list my-2">
                        {/* <p className="cer-summary-title">
                          Sustenance: <span>83.83</span>
                        </p> */}

                        {Summary
                          ? Summary.map((item, index) => {
                            return (
                              <p key={index} className="cer-summary-p">
                                {item}
                              </p>
                            );
                          })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 data-l-note" dangerouslySetInnerHTML={{ __html: Notes }}>
                {/* {Notes} */}
                {/* <p className="m-0">Note:</p>
                <p className="m-0">FY 21: Oct'2020-Sep'2021</p>
                <p className="m-0">Covid-2020: Apr'2020-Jul'2020</p>
                <p className="m-0">Post Covid-2020: Aug'2020 to Dec'2020</p>
                <p className="m-0">2021*: Data is available till September</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
