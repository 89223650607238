import React from "react";

const Legends = (props) => {
  return (
    <>
      <div
        style={{
          height: "16px",
          width: "16px",
          marginLeft: "12px",
          marginRight: "8px",
          background: props.item.Color,
        }}
      ></div>
      <p className="m-0 text-white custom-table-legends">{props.item.Name}</p>
    </>
  );
};

export default Legends;
