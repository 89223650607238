import { Stack, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";

import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";

const Input = styled("input")(({ theme, checked, refresh, inputChange }) => ({
  borderRadius: "4px",
  border: `1px solid gray`,
  backgroundColor:
    refresh || inputChange ? theme.palette.themeColor : theme.palette.bgColor, // Use the bgColor from the theme
  color:
    refresh || inputChange ? theme.palette.bgColor : theme.palette.themeColor, // Use the themeColor from the theme
  marginLeft: 0,
  width: 70,
  textAlign: "center",
  "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  outline: "none",

  opacity: checked ? 1 : 0.3,
}));

const RowData = ({
  type,
  traverse,
  name,
  prev_spend,
  diff,
  annual,
  checked,
  min_value,
  max_value,
  mykey,
  icon,
  onClick,
  color,
  refresh,
  edit,
  fillflag,
  setKeyFun,
  setTempInputKeyValueFun,
  setIsExecuted,
  setChannelType,
  traverseInputChange,
  inputChange,
  setGetInputChangeValueEditSpend,
}) => {
  const [editSpendsValue, setEditSpendsValue] = useState(
    parseFloat(annual?.toFixed(1))
  );

  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  // alert box start for range.........
  const Alert = ({ message }) => {
    return (
      <>
        {isOpen && (
          <Stack
            alignItems={"center"}
            spacing={4}
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 450,
              bgcolor: "bgColor",
              boxShadow: 24,
              p: "2rem",
              borderRadius: "1rem",
              zIndex: 999999999999999999999999999,
            }}
          >
            <Typography
              sx={{
                fontSize: "1.25rem",
                color: "white",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              {message}
            </Typography>
            <Stack direction={"row"} spacing={6}>
              <Button
                onClick={() => {
                  setIsOpen(false);
                  setKeyFun(mykey);
                  traverse(mykey, type, mykey, [editSpendsValue], true, true);
                }}
                sx={{
                  padding: "0.5rem 1.5rem",
                  backgroundColor: "themeColor",
                  color: "bgColor",
                  fontSize: "1rem",
                  fontWeight: 700,
                  "&:hover": {
                    backgroundColor: "themeColor",
                    color: "bgColor",
                  },
                }}
              >
                OK
              </Button>
              <Button
                onClick={() => {
                  setIsOpen(false);
                  refreshHandler();
                }}
                sx={{
                  padding: "0.5rem 1.5rem",
                  border: "1px solid #d6ff41",
                  backgroundColor: "bgColor",
                  color: "gray",
                  fontSize: "1rem",
                  fontWeight: 700,
                  "&:hover": {
                    backgroundColor: "bgColor",
                    color: "gray",
                  },
                }}
              >
                NO
              </Button>
            </Stack>
          </Stack>
        )}
      </>
    );
  };

  // alert box end for range............end

  // console.log("Refresh and My Key: ", refresh, mykey)

  const refreshHandler = () => {
    setEditSpendsValue(parseFloat(annual?.toFixed(1)));
    setKeyFun(mykey);
    traverse(mykey, type, mykey, [0], false, false);
    traverseInputChange(mykey, type, false);

    // setInputChange(false);
    // console.log("key in refresh: ", mykey);
    // console.log("type: ", type);
  };

  // console.log("refreshHandlerRef: ", refreshHandlerRef);

  const handleChange = (e) => {
    // console.log("key onchange: ", mykey);
    // setKeyFun(mykey);
    // setInputChange(true);
    setChannelType(type);
    traverseInputChange(mykey, type, true);

    const inputValue = parseFloat(e.target.value);
    if (fillflag === 1) {
      setEditSpendsValue(inputValue);
      setGetInputChangeValueEditSpend(inputValue);
      setTempInputKeyValueFun([mykey, color, inputValue]);
    }
  };

  const inputRangeValue = () => {
    if (editSpendsValue < max_value) {
      return editSpendsValue;
    } else {
      return max_value;
    }
  };

  useEffect(() => {
    if (refresh) {
      setEditSpendsValue(parseFloat(edit));
    } else {
      setEditSpendsValue(parseFloat(annual?.toFixed(1)));
    }
  }, [refresh, annual, edit]);

  const handleBlur = () => {
    // console.log("key on blur: ", mykey);
    setIsExecuted(false);
    setChannelType(type);

    if (isNaN(editSpendsValue)) {
      setKeyFun(mykey);
      traverse(mykey, type, mykey, [0], true, true);
    } else if (editSpendsValue < min_value || editSpendsValue > max_value) {
      // setKeyFun(mykey);
      // traverse(mykey, type, mykey, [editSpendsValue], true, true);
      setIsOpen(true);
      setMessage(
        "Entered value falls outside range, Are you sure you want to do this change?"
      );
    } else {
      setKeyFun(mykey);
      traverse(mykey, type, mykey, [editSpendsValue], true, true);
    }
  };

  return (
    <Stack
      direction={"row"}
      alignItems={"baseline"}
      sx={{ padding: "1rem 0", width: "100%" }}
    >
      <Typography
        variant="h6"
        sx={{
          width: "2%",
          cursor: "pointer",
          color: "white",
          fontWeight: 700,
        }}
        onClick={onClick}
      >
        {icon}
      </Typography>
      <Typography variant="h6" sx={{ width: "20%", color: color }}>
        {name}
      </Typography>
      <Typography variant="h6" sx={{ width: "10%", color: "white" }}>
        {prev_spend}
      </Typography>
      <Typography variant="h6" sx={{ width: "19%" }}>
        {diff}
      </Typography>
      <Typography variant="h6" sx={{ width: "13%", color: "#d6ff41" }}>
        {annual?.toFixed(1)}
      </Typography>
      <Typography
        variant="h6"
        sx={{ width: "19%", display: "flex", alignItems: "center", gap: 1 }}
      >
        {(refresh || inputChange) && (
          <RefreshSharpIcon
            fontSize="small"
            sx={{
              cursor: "pointer",
              color: "yellow",
              pointerEvents: checked ? "auto" : "none",
              opacity: checked ? 1 : 0.5,
            }}
            onClick={refreshHandler}
          />
        )}
        <Input
          checked={checked}
          refresh={refresh}
          inputChange={inputChange}
          type="number"
          disabled={!checked}
          value={editSpendsValue}
          onChange={handleChange}
          onBlur={() => {
            if (editSpendsValue !== parseFloat(annual?.toFixed(1))) {
              handleBlur();
            }
          }}
        />
      </Typography>
      <Stack direction={"column"} width={"18%"}>
        <input
          style={{ cursor: "pointer", width: "100%", color: "yellow" }}
          disabled={!checked}
          value={inputRangeValue()}
          type="range"
          min={min_value}
          max={max_value}
          onChange={handleChange} // Use the modified handleChange function
          onBlur={() => {
            if (editSpendsValue !== parseFloat(annual?.toFixed(1))) {
              handleBlur();
            }
          }}
        />
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h6" sx={{ color: "gray" }}>
            {min_value}
          </Typography>
          <Typography variant="h6" sx={{ color: "gray" }}>
            {max_value}
          </Typography>
        </Stack>
      </Stack>
      <Alert message={message} />
    </Stack>
  );
};

export default RowData;
