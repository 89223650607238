import { Stack, Typography, Box } from "@mui/material";

import Input from "./Input";

const Row = ({
  name,
  data,
  editMode,
  setRePayload,
  rePayload,
  refresh,
  setRefresh,
  setChanged,
  toggleStates,
}) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        borderBottom: "1px dashed #3E5056",
        // border:"2px solid red",
        // width:"200px"
      }}
    >
      <Box
        sx={{
          width: "10%",
          padding: "1.25rem 0",
          borderRight: "1px dashed #3E5056",
          // border: "1px solid red",
        }}
      >
        <Typography sx={{ color: "white" }}>{name}</Typography>
      </Box>

      {data.map((item, index) => {
        // console.log(Object.keys(item)[0], Object.values(item)[0]);
        return (
          <Box
            sx={{
              width: "30%",
              padding: "1rem 0",
              borderRight: "1px dashed #3E5056",
              // border: "1px solid yellow",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack
              key={index}
              direction={"row"}
              alignItems={"center"}
              gap={2}
              sx={{
                color: "white",
              }}
            >
              <Input
                // disabled={!editMode}
                // editMode={editMode}
                disabled={!toggleStates[index]}
                editMode={toggleStates[index]}
                min={Object.values(item)[0].min}
                max={Object.values(item)[0].max}
                rangeValue={Object.values(item)[0].value}
                fillFlag={Object.values(item)[0].edit_flag}
                percent={Object.values(item)[0]?.percent}
                incUnit={Object.values(item)[0]?.unit}
                channelName={name}
                tabName={Object.keys(item)[0]}
                setRePayload={setRePayload}
                refresh={refresh}
                setRefresh={setRefresh}
                setChanged={setChanged}
              />
            </Stack>
          </Box>
        );
      })}
    </Stack>
  );
};

export default Row;
