import React from 'react'

const Allocation = (props) => {

    const getHeight = (AllocationData, y) => {
        var Total = 0
        for (var i = 0; i < AllocationData.length; i++) {
            Total += AllocationData[i].y
        }
        // const Total = AllocationData.reduce(
        //     (previousValue, currentValue) => parseFloat(previousValue.y) + parseFloat(currentValue.y)
        // );
        return y / Total * 100
    }

    return (
        <div className={props.AllowEdit ? "col-xs-6 col-md-6 col-lg-6 col-xl-2" : "col-xs-6 col-md-6 col-lg-6 col-xl-1"}>
            <div className="card mb-0 optimizer-table-height">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 text-center p-0">
                            <h3 className="data-spends my-2">Allocation</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-6 text-center">
                            {
                                props.AllowEdit ? <h3 className="data-spends my-2 px-2" style={{ wordWrap: "normal" }}>Channel</h3> : null
                            }
                            <div className="bar-chart d-flex justify-content-center">

                                <div className="allcation-layout" >
                                    {
                                        props.SuggestedAllocation.map((item, index) => {
                                            return (
                                                <div
                                                    className="channel"
                                                    key={index}
                                                    title={item.name}
                                                    style={{
                                                        height: getHeight(props.SuggestedAllocation, item.y) + '%',
                                                        background: item.color
                                                    }} ><span>{parseFloat(item.y).toFixed(2)}</span></div>
                                            )
                                        })
                                    }
                                    {/* <div className="Channel-1" ></div>
                                    <div className="Channel-2" ></div> */}
                                </div>
                            </div>
                        </div>

                        {
                            props.AllowEdit ?
                                <div className="col-sm-6 text-center">
                                    <h3 className="data-spends my-2 px-2">User</h3>
                                    <div className="bar-chart d-flex justify-content-center">
                                        <div className="allcation-layout" >
                                            {
                                                props.EditedAllocation.map((item, index) => {
                                                    // {console.log(item.color, "item.color")}
                                                    return (
                                                        <div
                                                            className="channel"
                                                            key={index}
                                                            title={item.name}
                                                            style={{
                                                                height: getHeight(props.EditedAllocation, item.y) + '%',
                                                                background: item.color
                                                            }} ><span>{parseFloat(item.y).toFixed(2)}</span></div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div> : null
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Allocation