import React, { useState, useRef, useEffect } from "react";
import Download from "../../images/download-icon.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LineChart from "./LineChart";
import downloadFlag from "../downloadFlag";

const SummaryAdstock = (props) => {
  const adstockRef = useRef(null);
  const [FilterType, setFilterType] = useState(
    parseInt(props.ShowSummary) === 1 ? true : false
  );
  const [AdstockChartHeight, setAdstockChartHeight] = useState("");

  useEffect(() => {
    if (adstockRef.current !== null) {
      setAdstockChartHeight(adstockRef.current.clientHeight);
    }
  }, [props]);

  return (
    <>
      <div className="col-xs-12 col-md-12 col-lg-12 col-xl-4">
        <div className="card custom-summary m-0">
          <div
            className="card-body p-0 card-min-height"
            style={{ height: props.DivHeight + "px" }}
          >
            <div className="card-3">
              {parseInt(props.ShowSummary) === 1 ? (
                <div className="custom-header mb-1">
                  <span
                    className="header-title cursor-pointer"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={() => setFilterType(!FilterType)}
                  >
                    <h3 className="data-spends my-2 px-2">
                      {" "}
                      {parseInt(props.ShowSummary) === 1 ? "Summary" : ""}
                    </h3>
                    {FilterType ||
                    JSON.stringify(props.AdstockData) === "{}" ? (
                      JSON.stringify(props.AdstockData) !== "{}" ? (
                        <h3 className="m-0">
                          <i className="fa fa-minus" aria-hidden="true"></i>
                          &nbsp;
                        </h3>
                      ) : (
                        ""
                      )
                    ) : parseInt(props.ShowSummary) === 1 ? (
                      <h3 className="m-0">
                        <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;
                      </h3>
                    ) : null}
                  </span>
                </div>
              ) : (
                ""
              )}

              {JSON.stringify(props.AdstockData) !== "{}" ? (
                <div
                  className={
                    !FilterType
                      ? "custom-footer active mb-1"
                      : "custom-footer mb-1"
                  }
                >
                  <span
                    className="footer-title cursor-pointer"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={() =>
                      parseInt(props.ShowSummary) === 1
                        ? setFilterType(!FilterType)
                        : ""
                    }
                  >
                    <h3 className="data-spends my-2 px-2">Adstock</h3>
                    {/* chart icon */}
                    {(downloadFlag.chart) && (
                      <span className="flex-shrink-0 dropdown">
                        <Link
                          to="#"
                          className="d-flex align-items-center justify-content-end text-decoration-none dropdown-toggle text-white"
                          id="dropdownUser2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {/* <p className="m-0 px-2">
                              {" "}

                              <img src={Download} alt="" style={{ float: "right", cursor: 'pointer', width: '25px', height: '25px' }} title="Download" />
                            </p> */}

                          <p className="m-0 px-2">
                            <svg
                              src={Download}
                              title="Download"
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              className="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </p>
                        </Link>
                        <ul
                          className="dropdown-menu text-small shadow"
                          aria-labelledby="dropdownUser2"
                        >
                          {downloadFlag.chart && (
                            <li onClick={(e) => {}}>
                              <Link
                                className="dropdown-item"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (props.AdstockData !== "{}") {
                                    props.setIsDownloadingChart(true);
                                    props.setChartContainerID("adstock_chart");
                                    props.setDownloadedImageName(
                                      "Adstock Chart"
                                    );
                                    props.setDownloadingChartTitle(
                                      "Adstock Chart"
                                    );
                                  } else {
                                    toast.error("Chartdata is not available.");
                                  }
                                }}
                              >
                                Download Chart
                              </Link>
                            </li>
                          )}

                          {/* <li onClick={(e) => { }}>
                              <Link className="dropdown-item" onClick={() => {}} >Download Data</Link>
                            </li> */}
                          {/* <li onClick={(e) => { }}>
                              <Link className="dropdown-item" onClick={() => {}} >Download PDF</Link>
                            </li> */}
                        </ul>
                      </span>
                    )}
                    {/* chart icon end */}
                    {!FilterType ? (
                      parseInt(props.ShowSummary) === 1 ? (
                        <h3 className="m-0">
                          <i className="fa fa-minus" aria-hidden="true"></i>
                          &nbsp;
                        </h3>
                      ) : (
                        ""
                      )
                    ) : (
                      <h3 className="m-0">
                        <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;
                      </h3>
                    )}
                  </span>
                </div>
              ) : null}

              {(FilterType || JSON.stringify(props.AdstockData) === "{}") &&
              parseInt(props.ShowSummary) === 1 ? (
                <div className="custom-body px-3 py-1">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="p-0" style={{ height: "450px" }}>
                        <ul>
                          {props.Summary.map((item, index) => {
                            return (
                              <li key={index} className="spends-list-li">
                                {item}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="custom-body px-3 py-1">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 my-1 pb-2 d-flex justify-content-end">
                          <span
                            className="cursor-pointer text-white"
                            title="Full Screen"
                            onClick={() => props.setShowPopup(true)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi-fullscreen"
                              viewBox="0 0 16 16"
                            >
                              <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div
                        id="adstock_chart"
                        className="p-0 adstock-chart-height"
                        ref={adstockRef}
                      >
                        {JSON.stringify(props.AdstockData) !== "{}" ? (
                          <LineChart
                            adstockChart={props.adstockChart}
                            ChartTitle={props.AdstockData.data.title}
                            series={props.AdstockData.data.series}
                            xunits={props.AdstockData.data.xunits}
                            plotBands={props.AdstockData.data.plotBands}
                            labels={props.AdstockData.data.labels}
                            yunits={props.AdstockData.data.yunits}
                            SelectedChannel={""}
                            height={
                              parseInt(props.ShowSummary) === 1
                                ? AdstockChartHeight - 80
                                : AdstockChartHeight
                            }
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SummaryAdstock;
