import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Tooltip } from "@mui/material";

const Calendar = ({
  startDate,
  endDate,
  calenderData,
  toggle,
  isSubmit,
  height,
  none,
}) => {
  // console.log("endDate in calender: ", endDate);
  // const currentDate = new Date();
  // console.log("none: ", none);

  const [date, setDate] = useState(endDate);
  // console.log("date: ", date);
  const [selectedDate, setSelectedDate] = useState(null);

  // Reload calendar when endDate changes
  useEffect(() => {
    if (isSubmit) {
      setDate(endDate);
    }
  }, [endDate, isSubmit]);

  // const navigateMonth = (change) => {
  //   setDate(new Date(date.getFullYear(), date.getMonth() + change, 1));
  // };

  const navigateMonth = (change) => {
    const newDate = new Date(date.getFullYear(), date.getMonth() + change, 1);
    // Ensure newDate is within the range
    if (newDate >= startDate && newDate <= endDate) {
      setDate(newDate);
    } else if (newDate < startDate) {
      // If newDate is before startDate, set the date to startDate
      setDate(new Date(startDate));
    } else if (newDate > endDate) {
      // If newDate is after endDate, set the date to endDate
      setDate(new Date(endDate));
    }
  };

  function normalizeToPercentage1(value, minVal, maxVal) {
    return (value / (maxVal - minVal)) * 35;
  }

  function normalizeToPercentage(value, minVal, maxVal) {
    return (value / (maxVal - minVal)) * 40;
  }

  // const selectDate = (day) => {
  //   const selected = new Date(date.getFullYear(), date.getMonth(), day);
  //   if (
  //     !isWithinRange(day) &&
  //     !(startDate <= selected && selected <= endDate)
  //   ) {
  //     setSelectedDate(selected);
  //   }
  // };

  const getEventData = (currentDate) => {
    if (!Array.isArray(calenderData?.datadict)) {
      return null;
    }

    const event = calenderData?.datadict.find(
      (event) => event.date === currentDate
    );
    return event ? event.data : null;
  };

  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  const daysInMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();
  const dayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  const isWithinRange = (day) => {
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentDay = new Date(currentYear, currentMonth, day);

    return (
      (startDate <= currentDay && currentDay <= endDate) ||
      (startDate.getDate() === day &&
        startDate.getMonth() === currentMonth &&
        startDate.getFullYear() === currentYear) ||
      (endDate.getDate() === day &&
        endDate.getMonth() === currentMonth &&
        endDate.getFullYear() === currentYear)
    );
  };

  return (
    <Stack
      sx={{
        height: height,
        gap: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#3E5056",
          flexShrink: 0,
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "baseline" }}>
          <Box
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => navigateMonth(-1)}
          >
            {"<<"}
          </Box>
          <Typography sx={{ fontSize: "0.85rem", color: "#d6ff41" }}>
            {date.toLocaleDateString("en-us", {
              month: "long",
              year: "numeric",
            })}
          </Typography>
          <Box
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => navigateMonth(1)}
          >
            {">>"}
          </Box>
        </Box>
      </Box>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          overflow: "hidden",
          flex: 1,
        }}
      >
        {dayLabels.map((day, index) => (
          <div
            key={index}
            style={{
              textAlign: "center",
              color: "white",
              fontSize: "0.85rem",
              marginTop: "0.5rem",
            }}
          >
            {day}
          </div>
        ))}
        {[...Array(firstDay)].map((_, index) => (
          <div key={`empty-${index}`} style={{ visibility: "hidden" }}></div>
        ))}
        {days.map((day, index) => {
          const currentDate = `${date.getFullYear()}-${String(
            date.getMonth() + 1
          ).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
          const eventData = getEventData(currentDate);
          // console.log("eventData: ", eventData);
          return (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "0.25rem",
                alignItems: "flex-end",
                textAlign: "center",
                height: none ? "86px" : "60px",
                margin: 1,
                fontSize: "0.75rem",
                color: "white",
                backgroundColor:
                  selectedDate && selectedDate.getDate() === day
                    ? ""
                    : "#3E5056",
                ...(isWithinRange(day) && {
                  backgroundColor: "#3E5056",
                  color: "white",
                }),
              }}
              // onClick={() => selectDate(day)}
            >
              {/* {day} */}
              <Typography sx={{ fontSize: "0.65rem" }}>{day}</Typography>
              <Stack
                justifyContent={"flex-end"}
                sx={{ height: "100%", width: "75%" }}
              >
                {eventData && (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                    }}
                  >
                    {toggle ? ( // incremental
                      <Tooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                        title={
                          <>
                            {eventData.calendar_data.map((item, index) => (
                              <div key={index}>
                                <Typography sx={{ fontSize: "0.75rem" }}>
                                  {`${item.name}: ${item[item.name]} ${
                                    eventData.units
                                  }`}
                                </Typography>
                              </div>
                            ))}
                          </>
                        }
                        arrow
                        placement="right-start"
                      >
                        {/* stack */}
                        {eventData.fillflag === 1 ? (
                          <Box
                            sx={{
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#F6FFE5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "flex-end",
                              position: "relative",
                            }}
                          >
                            <Box
                              sx={{
                                height: "7px",
                                width: "7px",
                                backgroundColor: "#16F325",
                                position: "absolute",
                                top: 2,
                                left: 2,
                              }}
                            ></Box>
                            {/* stack bar started */}
                            <Stack
                              justifyContent={"flex-end"}
                              sx={{
                                width: "60%",
                                height: "100%",
                              }}
                            >
                              {eventData.calendar_data.map((item, index) =>
                                item?.show_flag === 1 ? (
                                  <Box
                                    key={index}
                                    sx={{
                                      width: "100%",
                                      height: `${normalizeToPercentage(
                                        item[item.name], // Dynamically get value
                                        calenderData?.minval,
                                        calenderData?.maxval
                                      )}%`,
                                      backgroundColor: item.color, // Dynamically get color
                                    }}
                                  ></Box>
                                ) : null
                              )}
                            </Stack>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#3e5056",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "flex-end",
                              position: "relative",
                            }}
                          >
                            <Stack
                              justifyContent={"flex-end"}
                              sx={{
                                width: "60%",
                                height: "100%",
                                // background:'red'
                              }}
                            >
                              {eventData.calendar_data.map((item, index) => (
                                item?.show_flag === 1 ? (<Box
                                  key={index}
                                  sx={{
                                    width: "100%",
                                    height: `${normalizeToPercentage(
                                      item[item.name], // Dynamically get value
                                      calenderData?.minval,
                                      calenderData?.maxval
                                    )}%`,
                                    backgroundColor: item.color, // Dynamically get color
                                  }}
                                ></Box>) : null
                                
                              ))}
                            </Stack>
                          </Box>
                        )}
                      </Tooltip>
                    ) : (
                      <Tooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={3000}
                        title={
                          <>
                            {eventData.calendar_data.map((item, index) => (
                              <Typography sx={{ fontSize: "0.75rem" }}>{`${
                                item.name
                              }: ${item[item.name]} ${
                                eventData.units
                              }`}</Typography>
                            ))}
                          </>
                        }
                        arrow
                        placement="right-start"
                      >
                        {eventData.fillflag === 1 ? (
                          <Box
                            sx={{
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#F6FFE5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "flex-end",
                              position: "relative",
                            }}
                          >
                            <Box
                              sx={{
                                height: "7px",
                                width: "7px",
                                backgroundColor: "#16F325",
                                position: "absolute",
                                top: 2,
                                left: 2,
                              }}
                            ></Box>

                            {eventData.calendar_data.map((item, index) => (
                              <Box
                                sx={{
                                  height: `${normalizeToPercentage1(
                                    item[item.name],
                                    calenderData?.minval,
                                    calenderData?.maxval
                                  )}%`,
                                  backgroundColor: item.color,
                                  width: "60%",
                                  position: "relative",
                                }}
                              ></Box>
                            ))}
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              width: "100%",
                              height: "100%",
                              // backgroundColor: "red",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "flex-end",
                              position: "relative",
                            }}
                          >
                            <Box
                              sx={{
                                height: "7px",
                                width: "7px",
                                // backgroundColor: "#16F325",
                                position: "absolute",
                                top: 2,
                                left: 2,
                              }}
                            ></Box>

                            {eventData.calendar_data.map((item, index) => (
                              <Box
                                sx={{
                                  height: `${normalizeToPercentage1(
                                    item[item.name],
                                    calenderData?.minval,
                                    calenderData?.maxval
                                  )}%`,
                                  backgroundColor: item.color,
                                  width: "60%",
                                  position: "relative",
                                }}
                              ></Box>
                            ))}
                          </Box>
                        )}
                      </Tooltip>
                    )}
                  </Box>
                )}
              </Stack>
            </div>
          );
        })}
      </div>

      <Stack
        direction={"row"}
        gap={5}
        sx={{
          width: "100%",
          flexShrink: 0,
        }}
      >
        {calenderData.legend?.map((legend, index) => {
          return (
            <Stack
              direction={"row"}
              gap={1}
              key={index}
              justifyContent={"flex-end"}
            >
              <Box
                sx={{
                  backgroundColor: legend.color,
                  padding: "0.5rem",
                  height: 10,
                  width: 10,
                }}
              ></Box>
              <Typography sx={{ fontSize: "0.75rem", color: "white" }}>
                {legend.name}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default React.memo(Calendar);
