// import { Stack } from "@mui/material";

import CampaignChart from "./CampaignChart";

import { Select, Option } from "./CustomSelect";
import { Stack, useMediaQuery, useTheme, Tooltip } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

import { useEffect, useState, useLayoutEffect, useRef } from "react";
const CampaignContainer = (props) => {
  const [chartKey, setChartKey] = useState(0); // State to force chart reload

  const [divHeight, setDivHeight] = useState("");
  const [height, setHeight] = useState("");
  const [full, setFull] = useState(true);

  const handler = () => {
    setFull(!full);
  };

  const divRef = useRef(null);
  const hRef = useRef(null);

  useLayoutEffect(() => {
    const updateDivHeight = () => {
      if (divRef.current && hRef) {
        const newHeight = divRef.current.clientWidth;
        setDivHeight(newHeight);
        setHeight(hRef.current.clientHeight);
        // console.log("new height: ", newHeight);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);

  const array = [{ value: "Channel First" }, { value: "Match First" }];
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
  return (
    <Stack
      ref={hRef}
      gap={1}
      sx={{
        // height: props.height,
        height: isSmall || isMedium ? "500px" : props.height,
        // height: "100%",
        // border:"2px solid red",
        borderTop: "1px solid black",
        paddingTop: isSmall || isMedium ? "4%" : "0.5%",
        // border:"5px solid yellow",
        
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <div className="row align-items-center sorting-btn-ui">
          {/* className="col-sm-4" */}
          <div>
            <ul>
              <li onClick={props.DrillUpAllCamp} title="Drill Up">
                <i className="fa fa-arrow-up" aria-hidden="true"></i>
              </li>
              <li onClick={props.DrillDownAllCamp} title="Drill Down">
                <i className="fa fa-arrow-down" aria-hidden="true"></i>
              </li>
              <li onClick={props.DrillAllCamp} title="Expand All">
                <i className="fa fa-sitemap" aria-hidden="true"></i>
              </li>
              <li onClick={props.ResetAllCamp} title="Reset">
                <i className="fa" aria-hidden="true">
                  &#8635;
                </i>
              </li>
            </ul>
          </div>
        </div>
        <Stack direction={"row"} alignItems={"center"} gap={2}>
          {full ? (
            <Tooltip title="Zoom Out">
              <ZoomOutIcon
                onClick={handler}
                sx={{ color: "white", cursor: "pointer" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Zoom In">
              <ZoomInIcon
                onClick={handler}
                sx={{ color: "white", cursor: "pointer" }}
              />
            </Tooltip>
          )}
          <Select
            value={props.defaultView}
            onChange={(e) => {
              props.setDefaultView(e.target.value);
              // props.setDisabledFlag(true);
              props.setFetchCallCamp(true);
              // props.setIsSubmit(true);
            }}
            selected={props.defaultView}
            sx={{
              backgroundColor: "bgColor",
              color: "white",
              fontSize: "0.75rem",
              borderRadius: "5px",
              padding: "0.1rem 0.15rem",
              border: "1px solid #3E5056",
            }}
          >
            {array.map((item) => {
              return (
                <Option key={item.value} value={item.value}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </Stack>
      </Stack>
      <div
        ref={divRef}
        style={{
          // overflowx:
          //   isSmall || isMedium ? "auto" : "",
          // overflowy:
          //   isSmall || isMedium ? "hidden" : "scroll" ,
          // whitespace:
          //   isSmall || isMedium ? "nowrap" : "",
          // overflow: isSmall || isMedium ? "scroll" : "",
          //   border: "2px solid yellow",
          overflowX: "scroll",
        }}
      >
        {props.campData && props.campData.series && (
          <CampaignChart
            key={chartKey}
            campData={props.campData}
            DrillDownCamp={props.DrillDownCamp}
            DrillUpCamp={props.DrillUpCamp}
            divWidth={divHeight}
            height={height}
            full={full}
          />
        )}
      </div>
    </Stack>
  );
};

export default CampaignContainer;
