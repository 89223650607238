import React from "react";
// import Logo from "../images/skewb-logomark.png";

//LOGO
import LogoMark from "../images/skewb-logomark.png";
import LavenderLogo from "../images/skewb-logomark-c1.png";
import SapphireLogo from "../images/skewb-logomark-c3.png";
import TealLogo from "../images/skewb-logomark-c5.png";
import CyanLogo from "../images/skewb-logomark-c7.png";
import GoldenrodLogo from "../images/skewb-logomark-b4.png";

const Loader = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(0, 0, 0,0.5)",
        zIndex: "9999",
        display: "flex",
      }}
    >
      <div className="loader-skewb" style={{ margin: "auto" }}>
        <img
        src={localStorage.getItem("CurrentTheme") === "Dark" ?
        LogoMark :
        localStorage.getItem("ColorCode") === "Lavender" ?
          LavenderLogo :
          localStorage.getItem("ColorCode") === "Sapphire" ?
            SapphireLogo :
            localStorage.getItem("ColorCode") === "Teal" ?
              TealLogo :
              localStorage.getItem("ColorCode") === "Cyan" ?
                CyanLogo :
                localStorage.getItem("ColorCode") === "Goldenrod" ?
                  GoldenrodLogo :  LogoMark}
        //   style={{ height: "100px", width: "100px" }}
          alt="Loading..."
        />
      </div>
    </div>
  );
};

export default Loader;
