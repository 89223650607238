import { Box } from "@mui/material";

import Tree from "./Tree";

const Sidebar = ({ hierarchy, setAllChannelsFun, setOptSummary, setChartData }) => {
  return (
    <Box
      sx={{
        padding: "1rem",
        height: "100%",
        width: "100%",
        borderRadius: "1rem 0 0 1rem",
        backgroundColor: "bgColor",
      }}
    >
      <Box
        flexGrow={1}
        sx={{
          // border: "1px solid red",
          overflow: "scroll",
          height: "100%",
          "&::-webkit-scrollbar": {
            width: "4px",
          },

          "&::-webkit-scrollbar-track": {
            background: "#1C2427",
          },

          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "gray",
            borderRadius: "10px",
          },
        }}
      >
        <Tree hierarchy={hierarchy} setAllChannelsFun={setAllChannelsFun} setOptSummary={setOptSummary} setChartData={setChartData} />
      </Box>
    </Box>
  );
};

export default Sidebar;
