// import DatePicker from "react-datepicker";
import {
  Box,
  Stack,
  Typography,
  Button,
  SvgIcon,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CustomCalendar from "./CustomCalendar";
import SingleCascading from "./SingleCascading";
import { Select, Option } from "./CustomSelect";
import Cascading from "./Cascading";
import DateInput from "./DataInput";
import ToggleButton from "react-toggle-button";
import NewMultiCascader from "./NewMultiCascader";
import React, { useState, useEffect } from "react";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import MultiSelect from "./MultiSelect";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import axios from "../../axios";
import CascaderFocusWrapper from "./CascaderFocusWrapper";
import baseURL from "../../BaseURL2";
const Aside = (props) => {
  const [isFocused, setIsFocused]=useState(false);
  console.log("CascasderDefaultValue ",props.CascasderDefaultValue);
  const data = [
    {
      id: "bottomfunnel",
      value: "bottomfunnel",
      name: "bottomfunnel",
      access: 1,
      children: [
        {
          id: "bottomfunnel-install",
          value: "install",
          name: "Downloads",
          access: 1,
          children: [],
        },
        {
          id: "bottomfunnel-closure",
          value: "closure",
          name: "Closure",
          access: 1,
          children: [],
        },
        {
          id: "bottomfunnel-lead",
          value: "lead",
          name: "Leads",
          access: 1,
          children: [],
        },
      ],
    },
    {
      id: "midfunnel",
      value: "midfunnel",
      name: "midfunnel",
      access: 1,
      children: [
        {
          id: "midfunnel-newtraffic",
          value: "newtraffic",
          name: "Traffic",
          access: 1,
          children: [],
        },
        {
          id: "midfunnel-bht",
          value: "bht",
          name: "bht",
          access: 1,
          children: [
            {
              id: "midfunnel-bht-searches",
              value: "searches",
              name: "Web Searches",
              access: 1,
              children: [],
            },
          ],
        },
      ],
    },
  ];

  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  const [none, setNone] = useState(false);

  const [teamChanged, setTeamChanged] = useState(false);
  const [matchIdChanged, setMatchIdChanged] = useState(false);
  const [locationChnaged, setLocationChanged] = useState(false);
  const [weekflagChanged, setWeekflagChanged] = useState(false);
  const [daypartChanged, setDaypartChanged] = useState(false);

  // const [selectedState, setSelectedState] = useState("");
  // const [selectedCity, setSelectedCity] = useState("");
  // const [stateChanged, setStateChanged] = useState(false);
  // const [cityChanged, setCityChanged] = useState(false);
 
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [channelData, setChannelData] = useState([]);
  const [geoData, setGeoData] = useState([]);
  

  function anyTrue(arr) {
    return arr.includes(true);
  }

  useEffect(()=>{
    const fetchGeoData = async () =>{
      try{
        const response = await axios.post(`geo_filter/`,{});
        setGeoData(response.data.data);
      }
      catch(err){
        console.log(err);
      }
    }

    // fetchGeoData();
    
  },[])

  console.log(" kpi filterLiast", props.kpiFilterList);

  useEffect(()=>{
    console.log("checkedList 111",props.checkedList)
  },[props.checkedList])
  // useEffect(() => {
  //   console.log(
  //     "anytrue flag: ",
  //     anyTrue([
  //       teamChanged,
  //       matchIdChanged,
  //       locationChnaged,
  //       weekflagChanged,
  //       daypartChanged,
  //     ]) && props.disabledFlag
  //   );

  //   console.log("disabled flag: ", props.disabledFlag);
  // }, []);

  const [validEndDate, setValidEndDate] = useState(
    new Date(props.defaultStartDate) > new Date(props.defaultEndDate)
      ? props.defaultStartDate
      : props.defaultEndDate
  );

  useEffect(() => {
    setValidEndDate(
      new Date(props.defaultStartDate) > new Date(props.defaultEndDate)
        ? props.defaultStartDate
        : props.defaultEndDate
    );
    props.setDefaultEndDate(
      new Date(props.defaultStartDate) > new Date(props.defaultEndDate)
        ? props.defaultStartDate
        : props.defaultEndDate
    );
  }, [props.defaultStartDate, props.defaultEndDate]);

  // console.log("validEndDate: ", validEndDate);

  const displayNoneHandler = () => {
    setNone(!none);
  };

  const iconSummary = !none ? (
    <SvgIcon
      component={RemoveTwoToneIcon}
      sx={{
        cursor: "pointer",
        color: "white",
        fontSize: "24px",
        fontWeight: 700,
      }}
    />
  ) : (
    <SvgIcon
      component={AddTwoToneIcon}
      sx={{
        cursor: "pointer",
        color: "white",
        fontSize: "24px",
        fontWeight: 700,
      }}
    />
  );

  const IPL_2024_START = new Date("2024-03-22");
  const IPL_2024_END = new Date("2024-05-26");
  const IPL_2025_START = new Date("2025-03-22");
  const IPL_2025_END = new Date("2025-05-25");
 
  const selectedStart = new Date(props.defaultStartDate);
  const selectedEnd = new Date(props.defaultEndDate);
 
  const isOutsideIPL =
    selectedEnd < IPL_2024_START ||
    (selectedStart > IPL_2024_END && selectedEnd < IPL_2025_START) ||
    selectedStart > IPL_2025_END;
 

  return (
    <Stack
      sx={{
        backgroundColor: "bgColor",
        padding: "0 1rem 1rem 1rem",
        width: isMedium || isSmall ? "100%" : "35%",
        borderRadius: "20px",
        height: "100%",
        gap: "1%",
        // border: "1px solid red",
      }}
    >
      <Stack
        onClick={() => {
          displayNoneHandler();
          props.setDropDownClick(false);
        }}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          cursor: "pointer",
          backgroundColor: "bgColor",
          borderBottom: "1px solid black",
          // padding: "0.5rem 0",
          height: "6%",
        }}
      >
        <Typography sx={{ color: "white", fontSize: "0.9rem" }}>
          Apply Filters
        </Typography>
        <Box
          sx={{
            color: "#d6ff41",
          }}
        >
          {iconSummary}
        </Box>
      </Stack>

      {/* time period selection start....... */}
      <Box
        onClick={() => props.setDropDownClick(false)}
        sx={{
          display: none ? "none" : "block",
          height: "22%",
          borderBottom: "1px solid black",
        }}
      >
        <Stack
          direction={"row"}
          gap={1}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            padding: "0.5rem 0",
          }}
        >
          <Typography sx={{ color: "themeColor", fontSize: "0.75rem" }}>
            Time Period:{" "}
          </Typography>

          <Typography sx={{ color: "white", fontSize: "0.75rem" }}>
            From:{" "}
          </Typography>

          <DateInput
            type={"date"}
            value={props.defaultStartDate}
            onChange={props.setDefaultStartDate}
            min={props.startDate}
            max={props.endDate}
            disabledFlagSubmit={props.setDisabledFlag}
            setPayload={props.setPayload}
            setDisabledFlag={props.setDisabledFlag}
            setIsSubmit={props.setIsSubmit}
            setTeampayload={props.setTeampayload}
            setCityPayload={props.setCityPayload}
            setDaypartpayload={props.setDaypartpayload}
            setMatchTeamVsPayload={props.setMatchTeamVsPayload}
            setWeekflagpayload={props.setWeekflagpayload}
          />
          <Typography sx={{ color: "white", fontSize: "0.75rem" }}>
            To:{" "}
          </Typography>

          <DateInput
            type={"date"}
            // value={props.defaultEndDate}
            value={validEndDate}
            onChange={props.setDefaultEndDate}
            min={props.defaultStartDate}
            max={props.endDate}
            disabledFlagSubmit={props.setDisabledFlag}
            setPayload={props.setPayload}
            setDisabledFlag={props.setDisabledFlag}
            setIsSubmit={props.setIsSubmit}
            setTeampayload={props.setTeampayload}
            setCityPayload={props.setCityPayload}
            setDaypartpayload={props.setDaypartpayload}
            setMatchTeamVsPayload={props.setMatchTeamVsPayload}
            setWeekflagpayload={props.setWeekflagpayload}
          />
        </Stack>
        {/* time period selection end...... */}

        {/* match selection start */}
        <Stack
          sx={{
            padding: "0.5rem 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              direction: "row",
              flexWrap: "wrap",
              gap: 2,
              pointerEvents: isOutsideIPL ? "none" : "all",
              opacity: isOutsideIPL ? 0.3 : 1,
            }}
          >
            <Typography sx={{ color: "themeColor", fontSize: "0.75rem" }}>
              Match Filters:{" "}
            </Typography>
            <Stack direction={"row"} gap={1}>
              <Select
                value={props.allTeams[0]?.value}
                onChange={(e) => {
                  props.selectTeamFromDropDown(e.target.value);
                  props.setTeampayload({ team: e.target.value });
                  props.setTeamId(e.target.value);
                  props.setDisabledFlag(false);
                  props.setIsSubmit(false);
                  setTeamChanged(true);
                }}
                selected={props.allTeams[0]?.value}
                sx={{
                  backgroundColor: "bgColor",
                  color: "white",
                  fontSize: "0.75rem",
                  borderRadius: "5px",
                  padding: "0.1rem 0.15rem",
                  border: "1px solid #3E5056",
                }}
              >
                {props.allTeams &&
                  props.allTeams.map((team) => {
                    return (
                      <Option key={team.value} value={team.value}>
                        {team.label}
                      </Option>
                    );
                  })}
              </Select>
              {teamChanged && (
                <RefreshSharpIcon
                  fontSize="small"
                  onClick={() => {
                    props.setTeampayload("");
                    setTeamChanged(false);
                    props.setIsSubmit(false);
                    props.setDisabledFlag(false);
                  }}
                  sx={{ color: "themeColor", cursor: "pointer" }}
                />
              )}
            </Stack>
            {/* </Box> */}

            {/* match selection end ...................................*/}

            {/* team vs team selection start */}
            <Box
              direction={"column"}
              gap={1}
              sx={{ backgroundColor: "bgColor" }}
            >
              <Stack>
                <Stack direction={"row"} gap={1}>
                  <Select
                    value={props.teamMatchesVs[0]?.value}
                    onChange={(e) => {
                      props.setDefultMatchTeamVs(e.target.value);
                      props.setDisabledFlag(false);
                      setMatchIdChanged(true);
                      props.setIsSubmit(false);
                      props.setMatchTeamVsPayload({
                        matchid: e.target.value,
                      });
                    }}
                    selected={props.teamMatchesVs[0]?.value}
                    sx={{
                      backgroundColor: "bgColor",
                      color: "white",
                      fontSize: "0.75rem",
                      borderRadius: "5px",
                      padding: "0.1rem 0.15rem",
                      border: "1px solid #3E5056",
                    }}
                  >
                    {props.teamMatchesVs &&
                      props.teamMatchesVs.map((teamVsTeam) => {
                        return (
                          <Option
                            key={teamVsTeam.value}
                            value={teamVsTeam.value}
                          >
                            {teamVsTeam.label}
                          </Option>
                        );
                      })}
                  </Select>
                  {matchIdChanged && (
                    <RefreshSharpIcon
                      fontSize="small"
                      onClick={() => {
                        props.setMatchTeamVsPayload("");
                        setMatchIdChanged(false);
                        props.setIsSubmit(false);
                        props.setDisabledFlag(false);
                      }}
                      sx={{ color: "themeColor", cursor: "pointer" }}
                    />
                  )}
                </Stack>
              </Stack>
            </Box>

            {/* team vs team selection end */}

            {/* location selection start */}
            <Box
              direction={"column"}
              gap={1}
              sx={{ backgroundColor: "bgColor" }}
            >
              <Stack direction={"row"} gap={1}>
                <Select
                  value={props.defaultCity}
                  onChange={(e) => {
                    props.setDefaultCity(e.target.value);
                    props.setCityPayload({ city: e.target.value });
                    props.setDisabledFlag(false);
                    props.setIsSubmit(false);
                    setLocationChanged(true);
                  }}
                  selected={props.defaultCity}
                  sx={{
                    backgroundColor: "bgColor",
                    color: "white",
                    fontSize: "0.75rem",
                    borderRadius: "5px",
                    padding: "0.1rem 0.15rem",
                    border: "1px solid #3E5056",
                  }}
                >
                  {props.allCities &&
                    [...new Set(props.allCities)].map((day) => (
                      <Option key={day} value={day}>
                        {day}
                      </Option>
                    ))}
                </Select>
                {locationChnaged && (
                  <RefreshSharpIcon
                    fontSize="small"
                    onClick={() => {
                      props.setCityPayload("");
                      setLocationChanged(false);
                      props.setIsSubmit(false);
                      props.setDisabledFlag(false);
                    }}
                    sx={{ color: "themeColor", cursor: "pointer" }}
                  />
                )}
              </Stack>
            </Box>
            {/* location selection end */}

            {/* days selection start */}
            <Box
              direction={"column"}
              gap={1}
              sx={{ backgroundColor: "bgColor" }}
            >
              <Stack direction={"row"} gap={1}>
                <Select
                  value={props.defaultWeekFlag}
                  onChange={(e) => {
                    props.setDefaultWeekFlag(e.target.value);
                    props.setWeekflagpayload({ wd_flag: e.target.value });
                    props.setDisabledFlag(false);
                    props.setIsSubmit(false);
                    setWeekflagChanged(true);
                  }}
                  selected={props.defaultWeekFlag}
                  sx={{
                    backgroundColor: "bgColor",
                    color: "white",
                    fontSize: "0.75rem",
                    borderRadius: "5px",
                    padding: "0.1rem 0.15rem",
                    border: "1px solid #3E5056",
                  }}
                >
                  {props.allWeekFlag &&
                    [...new Set(props.allWeekFlag)].map((day) => (
                      <Option key={day} value={day}>
                        {day}
                      </Option>
                    ))}
                </Select>
                {weekflagChanged && (
                  <RefreshSharpIcon
                    fontSize="small"
                    onClick={() => {
                      props.setWeekflagpayload("");
                      setWeekflagChanged(false);
                      props.setIsSubmit(false);
                      props.setDisabledFlag(false);
                    }}
                    sx={{ color: "themeColor", cursor: "pointer" }}
                  />
                )}
              </Stack>
            </Box>
            {/* days selection end */}

            {/* match timing selection start */}
            <Box
              direction={"column"}
              gap={1}
              sx={{ backgroundColor: "bgColor" }}
            >
              <Stack direction={"row"} gap={1}>
                <Select
                  value={props.defaultDaypart}
                  onChange={(e) => {
                    props.setDefaultpart(e.target.value);
                    props.setDaypartpayload({ daypart: e.target.value });
                    props.setDisabledFlag(false);
                    props.setIsSubmit(false);
                    setDaypartChanged(true);
                  }}
                  selected={props.defaultDaypart}
                  sx={{
                    backgroundColor: "bgColor",
                    color: "white",
                    fontSize: "0.75rem",
                    borderRadius: "5px",
                    padding: "0.1rem 0.15rem",
                    border: "1px solid #3E5056",
                  }}
                >
                  {props.allDaypart &&
                    [...new Set(props.allDaypart)].map((time) => (
                      <Option key={time} value={time}>
                        {time}
                      </Option>
                    ))}
                </Select>
                {daypartChanged && (
                  <RefreshSharpIcon
                    fontSize="small"
                    onClick={() => {
                      props.setDaypartpayload("");
                      setDaypartChanged(false);
                      props.setIsSubmit(false);
                      props.setDisabledFlag(false);
                    }}
                    sx={{ color: "themeColor", cursor: "pointer" }}
                  />
                )}
              </Stack>
            </Box>
            {/* match timing selection end */}
          </Box>

          {/* buttons reset and submit */}
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ paddingTop: 2 }}
          >
            <Button
              disabled={
                !anyTrue([
                  teamChanged,
                  matchIdChanged,
                  locationChnaged,
                  weekflagChanged,
                  daypartChanged,
                ])
              }
              onClick={() => {
                props.setFetchCall(true); // for calender data api call
                props.setPayload({});
                props.setIsSubmit(false);
                props.setDisabledFlag(false);
                props.setTeampayload("");
                props.setCityPayload("");
                props.setDaypartpayload("");
                props.setMatchTeamVsPayload("");
                props.setWeekflagpayload("");
                //
                setTeamChanged(false);
                setMatchIdChanged(false);
                setLocationChanged(false);
                setWeekflagChanged(false);
                setDaypartChanged(false);
              }}
              sx={{
                // backgroundColor: "#d6ff41",
                border: !anyTrue([
                  teamChanged,
                  matchIdChanged,
                  locationChnaged,
                  weekflagChanged,
                  daypartChanged,
                ])
                  ? "1px solid #3E5056"
                  : "1px solid #d6ff41",
                fontSize: "0.75rem",
                width: "100px",
                borderRadius: "5px",
                textAlign: "center",
                cursor: "pointer",
                color: "#d6ff41",
                pointerEvents: isOutsideIPL ? "none" : "all",
                opacity: isOutsideIPL ? 0.3 : 1,
                padding: "0.1rem 0",
                "&:hover": {
                  color: "black",
                  backgroundColor: "#d6ff41", // Change color on hover
                },
              }}
            >
              match reset
            </Button>
            <Button
              onClick={() => {
                props.setFetchCall(true);
                props.setDisabledFlag(true);
                props.setIsSubmit(true);
                props.setFetchCallTrends(true);
                props.setFetchCallBaseline(true);
                props.setFetchCallCamp(true);
                props.setFetchCallLine(true);
                props.setFetchCallFunnel(true);
                props.setFetchCallMatchStack(true);
              }}
              disabled={props.disabledFlag}
              sx={{
                // backgroundColor: props.disabledFlag ? "" : "#d6ff41",
                fontSize: "0.75rem",
                width: "100px",
                borderRadius: "5px",
                textAlign: "center",
                cursor: "pointer",
                padding: "0.1rem 0",
                color: "#d6ff41",
                border: !props.disabledFlag
                  ? "1px solid #d6ff41"
                  : "1px solid #3E5056",

                "&:hover": {
                  color: "black",
                  backgroundColor: "#d6ff41", // Change color on hover
                },
              }}
            >
              submit
            </Button>
          </Stack>
          {/* button reset and submit end......... */}
        </Stack>

        {/* match selection end */}
      </Box>

      {/* time perid selection end */}

      {/* bottom 2nd part start */}
      <Stack sx={{ height: none ? "93%" : "70%" }}>
        {/* KPI and Filter Controls */}
        <Stack
          sx={{
            // marginBottom: 1,
            flexShrink: 0, // Prevent controls from shrinking
          }}
        >
          {/* Business KPI section */}
          <div
            // gap={2}
            style={{
              width: "100%",
              display: "flex",
              // marginBottom: 2  // Add space between KPI and State/City
            }}
          >
            {/* State and City Selection */}
            <Stack
              direction="row"
              gap={1}
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                // justifyContent: "space-between",
                // marginTop: 2,
                // marginBottom: 2, // Add space before calendar
              }}
            >
              <Typography
                sx={{
                  color: "themeColor",
                  fontSize: "0.75rem",
                  whiteSpace: "nowrap",
                }}
              >
                Business KPI:{" "}
              </Typography>
              <CascaderFocusWrapper componentId="kpi"  isFocused={isFocused} setIsFocused={setIsFocused} >
              
              <SingleCascading
                data={props.cascaderKPIData}
                key={props.CascasderDefaultValue}
                setGetBrand={props.setGetBrand}
                match={props.CascasderDefaultValue}
                setIsFocused={setIsFocused}
              />
              </CascaderFocusWrapper>

            </Stack>

            <div
              style={{
                display: "flex",
                gap: "8px", // equivalent to gap={1}
                justifyContent: isSmall || isMedium ? "flex-start" : "flex-end",
                width: "100%",
                alignItems: "center", // Added to ensure vertical alignment
              }}
            >
              <Typography
                sx={{
                  color: !props.toggle ? "#d6ff41" : "white",
                  fontSize: "0.85rem",
                }}
              >
                Absolute
              </Typography>
              <ToggleButton
                value={props.toggle}
                inactiveLabel=""
                activeLabel=""
                onToggle={(value) => {
                  props.setToggle(!value);
                  props.setFetchCall(true);
                  props.setIsSubmit(true);
                }}
                colors={{
                  activeThumb: {
                    base: "#d6ff41",
                  },
                  inactiveThumb: {
                    base: "#d6ff41",
                  },
                  active: {
                    base: "#3E5056",
                  },
                  inactive: {
                    base: "#3E5056",
                  },
                }}
              />
              <Typography
                sx={{
                  color: props.toggle ? "#d6ff41" : "white",
                  fontSize: "0.85rem",
                }}
              >
                Incremental
              </Typography>
            </div>

            {/* <Box
              sx={{
                display: "flex",
                direction: "row",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              <Box
                direction={"column"}
                gap={1}
                sx={{ backgroundColor: "bgColor" }}
              >
                <Stack>
                  <Stack direction={"row"} gap={1}>
                    
                    <MultiSelect
                      selectKpiFilterFromDropDown={
                        props.selectKpiFilterFromDropDown
                      }
                      defaultKpiFilter={props.defaultKpiFilter}
                      kpiFilterList={props.kpiFilterList}
                      setDisabledFlag={props.setDisabledFlag}
                      setFetchCallTrends={props.setFetchCallTrends}
                      setFetchCallCamp={props.setFetchCallCamp}
                      setIsSubmit={props.setIsSubmit}
                      setFetchCall={props.setFetchCall}
                      setFetchCallLine={props.setFetchCallLine}
                      setFetchCallBaseline={props.setFetchCallBaseline}
                      setFetchCallFunnel={props.setFetchCallFunnel}
                      setFetchCallMatchStack={props.setFetchCallMatchStack}
                    />

                    <MultiSelect
                      selectKpiFilterFromDropDown={
                        props.selectKpiFilterFromDropDown2
                      }
                      defaultKpiFilter={props.defaultKpiFilter2}
                      kpiFilterList={props.kpiFilterList2}
                      setDisabledFlag={props.setDisabledFlag}
                      setFetchCallTrends={props.setFetchCallTrends}
                      setFetchCallCamp={props.setFetchCallCamp}
                      setIsSubmit={props.setIsSubmit}
                      setFetchCall={props.setFetchCall}
                      setFetchCallLine={props.setFetchCallLine}
                      setFetchCallBaseline={props.setFetchCallBaseline}
                      setFetchCallFunnel={props.setFetchCallFunnel}
                      setFetchCallMatchStack={props.setFetchCallMatchStack}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Box> */}
          </div>

          <div style={{ display: "flex" , marginBottom:'10px', marginTop:'5px'}}>
            {/* <Typography
              sx={{
                color: "themeColor",
                fontSize: "0.75rem",
                whiteSpace: "nowrap",
                display: "flex",
                // marginBottom:'10px',
                alignItems: "center",
              }}
            >
              Business KPI:{" "}
            </Typography> */}

            <div
              style={{
                position: "relative",
                marginLeft: "5px",
                marginTop: "2px",
                // border: "2px solid red",
              }}
            >
              
              {/* <CascaderFocusWrapper componentId="kpi">
              
              <SingleCascading
                data={props.cascaderKPIData}
                key={props.CascasderDefaultValue}
                setGetBrand={props.setGetBrand}
                match={props.CascasderDefaultValue}
              />
              </CascaderFocusWrapper> */}
              {/* <Cascading
                  kpiFilterList={props.kpiFilterList}
                  setDefaultKpiFilter2={props.setDefaultKpiFilter2}
                  setDisabledFlag={props.setDisabledFlag}
                  setFetchCallTrends={props.setFetchCallTrends}
                  setFetchCallCamp={props.setFetchCallCamp}
                  setIsSubmit={props.setIsSubmit}
                  setFetchCall={props.setFetchCall}
                  setFetchCallLine={props.setFetchCallLine}
                  setFetchCallBaseline={props.setFetchCallBaseline}
                  setFetchCallFunnel={props.setFetchCallFunnel}
                  setFetchCallMatchStack={props.setFetchCallMatchStack}
                  defaultKpiFilter2={props.defaultKpiFilter2}
                  defaulLabelKpiFilter2={props.defaulLabelKpiFilter2}
                  setDefauLabelKpiFilter2={props.setDefauLabelKpiFilter2}
                  defaultKpiFilter1={props.defaultKpiFilter1}
                  setDefaultKpiFilter1={props.setDefaultKpiFilter1}
                  dropDownClick={props.dropDownClick}
                  setDropDownClick={props.setDropDownClick}
                /> */}
            </div>
          </div>
        </Stack>
              <div className="no-cursor">

        {/* Calendar Section */}
        {props.defaultStartDate &&
          props.defaultEndDate &&
          props.calenderData && (
            <Box sx={{ flex: 1, overflow: "hidden" }}>
              <CustomCalendar
                startDate={new Date(props.defaultStartDate)}
                endDate={new Date(props.defaultEndDate)}
                calenderData={props.calenderData}
                toggle={props.toggle}
                isSubmit={props.isSubmit}
                height="100%"
                none={none}
                setDropDownClick={props.setDropDownClick}
              />
            </Box>
          )}
          </div>
      </Stack>
    </Stack>
  );
};

export default React.memo(Aside);
