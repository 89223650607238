import React, { useState } from "react";

import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";

import OptimizeForm from "./OptimizeForm/OptimizeForm";
import DeepDive from "./DeepDive";

// mui imports
import { Box, CssBaseline } from "@mui/material/";
import { createTheme, ThemeProvider } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    background: {
      default: "black", // Set the default background color
    },
    themeColor: "#d6ff41",
    bgColor: "#1c2427",
    inputBorderColor: "#3E5056",
  },

  typography: {
    // htmlFontSize: 10,
    fontFamily: ["Inter"].join(","),
    fontSize: 10,
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },

  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "clamp(0.5rem, .75vw + 0.4rem, 2rem)",
          // height: "100%",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        // large
        h5: {
          fontSize: "clamp(1rem, 1vw + 0.25rem, 1.75rem)", // 3rem
        },

        // medium
        h6: {
          fontSize: "clamp(0.75rem, 0.5vw + 0.4rem, 1.25rem)", // 2rem
        },

        // xs- small
        body1: {
          fontSize: "clamp(0.5rem, 0.5vw + 0.25rem, 1rem)",
        },

        // small
        body2: {
          fontSize: "clamp(1rem, 2vw + 1rem, 2rem)",
        },
      },
    },
  },
});

const Main = () => {
  const [hierarchy, setHierarchy] = useState([]);
  const [deepDive, setDeepDive] = useState(false);

  const [saveResult, setSaveResult] = useState(false);
  const [optimizationType, setOptimizationType] = useState("budget");
  const [optimizationTypeValue, setOptimizationTypeValue] = useState("");
  const [resultName, setResultName] = useState("");
  const [saveResponse, setSaveResponse] = useState("");
  const [getBrandName, setGetBrandName] = useState("");
  const [getKpiName, setGetKpiName] = useState("")
  const [getTimeframe, setGetTimeframe] = useState("");
  const [getTimeperiod, setGetTimeperiod] = useState("");
  // console.log("optimizationType: ", optimizationType);
  // console.log("optimizationTypeValue: ", optimizationTypeValue);
  // console.log("saveResponse: ", saveResponse);
  // console.log("resultName: ", resultName);
  // console.log(getBrandName, getKpiName, getTimeframe);

  const handler = (data) => {
    setHierarchy(data);
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Topbar />
        <Menubar />
        <div className="main-content">
          <div className="page-content">
            <Box sx={{ maxWidth: "xl", margin: "auto", height: "85vh", width: "100%" }}>
              {!deepDive && (
                <OptimizeForm
                  hierarchy={hierarchy}
                  handler={handler}
                  setDeepDive={setDeepDive}
                  setOptimizationType={setOptimizationType}
                  setOptimizationTypeValue={setOptimizationTypeValue}
                  setSaveResponse={setSaveResponse}
                  setGetBrandName={setGetBrandName}
                  setGetKpiName={setGetKpiName}
                  setGetTimeframe={setGetTimeframe}
                  setGetTimeperiod={setGetTimeperiod}
                />
              )}
              {deepDive && (
                <DeepDive
                  hierarchy={hierarchy}
                  saveResult={saveResult}
                  setSaveResult={setSaveResult}
                  optimizationType={optimizationType}
                  optimizationTypeValue={optimizationTypeValue}
                  setResultName={setResultName}
                  saveResponse={saveResponse}
                  resultName={resultName}
                  getBrandName={getBrandName}
                  getKpiName={getKpiName}
                  getTimeframe={getTimeframe}
                  getTimeperiod={getTimeperiod}
                  handler={handler}
                />
              )}
            </Box>
          </div>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Main;
