import { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  Box,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import DateInput from "./DateInput";
import Cascader from "./Cascader";
import NewMultiCascader from "../Overview/NewMultiCascader"
import SingleCascading from "../Overview/SingleCascading"
import CascaderFocusWrapper from "../Overview/CascaderFocusWrapper";
 
const Header = ({
  startDate,
  endDate,
  defaultStartDate,
  defaultEndDate,
  setDefaultStartDate,
  setDefaultEndDate,
  dropDownClick,
  setDropDownClick,
  kpiFilterList,
  setDefaultKpiFilter2,
  setDefauLabelKpiFilter2,
  defaulLabelKpiFilter2,
  setFetchCall,
  setIsSubmit,
  disabledFlag,
  setDisabledFlag,
  activeTabName,
  setActiveTabName,
  setToggle,
  cascaderKPIData,
  CascasderDefaultValue,
  geoData,
  setGetBrand,
  checkedList,
  setCheckedList
}) => {
  const [isFocused, setIsFocused]=useState(false);
  const [validEndDate, setValidEndDate] = useState(
    new Date(defaultStartDate) > new Date(defaultEndDate)
      ? defaultStartDate
      : defaultEndDate
  );
 
  useEffect(() => {
    setValidEndDate(
      new Date(defaultStartDate) > new Date(defaultEndDate)
        ? defaultStartDate
        : defaultEndDate
    );
    setDefaultEndDate(
      new Date(defaultStartDate) > new Date(defaultEndDate)
        ? defaultStartDate
        : defaultEndDate
    );
  }, [defaultStartDate, defaultEndDate]);
 
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
  const stateAndCityData = [
    {
      id: "india",
      name: "India",
      children: [
        {
          id: "maharashtra",
          name: "Maharashtra",
          children: [
            {
              id: "mumbai",
              name: "Mumbai",
              children: [],
            },
            {
              id: "pune",
              name: "Pune",
              children: [],
            },
            {
              id: "nagpur",
              name: "Nagpur",
              children: [],
            },
          ],
        },
        {
          id: "karnataka",
          name: "Karnataka",
          children: [
            {
              id: "bangalore",
              name: "Bangalore",
              children: [],
            },
            {
              id: "mysore",
              name: "Mysore",
              children: [],
            },
            {
              id: "hubli",
              name: "Hubli",
              children: [],
            },
          ],
        },
        {
          id: "gujarat",
          name: "Gujarat",
          children: [
            {
              id: "ahmedabad",
              name: "Ahmedabad",
              children: [],
            },
            {
              id: "surat",
              name: "Surat",
              children: [],
            },
            {
              id: "vadodara",
              name: "Vadodara",
              children: [],
            },
          ],
        },
        {
          id: "tamil-nadu",
          name: "Tamil Nadu",
          children: [
            {
              id: "chennai",
              name: "Chennai",
              children: [],
            },
            {
              id: "coimbatore",
              name: "Coimbatore",
              children: [],
            },
            {
              id: "madurai",
              name: "Madurai",
              children: [],
            },
          ],
        },
        {
          id: "delhi",
          name: "Delhi",
          children: [
            {
              id: "new-delhi",
              name: "New Delhi",
              children: [],
            },
            {
              id: "north-delhi",
              name: "North Delhi",
              children: [],
            },
            {
              id: "south-delhi",
              name: "South Delhi",
              children: [],
            },
          ],
        },
      ],
      access: 1,
    },
  ];
  // const [checkedList, setCheckedList] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [channelData, setChannelData] = useState([]);
  
 
  return (
    <Stack
      direction={isMedium || isSmall ? "column" : "row"}
      alignItems={isMedium || isSmall ? "flex-start" : "center"}
      gap={3}
      sx={{
        height: isMedium || isSmall ? "auto" : "6%",
        padding: "0.5rem 1rem",
        backgroundColor: "#1c2427",
        borderRadius: "20px",
      }}
    >
      <Stack
        onClick={() => setDropDownClick(false)}
        direction={isMedium || isSmall ? "column" : "row"}
        gap={3}
        alignItems={isMedium || isSmall ? "flex-start" : "center"}
        sx={{
          padding: "0.5rem 0",
        }}
      >
        <Typography sx={{ color: "themeColor", fontSize: "0.85rem" }}>
          Time Period:{" "}
        </Typography>
 
        <Stack direction={"row"} gap={3}>
          <Typography sx={{ color: "white", fontSize: "0.85rem" }}>
            From:{" "}
          </Typography>
 
          <DateInput
            type={"date"}
            value={defaultStartDate}
            onChange={setDefaultStartDate}
            min={startDate}
            max={endDate}
            setDisabledFlag={setDisabledFlag}
            setFetchCall={setFetchCall}
          />
          <Typography sx={{ color: "white", fontSize: "0.85rem" }}>
            To:{" "}
          </Typography>
 
          <DateInput
            type={"date"}
            value={validEndDate}
            onChange={setDefaultEndDate}
            min={defaultStartDate}
            max={endDate}
            setDisabledFlag={setDisabledFlag}
            setFetchCall={setFetchCall}
          />
        </Stack>
 
        <Button
          onClick={() => {
            setFetchCall(true);
            setDisabledFlag(true);
            setIsSubmit(true);
          }}
          disabled={disabledFlag}
          sx={{
            // backgroundColor: props.disabledFlag ? "" : "#d6ff41",
            fontSize: "0.75rem",
            width: "100px",
            borderRadius: "5px",
            textAlign: "center",
            cursor: "pointer",
            padding: "0.1rem 0",
            color: "#d6ff41",
            border: !disabledFlag ? "1px solid #d6ff41" : "1px solid #3E5056",
 
            "&:hover": {
              color: "black",
              backgroundColor: "#d6ff41", // Change color on hover
            },
          }}
        >
          submit
        </Button>
      </Stack>
      <Stack direction={"row"} gap={3}>
      {/* <Typography
                sx={{
                  color: "themeColor",
                  fontSize: "0.75rem",
                  whiteSpace: "nowrap",
                  display:'flex',
                  alignItems:"center"
                }}
              >
                Geo KPI:{" "}
              </Typography> */}
              {/* <NewMultiCascader
              data={[geoData]}
              match={"tea-green-jasmine"}
              placeholder={"Select Location"}
              setCheckedList={setCheckedList}
              checkedList={checkedList}
              selectedCheckboxes={selectedCheckboxes}
              setSelectedCheckboxes={setSelectedCheckboxes}
              setChannelData={setChannelData}
              // setFetchCall={setFetchCall}
            /> */}
        <Typography
          onClick={() => setDropDownClick(false)}
          sx={{ color: "themeColor", fontSize: "0.85rem" }}
        >
          KPI:{" "}
        </Typography>
       
        <Box sx={{ position: "relative", display:'flex', alignItems:'center', justifyContent:'center' }}>
          {/* <Cascader
            kpiFilterList={kpiFilterList}
            setDefaultKpiFilter2={setDefaultKpiFilter2}
            setDefauLabelKpiFilter2={setDefauLabelKpiFilter2}
            defaulLabelKpiFilter2={defaulLabelKpiFilter2}
            dropDownClick={dropDownClick}
            setDropDownClick={setDropDownClick}
            setFetchCall={setFetchCall}
            setDisabledFlag={setDisabledFlag}
            setToggle={setToggle}
          /> */}
          <CascaderFocusWrapper componentId="kpi" isFocused={isFocused} setIsFocused={setIsFocused} >
             
              <SingleCascading
                data={cascaderKPIData}
                key={CascasderDefaultValue}
                setGetBrand={setGetBrand}
                match={CascasderDefaultValue}
                setIsFocused={setIsFocused}
              />
              </CascaderFocusWrapper>
        </Box>
 
       
       
      </Stack>
   
      <Stack position={"absolute"} right={20} direction={"row"} gap={3}>
   
        <Typography
          onClick={() => {
            setActiveTabName("baseshift");
            setToggle(false);
          }}
          sx={{
            fontSize: "0.85rem",
            cursor: "pointer",
            color: activeTabName === "baseshift" ? "#d6ff41" : "white",
            borderBottom:
              activeTabName === "baseshift" ? "1px solid #d6ff41" : "",
          }}
        >
          Base Shift
        </Typography>
        <Typography
          onClick={() => {
            setActiveTabName("brandcarryover");
            setToggle(false);
          }}
          sx={{
            fontSize: "0.85rem",
            cursor: "pointer",
            color: activeTabName === "brandcarryover" ? "#d6ff41" : "white",
            borderBottom:
              activeTabName === "brandcarryover" ? "1px solid #d6ff41" : "",
          }}
        >
          Brand Carryover
        </Typography>
      </Stack>
    </Stack>
  );
};
 
export default Header;
 
 