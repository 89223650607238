import { useEffect, useState } from "react";
import RowData from "./RowData";
import { Box, Stack, Typography, Divider, Button } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

import AntSwitch from "./Switch";

const ChannelComponent = ({
  type,
  allChannels,
  traverse,
  channel,
  level,
  checked,
  DATA,
  flattened,
  setKeyFun,
  myObj,
  setTempInputKeyValueFun,
  TOTAL_BUDGET,
  userBarFillObject,
  setIsExecuted,
  setChannelType,
  traverseInputChange,
  setGetInputChangeValueEditSpend,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasSubchannels = channel.subchannel && channel.subchannel.length > 0;

  const toggleSubchannels = () => {
    setIsOpen(!isOpen);
  };

  const getDiff = () => {
    let diff = (channel.spends - channel.spend_prev).toFixed(1);
    const color = () => {
      if (diff > 0) {
        return "rgb(3, 255, 3)";
      } else {
        return "red";
      }
    };

    const percentage = () => {
      const value = (diff / channel.spend_prev) * 100;
      return value.toFixed(1);
    };

    // console.log(channel.changed, channel.editSpends);
    return (
      <>
        <div style={{ color: color() }}>
          {percentage()}
          {/* {"%"} */}
          {"%"}&nbsp;({diff})
        </div>
        {/* <div style={{ color: color() }}>({diff})</div> */}
      </>
    );
  };

  const getBackgroundColor = (level) => {
    const colors = [
      "rgb(38, 50, 56)",
      "rgb(55, 71, 79)",
      "gray",
      "yellow",
      "gray",
    ]; // Array of colors for each level
    return colors[level % colors.length];
  };

  return (
    <div>
      <div>
        <Stack direction={"row"} spacing={1}>
          {/* <Box
            onClick={toggleSubchannels}
            width={10}
            sx={{ cursor: "pointer", padding: "1rem 0" }}
          > */}
          {/* {hasSubchannels && (isOpen ? "-" : "+")} */}
          {/* </Box> */}
          {/* {channel.name} */}
          <RowData
            key={channel.mykey}
            onClick={toggleSubchannels}
            icon={hasSubchannels && (isOpen ? "-" : "+")}
            level={level}
            type={type}
            allChannels={allChannels}
            traverse={traverse}
            name={channel.name}
            prev_spend={channel.spend_prev}
            annual={channel.spends}
            fillflag={channel.fillflag}
            diff={getDiff()}
            checked={checked}
            min_value={channel.min_val}
            max_value={channel.max_val}
            mykey={channel.mykey}
            color={channel.color}
            refresh={channel.refresh}
            edit={channel.editSpends}
            DATA={DATA}
            flattened={flattened}
            setKeyFun={setKeyFun}
            myObj={myObj}
            setTempInputKeyValueFun={setTempInputKeyValueFun}
            TOTAL_BUDGET={TOTAL_BUDGET}
            userBarFillObject={userBarFillObject}
            setIsExecuted={setIsExecuted}
            setChannelType={setChannelType}
            traverseInputChange={traverseInputChange}
            inputChange={channel.inputChange}
            setGetInputChangeValueEditSpend={setGetInputChangeValueEditSpend}
          />
        </Stack>
      </div>
      {isOpen && hasSubchannels && (
        <div
          style={{
            backgroundColor: getBackgroundColor(level),
            padding: "0.5rem 0",
          }}
        >
          {channel.subchannel.map((subChannel) => (
            <ChannelComponent
              type={type}
              allChannels={allChannels}
              traverse={traverse}
              key={subChannel.mykey}
              channel={subChannel}
              level={level + 1}
              checked={checked}
              DATA={DATA}
              flattened={flattened}
              setKeyFun={setKeyFun}
              myObj={myObj}
              setTempInputKeyValueFun={setTempInputKeyValueFun}
              TOTAL_BUDGET={TOTAL_BUDGET}
              userBarFillObject={userBarFillObject}
              setIsExecuted={setIsExecuted}
              setChannelType={setChannelType}
              traverseInputChange={traverseInputChange}
              setGetInputChangeValueEditSpend={setGetInputChangeValueEditSpend}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Table = ({
  allChannels,
  traverse,
  allowEditFun,
  setCheckedFun,
  DATA,
  flattened,
  setKeyFun,
  myObj,
  setTempInputKeyValueFun,
  TOTAL_BUDGET,
  userBarFillObject,
  reOptimizeHandler,
  setIsExecuted,
  setChannelType,
  traverseInputChange,
  setGetInputChangeValueEditSpend,
  setSaveResult,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    allowEditFun(checked);
    setCheckedFun(checked);
  }, [checked, allowEditFun, setCheckedFun]);

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
  };

  const channelNamesAndValues = Object.entries(allChannels?.channels);

  // channelNamesAndValues.map((item) => {
  //   console.log(item[1]);
  // });

  // for re-optimize button active
  const disabledHandler = () => {
    const arr = Object.values(flattened);
    // console.log("arr: ", arr.length);
    for (let i = 0; i < arr.length; i++) {
      // console.log(i, "flag");
      if (arr[i][3] === true) {
        return false;
      }
    }
    return true;
  };


  const saveResultHandler = () => {
    setSaveResult(true);
  }
  


  return (
    <Box
      sx={{
        padding: "1rem 1rem 1rem 0",
        height: "100%",
        backgroundColor: "bgColor",
        // borderRadius: "0 1rem 1rem 0",
        borderRadius: "1rem",
        width: "100%",
        // border: "1px solid red",
      }}
    >
      <Box
        flexGrow={1}
        sx={{
          // border: "1px solid yellow",
          backgroundColor: "#1C2427",
          borderRadius: "1rem",
          overflowY: "scroll",
          padding: "0 1rem 0 0",
          height: "100%",

          "&::-webkit-scrollbar": {
            width: "4px",
          },

          "&::-webkit-scrollbar-track": {
            background: "#1C2427",
          },

          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "gray",
            borderRadius: "10px",
          },
        }}
      >
        <Stack
          sx={{
            height: "100%",
            backgroundColor: "bgColor",
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{
              backgroundColor: "#1C2427",
              height: "5rem",
              color: "white",
              width: "100%",
              fontWeight: 700,
              position: "sticky",
              top: 0,
              zIndex: 1,
              padding: "1rem 0 0 0",
              // border: "1px solid tomato",
            }}
          >
            <Box sx={{ width: "40px" }}></Box>
            <Typography variant="h6" sx={{ width: "2%" }}></Typography>
            <Typography variant="h6" sx={{ width: "20%", fontWeight: "bold" }}>
              Channels
            </Typography>
            <Typography
              variant="h6"
              sx={{ width: "10%", fontWeight: "bold", whiteSpace: "nowrap" }}
            >
              Prev Year
            </Typography>
            <Typography variant="h6" sx={{ width: "19%" }}></Typography>
            <Typography variant="h6" sx={{ width: "13%", fontWeight: "bold" }}>
              Annual
            </Typography>
            <Typography variant="h6" sx={{ width: "19%", fontWeight: "bold" }}>
              Edit Spends
            </Typography>
            <Typography
              variant="h6"
              sx={{
                width: "18%",
                display: "flex",
                justifyContent: "flex-end",
                // border: '1px solid red'
              }}
            >
              <FormControlLabel
                control={
                  <AntSwitch
                    checked={checked}
                    inputProps={{ "aria-label": "ant design" }}
                    onChange={handleSwitchChange}
                  />
                }
                label=""
                sx={{ marginRight: -1 }}
              ></FormControlLabel>
            </Typography>
          </Stack>

          {/* <Box>
          {allChannels.channels &&
            allChannels.channels.traditional.map((channel) => (
              <ChannelComponent
                type={"traditional"}
                traverse={traverse}
                allChannels={allChannels}
                key={channel.mykey}
                channel={channel}
                level={0}
                checked={checked}
              />
            ))}
        </Box> */}

          {/* <Box>
          {allChannels.channels &&
            allChannels.channels.digital.map((channel) => (
              <ChannelComponent
                type={"digital"}
                traverse={traverse}
                allChannels={allChannels}
                key={channel.mykey}
                channel={channel}
                level={0}
                checked={checked}
              />
            ))}
        </Box> */}

          {/* <Box>
          {allChannels.channels &&
            allChannels.channels.social.map((channel) => (
              <ChannelComponent
                type={"digital"}
                traverse={traverse}
                allChannels={allChannels}
                key={channel.mykey}
                channel={channel}
                level={0}
                checked={checked}
              />
            ))}
        </Box> */}

          {channelNamesAndValues.map((item, index) => {
            return (
              <Stack direction={"row"} key={index}>
                <Divider
                  orientation="vertical"
                  sx={{
                    width: "40px",
                    // border: "1px solid blue",
                    "&.MuiDivider-root::after": {
                      backgroundColor: item[1][0].color,
                    },
                    "&.MuiDivider-root::before": {
                      backgroundColor: item[1][0].color,
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: item[1][0].color,
                      textTransform: "capitalize",
                      writingMode: "vertical-lr",
                      transform: "rotate(-180deg)",
                    }}
                  >
                    {item[0]}
                  </Typography>
                </Divider>

                <Box key={index} flexGrow={1}>
                  {item[1].map((channel) => {
                    return (
                      <ChannelComponent
                        type={item[0]}
                        traverse={traverse}
                        allChannels={allChannels}
                        channel={channel}
                        key={channel.mykey}
                        level={0}
                        checked={checked}
                        DATA={DATA}
                        flattened={flattened}
                        setKeyFun={setKeyFun}
                        myObj={myObj}
                        setTempInputKeyValueFun={setTempInputKeyValueFun}
                        TOTAL_BUDGET={TOTAL_BUDGET}
                        userBarFillObject={userBarFillObject}
                        setIsExecuted={setIsExecuted}
                        setChannelType={setChannelType}
                        traverseInputChange={traverseInputChange}
                        setGetInputChangeValueEditSpend={
                          setGetInputChangeValueEditSpend
                        }
                      />
                    );
                  })}
                </Box>
              </Stack>
            );
          })}

          {/* last bar */}
          <Stack
            sx={{
              position: "sticky",
              zIndex: 1,
              bottom: 0,
              // border: "1px solid red",
              backgroundColor: "bgColor",
              padding: "1rem 0 0.25rem 0",
            }}
          >
            <Stack direction={"row"} spacing={8} justifyContent={"flex-end"}>
              <Button
                disabled={disabledHandler()}
                onClick={reOptimizeHandler}
                sx={{
                  backgroundColor: "#1c2427",
                  border: disabledHandler() ? "1px solid gray" : "1px solid #d6ff41",
                  color: disabledHandler() ? "#FF0000" : "#d6ff41",
                  // width: "8rem",
                  "&:hover": {
                    backgroundColor: "#d6ff41",
                    color: "black",
                  },
                }}
              >
                Re-Optimise
              </Button>
              <Button
                onClick={saveResultHandler}
                sx={{
                  backgroundColor: "#1c2427",
                  border: "1px solid #d6ff41",
                  color: "#d6ff41",
                  // width: "8rem",
                  "&:hover": {
                    backgroundColor: "#d6ff41",
                    color: "black",
                  },
                }}
              >
                Save Result
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Table;
