import React from 'react';
import PropTypes from 'prop-types';

const BaselineChartShimmer = ({ height }) => {
  return (
    <div 
      className="baseline-chart-shimmer" 
      style={{ 
        width: '100%', 
        height: height || '100%', 
        backgroundColor: '#1c2427', 
        overflow: 'hidden' 
      }}
    >
      <div 
        className="chart-area" 
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          height: 'calc(100% - 50px)',
          padding: '16px',
          gap: '8px'
        }}
      >
        {[1, 2, 3, 4, 5, 6, 7, 8,9,10].map((bar,index) => (
          <div 
            key={bar} 
            className="shimmer-bar"
            style={{
              flex: 1,
              backgroundColor: '#2c3e50',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              height: `${20 + index * 5}%`, // Example: increasing height gradually
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <div 
              className="shimmer-animation"
              style={{
                position: 'absolute',
                top: 0,
                left: '-100%',
                width: '100%',
                height: '100%',
                background: 'linear-gradient(90deg, rgba(44, 62, 80, 0.8) 0%, rgba(52, 73, 94, 0.5) 50%, rgba(44, 62, 80, 0.8) 100%)',
                animation: 'shimmerAnimation 1.5s infinite'
              }}
            />
          </div>
        ))}
      </div>
      
      <div 
        className="x-axis-shimmer"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 16px',
          height: '50px',
          alignItems: 'center',
          gap: '8px'
        }}
      >
        {[1, 2, 3, 4, 5, 6, 7, 8,9,10].map((label) => (
          <div 
            key={label} 
            className="x-axis-label"
            style={{
              flex: 1,
              height: '16px',
              backgroundColor: '#2c3e50',
              borderRadius: '4px',
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <div 
              className="shimmer-animation"
              style={{
                position: 'absolute',
                top: 0,
                left: '-100%',
                width: '100%',
                height: '100%',
                background: 'linear-gradient(90deg, rgba(44, 62, 80, 0.8) 0%, rgba(52, 73, 94, 0.5) 50%, rgba(44, 62, 80, 0.8) 100%)',
                animation: 'shimmerAnimation 1.5s infinite'
              }}
            />
          </div>
        ))}
      </div>

      {/* Global Keyframes */}
      <style jsx global>{`
        @keyframes shimmerAnimation {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(200%);
          }
        }
      `}</style>
    </div>
  );
};

BaselineChartShimmer.propTypes = {
  height: PropTypes.string
};

export default BaselineChartShimmer;