// import { Stack } from "@mui/material";
import MatchStackChart from "./MatchStackChart";

import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Stack, Box , useTheme, useMediaQuery} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
const StackContainer = (props) => {

  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  const [chartKey, setChartKey] = useState(0); // State to force chart reload

  const [isClicked,setisClicked] = useState(false);
  function matchStack2Click(){
    setisClicked(!isClicked)
  }


  useEffect(() => {
    // This effect will trigger whenever props.stackCategories or props.stackSeries changes
    // It will force reload the chart by updating the chartKey state
    setChartKey((prevKey) => prevKey + 1);
  }, [props.matchStackData]);

  const [divHeight, setDivHeight] = useState("");
  // console.log("divHeight stack chart: ", divHeight);


  const divRef = useRef(null);

  useLayoutEffect(() => {
    const updateDivHeight = () => {
      if (divRef.current) {
        const newHeight = divRef.current.clientHeight;
        setDivHeight(newHeight);
        // console.log("new height: ", newHeight);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);


  return (
    <Stack gap={1} ref={divRef} style={{height: "100%",
    // border:"2px solid green",
    paddingTop: (isSmall||isMedium)?"2%":""

    
    }}>
      <div className="row align-items-center sorting-btn-ui">
        <div className="col-sm-3" style={{display:"flex"}}>
          <ul>
            <li onClick={props.DrillUpAllMatch} title="Drill Up">
              <i className="fa fa-arrow-up" aria-hidden="true"></i>
            </li>
            <li onClick={props.DrillDownAllMatch} title="Drill Down">
              <i className="fa fa-arrow-down" aria-hidden="true"></i>
            </li>
            <li onClick={props.DrillAllMatch} title="Expand All">
              <i className="fa fa-sitemap" aria-hidden="true"></i>
            </li>
            <li onClick={props.ResetAllMatch} title="Reset">
              <i className="fa" aria-hidden="true">
                &#8635;
              </i>
            </li>
          </ul>
         {
          (isSmall||isMedium)&& <div onClick={matchStack2Click} style={{color:"white"}}>
           {
            (isClicked)?<ZoomInIcon />:<ZoomOutIcon />
           }
            </div>
         }
        </div>
      </div>
      <div
        style={{
          //   backgroundColor:'red',
          // border:"1px solid red",
          overflowx:
            isSmall || isMedium ? "auto" : "" /* Enable horizontal scrollbar */,
          overflowy:
            isSmall || isMedium ? "hidden" : "" /* Hide vertical scrollbar */,
          whitespace:
            isSmall || isMedium ? "nowrap" : "" /* Prevent line breaks */,
          overflow: isSmall || isMedium ? "scroll" : "",
        }}
      >
        <MatchStackChart
          // key={chartKey}
          matchStackData={props.matchStackData}
          DrillDownMatch={props.DrillDownMatch}
          divHeight={divHeight}
          isClicked={isClicked}
        />
      </div>
    </Stack>
  );
};

export default StackContainer;
