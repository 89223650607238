import React, { useEffect, useState } from "react";

import Menubar from "../../Components/Menubar";
import axios from "../../axios";
// import baseURL from "./BaseURL";
import baseURL from "../../BaseURL2";

import Loader from "./Loader";

import Form from "./Form";
import Channels from "./Channels";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

import { CssBaseline, Typography } from "@mui/material";
import { Stack, Box, useTheme, useMediaQuery } from "@mui/material";

let theme = createTheme({
  palette: {
    background: {
      default: "black", // Set the default background color
    },
    themeColor: "#d6ff41",
    bgColor: "#1c2427",
    inputBorderColor: "#3E5056",
  },

  typography: {
    fontFamily: ["Inter"].join(","),
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

theme = responsiveFontSizes(theme);

const Main = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fixedBudget, setFixedBudget] = useState(true); // flag for radio
  const [fixedSpots, setFixedSpots] = useState(false); // flag for radio

  const [fixedBudgetValue, setFixedBudgetValue] = useState("");
  // console.log("fixedBudgetValue: ", fixedBudgetValue);

  const [min_match, setMin_match] = useState("");
  const [max_tv, setMax_tv] = useState("");
  const [max_ctv, setMax_ctv] = useState("");
  const [max_tvmatch, setMax_tvmatch] = useState("");
  const [max_ctvmatch, setMax_ctvmatch] = useState("");

  const [isSubmit, setIsSubmit] = useState(false);
  const [fetchCall, setFetchCall] = useState(true);
  const [editMode, setEditMode] = useState(false);
  // console.log("editMode: ", editMode);
  // console.log("isSubmit: ", isSubmit);

  const fromClearHandler = () => {
    setFixedBudgetValue("");
    setMin_match("");
    setMax_tv("");
    setMax_ctv("");
    setMax_tvmatch("");
    setMax_ctvmatch("");
    setIsSubmit(false);
    setFetchCall(false);
  };

  const [allKPI, setAllKPI] = useState([]);
  const [defaultKPI, setDefaultKPI] = useState("");
  const [getBrand, setGetBrand]= useState("");
  const [getView,setGetView]= useState("");
  // console.log("defaultKPI: ", defaultKPI);

  // get all kpi
  useEffect(() => {
    const fetchData = async () => {
      // setIsLoading(true);
      try {
        const response = await axios.post(`optimizer_kpi/`, {});
        const data = await response.data; // Use await to get the data
        // console.log("optimizer_kpi: ", data);
        setAllKPI(data.data);
        setDefaultKPI(data.defaultid);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // #################################
  const [allViews, setAllViews] = useState([]);
  const [defaultView, setDefaultView] = useState("");
  // console.log("defaultView: ", defaultView);

  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  // optimizer view
  useEffect(() => {
    const fetchData = async () => {
      // setIsLoading(true);
      try {
        const response = await axios.post(
          `${baseURL}/optimizerview_options/`,
          {}
        );
        const data = await response.data; // Use await to get the data
        // console.log("optimizer_kpi: ", data);
        setAllViews(data.data);
        setDefaultView(data.defaultid);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // ###########################

  // #################################
  const [defaultStartDate, setDefaultStartDate] = useState("");
  const [defaultEndDate, setDefaultEndDate] = useState("");
  // console.log("defaultStartDate: ", defaultStartDate);
  // console.log("defaultEndDate: ", defaultEndDate);

  // optimizer timeperiod
  useEffect(() => {
    const fetchData = async () => {
      // setIsLoading(true);
      if (defaultKPI) {
        try {
          const response = await axios.post(
            `${baseURL}/optimizer_timeperiod/`,
            {  kpi: getBrand?.id?.split("-")?.pop() || defaultKPI?.split("-")?.pop(), }
          );
          const data = await response.data; // Use await to get the data
          // console.log("timeperiods: ", data.data.timeperiods);
          setDefaultEndDate(data.data.timeperiods[0].enddate);
          setDefaultStartDate(data.data.timeperiods[0].startdate);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [defaultKPI,getBrand]);

  //optmizer json

  const [DATA, setDATA] = useState([]);
  // console.log("DATA: ", DATA);
  const [headerTabNames, setHeaderTabNames] = useState([]);
  // console.log("headerTabNames: ", headerTabNames);
  // for dividing width in cell
  const [Length, setLength] = useState("");
  // console.log("Length: ", Length);
  const [rePayload, setRePayload] = useState({});
  // console.log("rePayload: ", rePayload);

  const [refresh, setRefresh] = useState(false);
  const [changed, setChanged] = useState([]);
  // console.log("changed: ", changed);

  //click sort----------------

  const [clickSort, setClickSort] = useState("");
  const [sortAlpha, setSortAlpha] = useState("");
  const [shouldSort, setShouldSort] = useState(false);
  console.log("clickSort", clickSort);
  // console.log("alphaSort", sortAlpha);
  // console.log("should sort", shouldSort)
  // Add a state variable to track whether sorting should be performed

  // useEffect(() => {
  //   if (sortAlpha !== "") {
  //     const sortedData = DATA.sort((a, b) => {
  //       if (a.name < b.name) return -1;
  //       if (a.name > b.name) return 1;
  //       return 0;
  //     });

  //     // Log the sorted data
  //     const sortedAlpha = JSON.stringify(sortedData, null, 4);
  //     setDATA(sortedAlpha);
  //   }
  // }, [sortAlpha]);

  // Update the state when clickSort changes
  useEffect(() => {
    if (clickSort !== "") {
      setShouldSort(true); // Set shouldSort to true when clickSort changes
    }
  }, [clickSort]);

  // Perform sorting when shouldSort is true

  useEffect(() => {
    console.log("clicckkkk", clickSort);
    if (clickSort.name === "name") {

      if(clickSort.action==="aTOz"){
        const sortedData = DATA.slice().sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
        setDATA(sortedData);
        setShouldSort(false); // Reset shouldSort after sorting by name
      }
      else{
        const sortedData = DATA.slice().sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return 1; // Changed from -1 to 1
          if (nameA > nameB) return -1; // Changed from 1 to -1
          return 0;
      });
      setDATA(sortedData);
      setShouldSort(false);
      }

      
    } else {
      if (shouldSort) {
        const keyName = clickSort.name;

        // for decresing order 
        if (clickSort.action === "decreasing") {
          const sortedData = DATA.map((team) => {
            const incrementalData = team.data.find((item) => keyName in item);
            return {
              ...team,
              [keyName]: incrementalData ? incrementalData[keyName].value : 0,
            };
          }).sort((a, b) => b[keyName] - a[keyName]);
          setDATA(sortedData);
          setShouldSort(false);
        } else {

          // for increasing order

          const sortedData = DATA.map((team) => {
            const incrementalData = team.data.find((item) => keyName in item);
            return {
              ...team,
              [keyName]: incrementalData ? incrementalData[keyName].value : 0,
            };
          }).sort((a, b) => a[keyName] - b[keyName]);
          setDATA(sortedData);
          setShouldSort(false);
        }
      }
    }
  }, [clickSort, shouldSort]);

  //toggle functionAlity.........

  const [toggleStates, setToggleStates] = useState(
    Array.from({ length: headerTabNames.length }, () => false)
  );

  // Function to handle toggle state change for a specific index
  const handleToggleChange = (index) => {
    setToggleStates((prevStates) => {
      const newStates = [...prevStates]; // Create a copy of the array
      newStates[index] = !newStates[index]; // Toggle the state at the specified index
      return newStates; // Return updated array
    });
  };

  console.log("toggleStates", toggleStates);

  useEffect(() => {
    const fetchData = async () => {
      if (isSubmit && fetchCall) {
        setIsLoading(true);
        try {
          const response = await axios.post(`optimizer_json/`, {
           kpi: getBrand?.id?.split("-")?.pop() || defaultKPI?.split("-")?.pop(),
            view: getView?.id?.split("-")?.pop() || defaultView?.split("-")?.pop(),
            startdate: defaultStartDate,
            enddate: defaultEndDate,
            opt_type: fixedBudget ? "Fixed Budget" : "Fixed Spot",
            budget: fixedBudgetValue,
            min_match: min_match,
            max_tv: max_tv,
            max_ctv: max_ctv,
            max_tvmatch: max_tvmatch,
            max_ctvmatch: max_ctvmatch,
            changed: rePayload,
          });
          const data = await response.data; // Use await to get the data
          console.log("jsonss: ", data.data);
          setDATA(data.data.data);
          setHeaderTabNames(data.data.show_categories);
          setLength(data.data.show_categories.length);
          setIsLoading(false);
          setFetchCall(false);
          setEditMode(false);
          setRefresh(false);
          setRePayload({});
          setChanged([]);
          setFixedBudgetValue("");
          setMax_ctv("");
          setMax_tv("");
          setMin_match("");
          setMax_tvmatch("");
          setMax_ctvmatch("");
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [
    defaultKPI,
    getBrand,
    defaultView,
    defaultStartDate,
    defaultEndDate,
    min_match,
    max_tv,
    max_ctv,
    max_tvmatch,
    max_ctvmatch,
    fixedBudget,
    isSubmit,
    fetchCall,
    fixedBudgetValue,
    // rePayload,
  ]);

  return (
    <>
      {isLoading ? <Loader /> : null}

      <Menubar />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            position: "relative",
            top: 20,
            //  border:"2px solid red",
            //  width:"1000px"
          }}
        >
          <Stack
            direction={isMedium || isSmall ? "column" : "row"}
            justifyContent={"space-between"}
            sx={{ height: "700px" }}
            // border="2px solid green"
            // border="2px solid green"
          >
            {/* left container */}
            <Form
              allKPI={allKPI}
              defaultKPI={defaultKPI}
              setGetBrand={setGetBrand}
              setGetView={setGetView}
              setDefaultKPI={setDefaultKPI}
              //
              allViews={allViews}
              defaultView={defaultView}
              setDefaultView={setDefaultView}
              //
              defaultStartDate={defaultStartDate}
              setDefaultStartDate={setDefaultStartDate}
              defaultEndDate={defaultEndDate}
              setDefaultEndDate={setDefaultEndDate}
              //
              fixedBudget={fixedBudget}
              fixedSpots={fixedSpots}
              setFixedBudget={setFixedBudget}
              setFixedSpots={setFixedSpots}
              //
              fixedBudgetValue={fixedBudgetValue}
              setFixedBudgetValue={setFixedBudgetValue}
              min_match={min_match}
              setMin_match={setMin_match}
              max_tv={max_tv}
              setMax_tv={setMax_tv}
              max_ctv={max_ctv}
              setMax_ctv={setMax_ctv}
              max_tvmatch={max_tvmatch}
              setMax_tvmatch={setMax_tvmatch}
              max_ctvmatch={max_ctvmatch}
              setMax_ctvmatch={setMax_ctvmatch}
              setIsSubmit={setIsSubmit}
              setFetchCall={setFetchCall}
              fromClearHandler={fromClearHandler}
              setDATA={setDATA}
            />
            {/* ledt container */}

            {/* ############################################## */}
            {/* right container */}
            {isSubmit ? (
              <Channels
                DATA={DATA}
                headerTabNames={headerTabNames}
                Length={Length}
                editMode={editMode}
                setEditMode={setEditMode}
                setRePayload={setRePayload}
                rePayload={rePayload}
                fetchCall={setFetchCall}
                refresh={refresh}
                setRefresh={setRefresh}
                defaultView={defaultView}
                changed={changed}
                setChanged={setChanged}
                setClickSort={setClickSort}
                setSortAlpha={setSortAlpha}
                toggleStates={toggleStates}
                handleToggleChange={handleToggleChange}
                clickSort={clickSort}
              />
            ) : null}
            {/* right container */}
          </Stack>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Main;
