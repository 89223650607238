import React, { useEffect, useState } from "react";

import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import baseURL from "../../BaseURL2";
import {
  Stack,
  Box,
  useTheme,
  useMediaQuery,
  CssBaseline,
  Typography,
} from "@mui/material";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

// import { CssBaseline, Typography } from "@mui/material";
// import { Stack, Box } from "@mui/material";

import Loader from "./Loader";
import Filters from "./Filters";
import ChartContainer from "./CampaignContainer";

let theme = createTheme({
  palette: {
    background: {
      default: "black", // Set the default background color
    },
    themeColor: "#d6ff41",
    bgColor: "#1c2427",
    inputBorderColor: "#3E5056",
  },

  typography: {
    fontFamily: ["Inter"].join(","),
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },

  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "26px", // Set default size for all icons
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

const Main = () => {
  const [isLoading, setIsLoading] = useState(false);

  // chart 1
  const [defaultStartDate, setDefaultStartDate] = useState("");
  const [defaultEndDate, setDefaultEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // for filterall api
  const [payload, setPayload] = useState({});
  // console.log("payload: ", payload);

  const [defaultTeam, setDefaultTeam] = useState("");
  const [allTeams, setAllTeams] = useState([]);
  const [teamPayload, setTeampayload] = useState("");

  const [defaultCity, setDefaultCity] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [cityPayload, setCityPayload] = useState("");

  const [defaultDaypart, setDefaultDaypart] = useState("");
  const [allDaypart, setAllDaypart] = useState([]);
  const [dayPartpayload, setDaypartpayload] = useState("");

  const [defaultMatchId, setDefultMatchId] = useState("");
  const [allMatchId, setAllMatchId] = useState([]);
  const [matchIdPayload, setMatchIdPayload] = useState("");

  const [defaultWeekFlag, setDefaultWeekFlag] = useState("");
  const [allWeekFlag, setAllWeekFlag] = useState([]);
  const [weekFlagpayload, setWeekflagpayload] = useState("");

  const [isSubmit, setIsSubmit] = useState(true);
  const [disabledFlag, setDisabledFlag] = useState(true);

  const [defaultH0Value, setDefaultH0Value] = useState("");
  // console.log("defaultH0Value: ", defaultH0Value);
  const [defaultH1Value, setDefaultH1Value] = useState("");
  // console.log("defaultH1Value: ", defaultH1Value);

  const [count, setCount] = useState(10);

  const [yearFilter,setYearFilter]=useState();

  // post timeperiod
  useEffect(() => {
    // console.log("time period called")
    const fetchData = async () => {
      try {
        const response = await axios.post(`timeperiod/`, {
          tabid: "campaign",
          yearFilter:yearFilter
        });
        const data = await response.data; // Use await to get the data
        // console.log(data);
        setDefaultStartDate(data.data.timeperiods[0].defaultstartdate);
        setDefaultEndDate(data.data.timeperiods[0].defaultenddate);
        setStartDate(data.data.timeperiods[0].startdate);
        setEndDate(data.data.timeperiods[0].enddate);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [yearFilter]);

  // payload for filterall api
  useEffect(() => {
    setPayload({
      ...teamPayload,
      ...cityPayload,
      ...dayPartpayload,
      ...matchIdPayload,
      ...weekFlagpayload,
    });
  }, [
    teamPayload,
    cityPayload,
    dayPartpayload,
    matchIdPayload,
    weekFlagpayload,
  ]);

  // post all filters........................................
  useEffect(() => {
    const fetchData = async () => {
      if (defaultStartDate && defaultEndDate) {
        try {
          const response = await axios.post(`filterall/`, {
            start_date: defaultStartDate,
            end_date: defaultEndDate,
            matchfilters: payload,
          });
          const data = await response.data; // Use await to get the data
          // console.log(data);
          setAllTeams(data.teamdict);
          setDefaultTeam(data.teamdict[0].value);

          setDefaultCity(data.city[0]);
          setAllCities(data.city);

          setDefaultDaypart(data.daypart[0]);
          setAllDaypart(data.daypart);

          setAllMatchId(data.matchdict);
          setDefultMatchId(data.matchdict[0].value);

          setDefaultWeekFlag(data.wd_flag[0]);
          setAllWeekFlag(data.wd_flag);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [defaultStartDate, defaultEndDate, payload]);

  const [defaultKpiFilter, setDefaultKpiFilter] = useState("");
  const [allKpi, setAllKpi] = useState([]);
  const [chartDataCamp, setChartDataCamp] = useState({});
  // console.log("chartDataCamp: ", chartDataCamp);
  const [fetchCallCamp, setFetchCallCamp] = useState(true);
  const [isAllCamp, setIsAllCamp] = useState(0);
  const [resetCamp, setResetCamp] = useState(0);
  const [campData, setCampData] = useState([]);
  const [BreadCrumbsCamp, setBreadCrumbsCamp] = useState([]);
  // console.log("BreadCrumbsCamp: ", BreadCrumbsCamp);

  const [channelFilters, setChannelFilters] = useState([]);

  // console.log("channelFilter: ", channelFilters);
  const [cascaderData1, setCascaderData1] = useState([]);
  const [cascaderData2, setCascaderData2] = useState([]);
  const [getBrand1, setGetBrand1] = useState("");
  const [getBrand2, setGetBrand2] = useState("");
  const [CascasderDefaultValue, setCascasderDefaultValue] = useState("");

  // get kpi filters
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`kpi_filter/`, {
          tabid: "campaign",
        });
        const data = await response.data; // Use await to get the data
        // console.log(data);
        setCascaderData1(data.data);
        setCascasderDefaultValue(data.defaultid);
        setDefaultKpiFilter(data.kpi.default_value.value);
        setAllKpi(data.kpi.all_value);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // prepare chartdata
  function convertToChartData(originalData) {
    const chartdata = {};

    originalData.forEach((section) => {
      const sectionData = {
        selection: [],
        // Filter: section.values.map(item => item.value)
        Filter: [],
      };
      chartdata[section.name] = sectionData;
    });

    return chartdata;
  }

  // add filters in chartdata
  function addToFilter(jsonObj, obj) {
    // Extract the key and value from the passed object
    let key = Object.keys(obj)[0]; // Assuming only one key is passed
    let value = obj[key];

    // Check if the key exists in the JSON object
    if (jsonObj.hasOwnProperty(key)) {
      // Replace the previous value with the new one
      jsonObj[key].Filter = [value];
    } else {
      // If the key does not exist, create it with an empty "selection" array and push the value to its "Filter" array
      jsonObj[key] = {
        selection: [],
        Filter: [value],
      };
    }
    // Return the updated JSON object
    return jsonObj;
  }
  // get channel filter  remove this api
  const [defaultView, setDefaultView] = useState("Channel First");
  // console.log("defaultView: ", defaultView);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`newchannelfilter/`, {
          view: defaultView,
        });
        const data = await response.data; // Use await to get the data
        // console.log("channel filters: ", data);
        setChartDataCamp(convertToChartData(data.data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [defaultView]);

  // get new channel filter
  useEffect(() => {
    const fetchData = async () => {
      if (JSON.stringify(chartDataCamp) !== "{}" && count === 10) {
        try {
          const response = await axios.post(`newchannelfilter/`, {});
          const data = await response.data; // Use await to get the data
          // console.log("channel filters: ", data.data);
          // setChartDataCamp(convertToChartData(data.data));
          setCount(11);
          setChannelFilters(data.data);
          setDefaultH0Value(data.data[0].values[0].value);
          setDefaultH1Value(data.data[1].values[0].value);
          setChartDataCamp(
            addToFilter(chartDataCamp, { h0: data.data[0].values[0].value })
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [chartDataCamp, count]);

  useEffect(()=>{
    console.log("getBrand1 useEffect ",getBrand1);
    setFetchCallCamp(true);
    setIsSubmit(true);
  },[getBrand1])

  useEffect(()=>{
    console.log("getBrand1 useEffect ",getBrand1);
    setFetchCallCamp2(true);
    setIsSubmit2(true);
  },[getBrand2])

  // for campaign chart
  useEffect(() => {
    console.log("fetchCallCamp ",fetchCallCamp,"  ",getBrand1);
    const fetchData = async () => {
      if (
        JSON.stringify(chartDataCamp) !== "{}" &&
        fetchCallCamp &&
        isSubmit &&
        CascasderDefaultValue &&
        defaultMatchId &&
        defaultStartDate &&
        defaultEndDate &&
        defaultCity &&
        defaultDaypart &&
        defaultTeam &&
        defaultWeekFlag
      ) {
        try {
          setIsLoading(true);
          const response = await axios.post(`comparisonchart/`, {
            kpi: getBrand1?.id?.split("-")?.pop() || CascasderDefaultValue?.split("-")?.pop(),
            match_id: defaultMatchId,
            startdate: defaultStartDate,
            enddate: defaultEndDate,
            city: defaultCity,
            time: defaultDaypart,
            chartdata: chartDataCamp,
            is_all_enabled: isAllCamp,
            reset: resetCamp,
            team1: defaultTeam,
            weekend_flag: defaultWeekFlag,
          });
          const data = await response.data; // Use await to get the data
          // console.log(data.chartdata);
          setCampData(data.chartdata);
          setFetchCallCamp(false);
          setIsAllCamp(0);
          setResetCamp(0);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [
    CascasderDefaultValue,
    getBrand1,
    defaultMatchId,
    defaultStartDate,
    defaultEndDate,
    defaultCity,
    defaultDaypart,
    chartDataCamp,
    isAllCamp,
    resetCamp,
    defaultTeam,
    defaultWeekFlag,
    fetchCallCamp,
    isSubmit,
  ]);

  // for tree button camp
  const DrillAllCamp = () => {
    // let ChartdataTemp = {};
    // for (const filter in Chartdata) {
    //   ChartdataTemp[filter] = Chartdata[filter];
    //   ChartdataTemp[filter].selection = [];
    // }

    // console.log("NotAllow: ", NotAllow);

    // new code ................
    const keysArrayOfChartData = Object.keys(chartDataCamp);

    // console.log("keysArrayOfChartData Trend: ", keysArrayOfChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTemp = structuredClone(chartDataCamp);

    function createArrays(numberOfArrays) {
      const arrays = [];

      for (let i = 0; i < numberOfArrays; i++) {
        arrays.push([]);
      }

      return arrays;
    }

    // Example usage:
    const result = createArrays(keysArrayOfChartData.length);
    // console.log("numbers of arrays: ", result);

    // result.map((item) => {
    //   item.push(...NotAllow);
    //   return "";
    // });

    result.forEach((item) => {
      return item.push(...campData.nonclicklist);
    });

    // console.log("resulted array: ", result)

    // const payloadArray = result.map((item, index) => {
    //   return [item[0].split("-").slice(0, index + 1).join("-")];
    // })

    const payloadArray = result.map((item, index) => {
      const itemArray = item.map((key) => {
        return key
          .split("-")
          .slice(0, index + 1)
          .join("-");
      });

      //  return itemArray;
      return [...new Set(itemArray)];
    });

    // console.log("output arrays payload: ", payloadArray);

    keysArrayOfChartData
      .slice(0, keysArrayOfChartData.length - 1)
      .forEach((key, index) => {
        if (ChartdataTemp.hasOwnProperty(key)) {
          // console.log("keyssssssssssssss: ", key);
          ChartdataTemp[key].selection = payloadArray[index];
        }
      });

    // console.log("ChartdataTrendsTemp: ", ChartdataTrendsTemp);

    // new code ended..............

    setIsAllCamp(1);
    setChartDataCamp(ChartdataTemp);
    setFetchCallCamp(true);
  };

  // for reset button camp
  const ResetAllCamp = () => {
    let ChartdataTemp = {};
    for (const filter in chartDataCamp) {
      ChartdataTemp[filter] = chartDataCamp[filter];
      ChartdataTemp[filter].selection = [];
    }

    setResetCamp(1);
    setChartDataCamp(ChartdataTemp);
    setFetchCallCamp(true);
  };

  // for point click
  const DrillDownCamp = (category) => {
    // console.log("clicked category: ", category);

    const splitNameList = category.split("-");
    const Length = splitNameList.length;

    // console.log("Length: ", Length);

    const allKeysAlreadyInChartdata = Object.keys(chartDataCamp);
    // console.log("allKeysAlreadyInChartdatra: ", allKeysAlreadyInChartdata);

    const keyToAddSelectionFilterInChartdata =
      allKeysAlreadyInChartdata[Length - 1];

    // console.log("keyToAddSelectionFilterInChartdata: ", keyToAddSelectionFilterInChartdata);

    let cloneOfChartdata = structuredClone(chartDataCamp);

    if (cloneOfChartdata.hasOwnProperty(keyToAddSelectionFilterInChartdata)) {
      cloneOfChartdata[keyToAddSelectionFilterInChartdata].selection.push(
        category
      );
    }

    setChartDataCamp(cloneOfChartdata);
    setFetchCallCamp(true);
  };

  // for label click
  const DrillUpCamp = (category) => {
    // console.log("category: ", category.value);
    var span = document.createElement("span");
    span.innerHTML = category.value;
    const label = span.firstChild.getAttribute("title");
    // console.log("label: ", label);

    const ChartdataTemp = structuredClone(chartDataCamp);

    for (const key in ChartdataTemp) {
      // Remove any values containing "glico-pejoy" from the selection array
      ChartdataTemp[key].selection = ChartdataTemp[key].selection.filter(
        (value) => !value.includes(label)
      );
    }

    setChartDataCamp(ChartdataTemp);
    setFetchCallCamp(true);
  };

  // for down arrow
  const DrillDownAllCamp = () => {
    // new code ................
    const keysAlreadyInChartData = Object.keys(chartDataCamp);

    // console.log("keysAlreadyInChartData Trend: ", keysAlreadyInChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTemp = structuredClone(chartDataCamp);

    function createArrays(numberOfArrays) {
      const arrays = [];

      for (let i = 0; i < numberOfArrays; i++) {
        arrays.push([]);
      }

      return arrays;
    }

    // Example usage:
    const result = createArrays(keysAlreadyInChartData.length);
    // console.log("numbers of arrays: ", result);

    result.forEach((item) => {
      return item.push(...campData.nonclicklist);
    });

    const payloadArray = result.map((item, index) => {
      const itemArray = item.map((key) => {
        return key
          .split("-")
          .slice(0, index + 1)
          .join("-");
      });
      return [...new Set(itemArray)];
    });

    // console.log("output arrays payload: ", payloadArray);

    const LENGTH = BreadCrumbsCamp.length;

    const chartDataKeysToAddSelection = keysAlreadyInChartData.slice(
      0,
      keysAlreadyInChartData.length - 1
    );

    // console.log("chartDataKeysToAddSelection: ", chartDataKeysToAddSelection);

    // chartDataKeysToAddSelection.slice(0, LENGTH).forEach((key, index) => {
    //   if(ChartdataTemp.hasOwnProperty(key)) {
    //     // console.log("keyssssssssssssss: ", key);
    //     ChartdataTemp[key].selection = payloadArray[index];
    //   }
    // })

    for (
      let index = 0;
      index < LENGTH && index < chartDataKeysToAddSelection.length;
      index++
    ) {
      const key = chartDataKeysToAddSelection[index];

      if (ChartdataTemp.hasOwnProperty(key)) {
        if (chartDataCamp[key].selection.length < payloadArray[index].length) {
          ChartdataTemp[key].selection = payloadArray[index];
          break;
        } else {
          ChartdataTemp[key].selection = payloadArray[index];
        }
      }
    }

    // new code ended..............

    setChartDataCamp(ChartdataTemp);
    setFetchCallCamp(true);
  };

  //  prepare BreadCrumbs camp......................................
  useEffect(() => {
    if (JSON.stringify(chartDataCamp) !== "{}") {
      const cloneOfChartdata = structuredClone(chartDataCamp);
      // console.log("cloneOfChartdata: ", cloneOfChartdata);
      const allKeysAlreadyInChartdata = Object.keys(cloneOfChartdata);
      const listToStoreLength = [];
      for (const key in cloneOfChartdata) {
        const list = cloneOfChartdata[key]["selection"];
        // console.log("list: ", list);
        if (list.length === 0) {
          break;
        }
        for (const name of list) {
          const arr = name.split("-");
          listToStoreLength.push(arr.length);
        }
      }

      // console.log("ARR: ", ARR);

      // console.log("listToStoreLength: ", listToStoreLength);

      let maxNumber = Math.max(...listToStoreLength);

      if (listToStoreLength.length === 0) {
        maxNumber = 0;
      }

      // console.log("maxNumber: ", maxNumber);

      setBreadCrumbsCamp(allKeysAlreadyInChartdata.slice(0, maxNumber + 1));
    }
  }, [chartDataCamp]);

  const DrillUpAllCamp = () => {
    // new code ................
    const keysAlreadyInChartData = Object.keys(chartDataCamp);

    // console.log("keysAlreadyInChartData Trend: ", keysAlreadyInChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTemp = structuredClone(chartDataCamp);
    // console.log("output arrays payload: ", payloadArray);

    const LENGTH = BreadCrumbsCamp.length;

    // console.log("LENGTH: ", LENGTH);

    const chartDataKeysToRemoveSelection = keysAlreadyInChartData.slice(
      0,
      keysAlreadyInChartData.length - 1
    );

    // console.log("chartDataKeysToSelection: ", chartDataKeysToRemoveSelection);

    chartDataKeysToRemoveSelection.slice(LENGTH - 2, LENGTH).forEach((key) => {
      if (ChartdataTemp.hasOwnProperty(key)) {
        // console.log("keyssssssssssssss: ", key);
        ChartdataTemp[key].selection = [];
      }
    });

    // new code ended..............

    setChartDataCamp(ChartdataTemp);
    setFetchCallCamp(true);
  };

  // chart 1 end
  //##########################################################################
  // chart 2
  const [defaultStartDate2, setDefaultStartDate2] = useState("");
  const [defaultEndDate2, setDefaultEndDate2] = useState("");
  const [startDate2, setStartDate2] = useState("");
  const [endDate2, setEndDate2] = useState("");

  // for filterall api
  const [payload2, setPayload2] = useState({});
  // console.log("payload: ", payload2);

  const [defaultTeam2, setDefaultTeam2] = useState("");
  const [allTeams2, setAllTeams2] = useState([]);
  const [teamPayload2, setTeampayload2] = useState("");

  const [defaultCity2, setDefaultCity2] = useState([]);
  const [allCities2, setAllCities2] = useState([]);
  const [cityPayload2, setCityPayload2] = useState("");

  const [defaultDaypart2, setDefaultDaypart2] = useState("");
  const [allDaypart2, setAllDaypart2] = useState([]);
  const [dayPartpayload2, setDaypartpayload2] = useState("");

  const [defaultMatchId2, setDefultMatchId2] = useState("");
  const [allMatchId2, setAllMatchId2] = useState([]);
  const [matchIdPayload2, setMatchIdPayload2] = useState("");

  const [defaultWeekFlag2, setDefaultWeekFlag2] = useState("");
  const [allWeekFlag2, setAllWeekFlag2] = useState([]);
  const [weekFlagpayload2, setWeekflagpayload2] = useState("");

  const [isSubmit2, setIsSubmit2] = useState(true);
  const [disabledFlag2, setDisabledFlag2] = useState(true);

  const [defaultH0Value2, setDefaultH0Value2] = useState("");
  const [defaultH1Value2, setDefaultH1Value2] = useState("");
  const [count2, setCount2] = useState(10);
  const [yearFilter2,setYearFilter2]=useState();
  // post timeperiod
  useEffect(() => {
    // console.log("time period called")
    const fetchData = async () => {
      try {
        const response = await axios.post(`timeperiod/`, {
          tabid: "campaign",
          yearFilter:yearFilter2

        });
        const data = await response.data; // Use await to get the data
        // console.log(data);
        setDefaultStartDate2(data.data.timeperiods[0].defaultstartdate);
        setDefaultEndDate2(data.data.timeperiods[0].defaultenddate);
        setStartDate2(data.data.timeperiods[0].startdate);
        setEndDate2(data.data.timeperiods[0].enddate);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [yearFilter2]);

  // payload for filterall api
  useEffect(() => {
    setPayload2({
      ...teamPayload2,
      ...cityPayload2,
      ...dayPartpayload2,
      ...matchIdPayload2,
      ...weekFlagpayload2,
    });
  }, [
    teamPayload2,
    cityPayload2,
    dayPartpayload2,
    matchIdPayload2,
    weekFlagpayload2,
  ]);

  // post all filters........................................
  useEffect(() => {
    const fetchData = async () => {
      if (defaultStartDate2 && defaultEndDate2) {
        try {
          const response = await axios.post(`filterall/`, {
            start_date: defaultStartDate2,
            end_date: defaultEndDate2,
            matchfilters: payload2,
          });
          const data = await response.data; // Use await to get the data
          // console.log(data);
          setAllTeams2(data.teamdict);
          setDefaultTeam2(data.teamdict[0].value);

          setDefaultCity2(data.city[0]);
          setAllCities2(data.city);

          setDefaultDaypart2(data.daypart[0]);
          setAllDaypart2(data.daypart);

          setAllMatchId2(data.matchdict);
          setDefultMatchId2(data.matchdict[0].value);

          setDefaultWeekFlag2(data.wd_flag[0]);
          setAllWeekFlag2(data.wd_flag);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [defaultStartDate2, defaultEndDate2, payload2]);

  const [defaultKpiFilter2, setDefaultKpiFilter2] = useState("");
  const [allKpi2, setAllKpi2] = useState([]);
  const [chartDataCamp2, setChartDataCamp2] = useState({});
  const [fetchCallCamp2, setFetchCallCamp2] = useState(true);
  const [isAllCamp2, setIsAllCamp2] = useState(0);
  const [resetCamp2, setResetCamp2] = useState(0);
  const [campData2, setCampData2] = useState([]);
  const [BreadCrumbsCamp2, setBreadCrumbsCamp2] = useState([]);
  // console.log("BreadCrumbsCamp2: ", BreadCrumbsCamp2);

  const [channelFilters2, setChannelFilters2] = useState([]);

  // console.log("channelFilter: ", channelFilters[0]?.values);

  // get kpi filters
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`kpi_filter/`, {
          tabid: "campaign",
        });
        const data = await response.data; // Use await to get the data
        // console.log(data);
        setCascaderData2(data.data);
        setCascasderDefaultValue(data.defaultid);
        setDefaultKpiFilter2(data.kpi.default_value.value);
        setAllKpi2(data.kpi.all_value);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  function convertToChartData2(originalData) {
    const chartdata = {};

    originalData.forEach((section) => {
      const sectionData = {
        selection: [],
        // Filter: section.values.map(item => item.value)
        Filter: [],
      };
      chartdata[section.name] = sectionData;
    });

    return chartdata;
  }

  // add filters in chartdata
  function addToFilter2(jsonObj, obj) {
    // Extract the key and value from the passed object
    let key = Object.keys(obj)[0]; // Assuming only one key is passed
    let value = obj[key];

    // Check if the key exists in the JSON object
    if (jsonObj.hasOwnProperty(key)) {
      // Replace the previous value with the new one
      jsonObj[key].Filter = [value];
    } else {
      // If the key does not exist, create it with an empty "selection" array and push the value to its "Filter" array
      jsonObj[key] = {
        selection: [],
        Filter: [value],
      };
    }
    // Return the updated JSON object
    return jsonObj;
  }

  const [defaultView2, setDefaultView2] = useState("Channel First");
  // console.log("defaultView: ", defaultView2);
  // get channel filter
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`newchannelfilter/`, {
          view: defaultView2,
        });
        const data = await response.data; // Use await to get the data
        // console.log("channel filters: ", data);
        setChartDataCamp2(convertToChartData2(data.data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [defaultView2]);

  // get new channel filter
  useEffect(() => {
    const fetchData = async () => {
      if (JSON.stringify(chartDataCamp2) !== "{}" && count2 === 10) {
        try {
          const response = await axios.post(`newchannelfilter/`, {});
          const data = await response.data; // Use await to get the data
          // console.log("channel filters: ", data);
          setCount2(11);
          setChannelFilters2(data.data);
          setDefaultH0Value2(data.data[0].values[0].value);
          setDefaultH1Value2(data.data[1].values[0].value);
          setChartDataCamp2(
            addToFilter2(chartDataCamp2, { h0: data.data[0].values[0].value })
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [chartDataCamp2, count2]);

  // for campaign chart
  useEffect(() => {
    const fetchData = async () => {
      if (
        JSON.stringify(chartDataCamp2) !== "{}" &&
        fetchCallCamp2 &&
        isSubmit2 &&
        CascasderDefaultValue &&
        defaultMatchId2 &&
        defaultStartDate2 &&
        defaultEndDate2 &&
        defaultCity2 &&
        defaultDaypart2 &&
        defaultTeam2 &&
        defaultWeekFlag2
      ) {
        try {
          setIsLoading(true);
          const response = await axios.post(`comparisonchart/`, {
            kpi: getBrand2?.id?.split("-")?.pop() || CascasderDefaultValue?.split("-")?.pop(),
            match_id: defaultMatchId2,
            startdate: defaultStartDate2,
            enddate: defaultEndDate2,
            city: defaultCity2,
            time: defaultDaypart2,
            chartdata: chartDataCamp2,
            is_all_enabled: isAllCamp2,
            reset: resetCamp2,
            team1: defaultTeam2,
            weekend_flag: defaultWeekFlag2,
          });
          const data = await response.data; // Use await to get the data
          // console.log(data.chartdata);
          setCampData2(data.chartdata);
          setFetchCallCamp2(false);
          setIsAllCamp2(0);
          setResetCamp2(0);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [
    CascasderDefaultValue,
    getBrand2,
    defaultMatchId2,
    defaultStartDate2,
    defaultEndDate2,
    defaultCity2,
    defaultDaypart2,
    chartDataCamp2,
    isAllCamp2,
    resetCamp2,
    defaultTeam2,
    defaultWeekFlag2,
    fetchCallCamp2,
    isSubmit2,
  ]);

  // for tree button camp
  const DrillAllCamp2 = () => {
    // let ChartdataTemp = {};
    // for (const filter in Chartdata) {
    //   ChartdataTemp[filter] = Chartdata[filter];
    //   ChartdataTemp[filter].selection = [];
    // }

    // console.log("NotAllow: ", NotAllow);

    // new code ................
    const keysArrayOfChartData = Object.keys(chartDataCamp2);

    // console.log("keysArrayOfChartData Trend: ", keysArrayOfChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTemp = structuredClone(chartDataCamp2);


    function createArrays(numberOfArrays) {
      const arrays = [];

      for (let i = 0; i < numberOfArrays; i++) {
        arrays.push([]);
      }

      return arrays;
    }

    

    // Example usage:
    const result = createArrays(keysArrayOfChartData.length);
    // console.log("numbers of arrays: ", result);

    // result.map((item) => {
    //   item.push(...NotAllow);
    //   return "";
    // });

    result.forEach((item) => {
      return item.push(...campData2.nonclicklist);
    });

    // console.log("resulted array: ", result)

    // const payloadArray = result.map((item, index) => {
    //   return [item[0].split("-").slice(0, index + 1).join("-")];
    // })

    const payloadArray = result.map((item, index) => {
      const itemArray = item.map((key) => {
        return key
          .split("-")
          .slice(0, index + 1)
          .join("-");
      });

      //  return itemArray;
      return [...new Set(itemArray)];
    });

    // console.log("output arrays payload: ", payloadArray);

    keysArrayOfChartData
      .slice(0, keysArrayOfChartData.length - 1)
      .forEach((key, index) => {
        if (ChartdataTemp.hasOwnProperty(key)) {
          // console.log("keyssssssssssssss: ", key);
          ChartdataTemp[key].selection = payloadArray[index];
        }
      });

    // console.log("ChartdataTrendsTemp: ", ChartdataTrendsTemp);

    // new code ended..............

    setIsAllCamp2(1);
    setChartDataCamp2(ChartdataTemp);
    setFetchCallCamp2(true);
  };

  // for reset button camp
  const ResetAllCamp2 = () => {
    let ChartdataTemp = {};
    for (const filter in chartDataCamp2) {
      ChartdataTemp[filter] = chartDataCamp2[filter];
      ChartdataTemp[filter].selection = [];
    }

    setResetCamp2(1);
    setChartDataCamp2(ChartdataTemp);
    setFetchCallCamp2(true);
  };

  // for point click
  const DrillDownCamp2 = (category) => {
    // console.log("clicked category: ", category);

    const splitNameList = category.split("-");
    const Length = splitNameList.length;

    // console.log("Length: ", Length);

    const allKeysAlreadyInChartdata = Object.keys(chartDataCamp2);
    // console.log("allKeysAlreadyInChartdatra: ", allKeysAlreadyInChartdata);

    const keyToAddSelectionFilterInChartdata =
      allKeysAlreadyInChartdata[Length - 1];

    // console.log("keyToAddSelectionFilterInChartdata: ", keyToAddSelectionFilterInChartdata);

    let cloneOfChartdata = structuredClone(chartDataCamp2);

    if (cloneOfChartdata.hasOwnProperty(keyToAddSelectionFilterInChartdata)) {
      cloneOfChartdata[keyToAddSelectionFilterInChartdata].selection.push(
        category
      );
    }

    setChartDataCamp2(cloneOfChartdata);
    setFetchCallCamp2(true);
  };

  // for label click
  const DrillUpCamp2 = (category) => {
    // console.log("category: ", category.value);
    var span = document.createElement("span");
    span.innerHTML = category.value;
    const label = span.firstChild.getAttribute("title");
    // console.log("label: ", label);

    const ChartdataTemp = structuredClone(chartDataCamp2);

    for (const key in ChartdataTemp) {
      // Remove any values containing "glico-pejoy" from the selection array
      ChartdataTemp[key].selection = ChartdataTemp[key].selection.filter(
        (value) => !value.includes(label)
      );
    }

    setChartDataCamp2(ChartdataTemp);
    setFetchCallCamp2(true);
  };

  // for down arrow
  const DrillDownAllCamp2 = () => {
    // new code ................
    const keysAlreadyInChartData = Object.keys(chartDataCamp2);

    // console.log("keysAlreadyInChartData Trend: ", keysAlreadyInChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTemp = structuredClone(chartDataCamp2);

    function createArrays(numberOfArrays) {
      const arrays = [];

      for (let i = 0; i < numberOfArrays; i++) {
        arrays.push([]);
      }

      return arrays;
    }

    // Example usage:
    const result = createArrays(keysAlreadyInChartData.length);
    // console.log("numbers of arrays: ", result);

    result.forEach((item) => {
      return item.push(...campData2.nonclicklist);
    });

    const payloadArray = result.map((item, index) => {
      const itemArray = item.map((key) => {
        return key
          .split("-")
          .slice(0, index + 1)
          .join("-");
      });
      return [...new Set(itemArray)];
    });

    // console.log("output arrays payload: ", payloadArray);

    const LENGTH = BreadCrumbsCamp2.length;

    const chartDataKeysToAddSelection = keysAlreadyInChartData.slice(
      0,
      keysAlreadyInChartData.length - 1
    );

    // console.log("chartDataKeysToAddSelection: ", chartDataKeysToAddSelection);

    // chartDataKeysToAddSelection.slice(0, LENGTH).forEach((key, index) => {
    //   if(ChartdataTemp.hasOwnProperty(key)) {
    //     // console.log("keyssssssssssssss: ", key);
    //     ChartdataTemp[key].selection = payloadArray[index];
    //   }
    // })

    for (
      let index = 0;
      index < LENGTH && index < chartDataKeysToAddSelection.length;
      index++
    ) {
      const key = chartDataKeysToAddSelection[index];

      if (ChartdataTemp.hasOwnProperty(key)) {
        if (chartDataCamp2[key].selection.length < payloadArray[index].length) {
          ChartdataTemp[key].selection = payloadArray[index];
          break;
        } else {
          ChartdataTemp[key].selection = payloadArray[index];
        }
      }
    }

    // new code ended..............
    // console.log("ChartdataTemp: ", ChartdataTemp);

    setChartDataCamp2(ChartdataTemp);
    setFetchCallCamp2(true);
  };

  //  prepare BreadCrumbs camp......................................
  useEffect(() => {
    if (JSON.stringify(chartDataCamp2) !== "{}") {
      const cloneOfChartdata = structuredClone(chartDataCamp2);
      // console.log("cloneOfChartdata: ", cloneOfChartdata);
      const allKeysAlreadyInChartdata = Object.keys(cloneOfChartdata);
      const listToStoreLength = [];
      for (const key in cloneOfChartdata) {
        const list = cloneOfChartdata[key]["selection"];
        // console.log("list: ", list);
        if (list.length === 0) {
          break;
        }
        for (const name of list) {
          const arr = name.split("-");
          listToStoreLength.push(arr.length);
        }
      }

      // console.log("ARR: ", ARR);

      // console.log("listToStoreLength: ", listToStoreLength);

      let maxNumber = Math.max(...listToStoreLength);

      if (listToStoreLength.length === 0) {
        maxNumber = 0;
      }

      // console.log("maxNumber: ", maxNumber);

      setBreadCrumbsCamp2(allKeysAlreadyInChartdata.slice(0, maxNumber + 1));
    }
  }, [chartDataCamp2]);

  const DrillUpAllCamp2 = () => {
    // new code ................
    const keysAlreadyInChartData = Object.keys(chartDataCamp2);

    // console.log("keysAlreadyInChartData Trend: ", keysAlreadyInChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTemp = structuredClone(chartDataCamp2);
    // console.log("output arrays payload: ", payloadArray);

    const LENGTH = BreadCrumbsCamp2.length;

    // console.log("LENGTH: ", LENGTH);

    const chartDataKeysToRemoveSelection = keysAlreadyInChartData.slice(
      0,
      keysAlreadyInChartData.length - 1
    );

    // console.log("chartDataKeysToSelection: ", chartDataKeysToRemoveSelection);

    chartDataKeysToRemoveSelection.slice(LENGTH - 2, LENGTH).forEach((key) => {
      if (ChartdataTemp.hasOwnProperty(key)) {
        // console.log("keyssssssssssssss: ", key);
        ChartdataTemp[key].selection = [];
      }
    });

    // new code ended..............

    setChartDataCamp2(ChartdataTemp);
    setFetchCallCamp2(true);
  };

  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  return (
    <>
      {isLoading ? <Loader /> : null}
      <Menubar />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div style={{ position: "relative", top: 20 }}>
          <Stack
            gap={1}
            direction={isSmall || isMedium ? "column" : "row"}
            justifyContent={"space-between"}
            sx={{ height: "750px" }}
            // border="2px solid red"
          >
            <Stack
              flex={1}
              sx={{
                // border: "1px solid blue",
                backgroundColor: "bgColor",
                borderRadius: "20px",
                padding: "0 1rem",
                height: "100%",
                gap: 2,
                width:isSmall||isMedium?"100%":"49%",
              }}
            >
              <Filters
              setGetBrand={setGetBrand1}
              getBrand={getBrand1}
              ids={"left"}
              cascaderData= {cascaderData1}
              CascasderDefaultValue={CascasderDefaultValue}
                defaultStartDate={defaultStartDate}
                setDefaultStartDate={setDefaultStartDate}
                defaultEndDate={defaultEndDate}
                setDefaultEndDate={setDefaultEndDate}
                startDate={startDate}
                endDate={endDate}
                // year-wise-filter
                yearFilter={yearFilter}
                setYearFilter={setYearFilter}
                // team
                allTeams={allTeams}
                defaultTeam={defaultTeam}
                setTeampayload={setTeampayload} // team payload
                setDefaultTeam={setDefaultTeam}
                // location
                defaultCity={defaultCity}
                setDefaultCity={setDefaultCity}
                allCities={allCities}
                setCityPayload={setCityPayload} // city payload
                // days

                defaultWeekFlag={defaultWeekFlag}
                setDefaultWeekFlag={setDefaultWeekFlag}
                allWeekFlag={allWeekFlag}
                setDaypartpayload={setDaypartpayload} // day part
                // timings
                defaultDaypart={defaultDaypart}
                setDefaultDaypart={setDefaultDaypart}
                allDaypart={allDaypart}
                setWeekflagpayload={setWeekflagpayload} // week flag
                // team1 vs team2
                allMatchId={allMatchId}
                defaultMatchId={defaultMatchId}
                setDefultMatchId={setDefultMatchId}
                setMatchIdPayload={setMatchIdPayload} // match id payload
                isSubmit={isSubmit}
                setIsSubmit={setIsSubmit}
                disabledFlag={disabledFlag}
                setDisabledFlag={setDisabledFlag}
                setPayload={setPayload}
                setFetchCallCamp={setFetchCallCamp}
                // channel filters
                channelFilters={channelFilters}
                allKpi={allKpi}
                setDefaultKpiFilter={setDefaultKpiFilter}
                defaultH0Value={defaultH0Value}
                setDefaultH0Value={setDefaultH0Value}
                defaultH1Value={defaultH1Value}
                setDefaultH1Value={setDefaultH1Value}
                setChartDataCamp={setChartDataCamp}
                addToFilter={addToFilter}
                chartDataCamp={chartDataCamp}
              />

              <ChartContainer
                campData={campData}
                height={"74%"}
                DrillAllCamp={DrillAllCamp}
                ResetAllCamp={ResetAllCamp}
                DrillDownCamp={DrillDownCamp}
                DrillUpCamp={DrillUpCamp}
                DrillDownAllCamp={DrillDownAllCamp}
                DrillUpAllCamp={DrillUpAllCamp}
                defaultView={defaultView}
                setDefaultView={setDefaultView}
                setFetchCallCamp={setFetchCallCamp}
              />
            </Stack>

            {/* ############################## */}
            <Stack
              flex={1}
              sx={{
                // border: "1px solid blue",
                backgroundColor: "bgColor",
                borderRadius: "20px",
                padding: "0 1rem",
                height: "100%",
                gap: 2,
                width: isSmall||isMedium?"100%":"49%",
              }}
            >
              <Filters
                setGetBrand={setGetBrand2}
                getBrand={getBrand2}
                ids={"right"}
                cascaderData= {cascaderData2}
                CascasderDefaultValue={CascasderDefaultValue}
                defaultStartDate={defaultStartDate2}
                setDefaultStartDate={setDefaultStartDate2}
                defaultEndDate={defaultEndDate2}
                setDefaultEndDate={setDefaultEndDate2}
                startDate={startDate2}
                endDate={endDate2}
                // year-wise-filter
                yearFilter={yearFilter2}
                setYearFilter={setYearFilter2}
                // team
                allTeams={allTeams2}
                defaultTeam={defaultTeam2}
                setTeampayload={setTeampayload2} // team payload
                setDefaultTeam={setDefaultTeam2}
                // location
                defaultCity={defaultCity2}
                setDefaultCity={setDefaultCity2}
                allCities={allCities2}
                setCityPayload={setCityPayload2} // city payload
                // days

                defaultWeekFlag={defaultWeekFlag2}
                setDefaultWeekFlag={setDefaultWeekFlag2}
                allWeekFlag={allWeekFlag2}
                setDaypartpayload={setDaypartpayload2} // day part
                // timings
                defaultDaypart={defaultDaypart2}
                setDefaultDaypart={setDefaultDaypart2}
                allDaypart={allDaypart2}
                setWeekflagpayload={setWeekflagpayload2} // week flag
                // team1 vs team2
                allMatchId={allMatchId2}
                defaultMatchId={defaultMatchId2}
                setDefultMatchId={setDefultMatchId2}
                setMatchIdPayload={setMatchIdPayload2} // match id payload
                isSubmit={isSubmit2}
                setIsSubmit={setIsSubmit2}
                disabledFlag={disabledFlag2}
                setDisabledFlag={setDisabledFlag2}
                setPayload={setPayload2}
                setFetchCallCamp={setFetchCallCamp2}
                // channel filters
                channelFilters={channelFilters2}
                allKpi={allKpi2}
                setDefaultKpiFilter={setDefaultKpiFilter2}
                defaultH0Value={defaultH0Value2}
                defaultH1Value={defaultH1Value2}
                setDefaultH0Value={setDefaultH0Value2}
                setDefaultH1Value={setDefaultH1Value2}
                addToFilter={addToFilter2}
                setChartDataCamp={setChartDataCamp2}
                chartDataCamp={chartDataCamp2}
              />

              <ChartContainer
                campData={campData2}
                height={"74%"}
                DrillAllCamp={DrillAllCamp2}
                ResetAllCamp={ResetAllCamp2}
                DrillDownCamp={DrillDownCamp2}
                DrillUpCamp={DrillUpCamp2}
                DrillDownAllCamp={DrillDownAllCamp2}
                DrillUpAllCamp={DrillUpAllCamp2}
                defaultView={defaultView2}
                setDefaultView={setDefaultView2}
                setFetchCallCamp={setFetchCallCamp2}
              />
            </Stack>
          </Stack>
        </div>
      </ThemeProvider>
    </>
  );
};

export default Main;
