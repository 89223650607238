import React, { useEffect, useState, useRef } from "react";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import axiosDownload from "../../axiosDownload";
import LTSTROI from "./LTSTROI";
import ComparativeROI from "./ComparativeROI";
import Loader from "../../Components/Loader";
import { useLocation, Link } from "react-router-dom";
import Download from '../../images/download-icon.png'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllCampaignFilter from '../AllCampaignFilter';
import AllHeirarchyFilter from "../AllHeirarchyFilter";
import ActiveHeirarchyAndCampaignFilter from "../ActiveHeirarchyAndCampaignFilter";
import { Tooltip } from 'react-tooltip'
import InfoIcon from '../../images/icons8-info.svg'

import LightThemeHexCode from "../LightThemeHexCode";
import useDownloadChart from "../../hooks/useDownloadChartC";
import domtoimage from "dom-to-image";
import axiosChartDownload from "../../axiosChartDownload";
import downloadFlag from "../downloadFlag";




const Main = () => {
  const location = useLocation();
  const refGraphDiv = useRef(null);

  const [loader, setLoader] = useState(false);
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  // console.log("Analyse: ", Analyse);
  const AnalyticFilter = JSON.parse(localStorage.getItem("AnalyticFilter"));
  // console.log("AnalyticFilter: ", AnalyticFilter);
  const CampaignAnalyticFilter = JSON.parse(localStorage.getItem("CampaignAnalyticFilter"));
  // console.log("CampaignAnalyticFilter: ", CampaignAnalyticFilter);
  const [AnalyticFilterFlag, setAnalyticFilterFlag] = useState(true);
  const [TimeFramesAll, setTimeFramesAll] = useState([]);
  const [TimeFrame, setTimeFrame] = useState("");
  const [Tabs, setTabs] = useState([]);
  const [ActiveTab, setActiveTab] = useState("");
  // console.log("ActiveTab: ", ActiveTab);
  const [ActiveTimeFrame, setActiveTimeFrame] = useState("");
  const [Categories, setCategories] = useState([]);
  // console.log("Categories: ", Categories);
  const [Series, setSeries] = useState([]);
  const [Summary, setSummary] = useState([]);
  const [Years, setYears] = useState([]);
  const [ChannelDataAll, setChannelDataAll] = useState([]);
  const [Legends, setLegends] = useState([]);
  const [FooterNotes, setFooterNotes] = useState('');

  const [Notes, setNotes] = useState('');
  const [ChartTitle, setChartTitle] = useState('');
  const [Xtitle, setxTitle] = useState('');
  const [Ytitle, setyTitle] = useState('');

  const [AllChannelCategory, setAllChannelCategory] = useState([]);
  const [ChannelCategory, setChannelCategory] = useState("");
  const [FetchCall, setFetchCall] = useState(false);

  const [CategoryFlag, setCategoryFlag] = useState(false)
  const [ShowSummary, setShowSummary] = useState(0);
  const [IsAllEnabled, setIsAllEnabled] = useState(0);
  const [Reset, setReset] = useState(0);

  //Heirarchy filters state
  const [AllFilters, setAllFilters] = useState([]);
  // console.log("AllFilters: ", AllFilters);
  const [ActiveFilters, setActiveFilters] = useState([]);
  // console.log("ActiveFilters: ", ActiveFilters);

  const Fixed = localStorage.getItem("AnalyticFilter") ? AnalyticFilter.Fixed : [];

  const [Chartdata, setChartdata] = useState({});
  // console.log("Chartdata: ", Chartdata);

  const [NotAllow, setNotAllow] = useState([]);

  //Channel filters state
  const [CompAllFilters, setCompAllFilters] = useState([]);
  // console.log("CompAllFilters: ", CompAllFilters);
  const [CompActiveFilters, setCompActiveFilters] = useState([]);
  // console.log("CompActiveFilters: ", CompActiveFilters);

  const [Downloadchart, setDownloadchart] = useState(false);
  const [BreadCrumbs, setBreadCrumbs] = useState([]);
  // console.log("BreadCrumbs: ", BreadCrumbs);
  // const [ShowLevels, setShowLevels] = useState(0);
  const [FilterTabShow, setFilterTabShow] = useState(false);
  const [ChartHeight, setChartHeight] = useState('');
  const [ResetFilterFlag, setResetFilterFlag] = useState(false);

  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const [chartContainerID, setChartContainerID] = useState("");
  const [downloadedImageName, setDownloadedImageName] = useState("");
  const [downloadingChartTitle, setDownloadingChartTitle] = useState("");

  const [IsAllEnabledDownload, setIsAllEnabledDownload] = useState(0);

  // console.log("IsAllEnabledDownload: ", IsAllEnabledDownload);

  const [showOpenLevel, setShowOpenLevel] = useState(0);


  
  
  const userName = localStorage.getItem("NameSkewb");

  useEffect(() => {
    setIsAllEnabledDownload(0);
  }, [ActiveTab])

  const downloadButtonFlag = () => {
    if(ActiveTab === "comp") {
      return (downloadFlag.data || downloadFlag.pdf)
    }else {
      return (downloadFlag.chart || downloadFlag.data || downloadFlag.pdf)
    }
  }


  useEffect(() => {
    setChannelDataAll([]);
  }, [TimeFrame]);


  useDownloadChart(
    downloadedImageName,
    downloadingChartTitle,
    isDownloadingChart,
    chartContainerID,
    BreadCrumbs,
    setLoader,
    Analyse.Brand,
    TimeFrame,
    "",
    "",
    setIsDownloadingChart,
    Analyse.KPI
  );

  useEffect(() => {
    const url = window.location.href;
    const text = url.split('/');
    const tab = text[text.length - 1];
    for (var i = 0; i < Analyse.Menubar.length; i++) {
      if (Analyse.Menubar[i].key === tab) {
        setNotes(Analyse.Menubar[i].notes);
      }
    }
  }, [Analyse])

  useEffect(() => {
    if (AnalyticFilterFlag && AnalyticFilter && CampaignAnalyticFilter) {
      setAllFilters(AnalyticFilter.AllFilters)
      setActiveFilters(AnalyticFilter.ActiveFilters)
      setCompActiveFilters(CampaignAnalyticFilter.CompActiveFilters);
      setCompAllFilters(CampaignAnalyticFilter.CompAllFilters)
      setAnalyticFilterFlag(false)
    }
  }, [AnalyticFilter, CampaignAnalyticFilter, AnalyticFilterFlag])

  useEffect(() => {
    axios
      .post("get_roi_tabs/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setTabs(response.data.data.tabs)
          if (response.data.data.tabs.length > 0) {
            setActiveTab(response.data.data.tabs[0].tab)
            localStorage.setItem("current_level", 0);
          }
        }
      });
  }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    if (ActiveTab) {
      setLoader(true);
      axios
        .post("get_timeframes/", {
          page: ActiveTab + "_roi",
          brand: Analyse.Brand,
          kpi: Analyse.KPI
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setTimeFramesAll(response.data.data);
            setTimeFrame(response.data.data[0].key);
            setActiveTimeFrame(ActiveTab)
            setCategoryFlag(true)
            setLoader(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [ActiveTab, Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    if (CategoryFlag && TimeFrame) {
      axios
        .post("get_category/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: TimeFrame,
          page: "roi",
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setAllChannelCategory(response.data.data);
            if (response.data.data.length > 0) {
              setChannelCategory(response.data.data[0].value);
            } else {
              setChannelCategory('');
            }
            setCategoryFlag(false)
            setFetchCall(true);
            setLoader(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [ActiveTab, Analyse.Brand, Analyse.KPI, TimeFrame, CategoryFlag])

  useEffect(() => {
    // console.log("useeffect called!")
    if (FetchCall && TimeFrame && ActiveTimeFrame === ActiveTab && !CategoryFlag && JSON.stringify(Chartdata) !== "{}") {
      setLoader(true);
      setCategories([]);
      setSeries([]);
      axios
        .post("get_gain_risk_roi/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          tab: ActiveTab,
          timeframe: TimeFrame,
          category: ChannelCategory,
          chartdata: Chartdata,
          is_all_enabled: IsAllEnabled,
          reset: Reset,
          level: localStorage.getItem("DefaultLevel"),
          theme:localStorage.getItem("CurrentTheme"),
          LightThemeColorCode: [localStorage.getItem("ColorCode"), LightThemeHexCode[localStorage.getItem("ColorCode")]],

        })
        .then((response) => {
          if (response.data.error !== 1) {
            if (ActiveTab === "comp") {
              setYears(response.data.data.ChannelYears);
              setChannelDataAll(response.data.data.ChannelData);
              // setShowLevels(response.data.data.show_levels)
              setLegends(response.data.data.legends)
              setShowOpenLevel(response.data.data.show_levels);
            } else {
              setCategories(response.data.data.categories);
              setSeries(response.data.data.series);
            }
            setNotAllow(response.data.data.nonclicklist);
            setShowSummary(response.data.data.show_summary)
            setSummary(response.data.data.summary);
            setFooterNotes(response.data.notes)
            setChartTitle(response.data.data.charttitle)
            setxTitle(response.data.data.xtitle)
            setyTitle(response.data.data.ytitle)
            setIsAllEnabled(0);
            setReset(0);
            localStorage.setItem("DefaultLevel", -1)
          } else {
            setYears([]);
            setChannelDataAll([]);
            setLegends([])
            setCategories([]);
            setSeries([]);
            setSummary([]);
            setFooterNotes('')
            setChartTitle('')
            setxTitle('')
            setyTitle('')
            // toast.error(response.data.erroMsg);
            toast.error("No data found for the following filter, please reset the filter");
          }
          setFetchCall(false)
          setLoader(false);
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [ActiveTab, Analyse.Brand, Analyse.KPI, TimeFrame, ActiveTimeFrame, ChannelCategory, FetchCall, Chartdata, CategoryFlag, IsAllEnabled, Reset]);
  const DivRef = useRef(null);
  const [DivHeight, setDivHeight] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setDivHeight(DivRef.current.clientHeight)
    }, 500)
  }, [DivRef, FetchCall])

  const DownloadDataltstroi = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("ltstroi/", {
        // data: {
        //   categories: Categories,
        //   series: Series,
        //   summary: Summary,
        //   Legends: Legends
        // }
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: TimeFrame,
        chartdata: Chartdata,
        category: ChannelCategory, 
        is_all_enabled: IsAllEnabledDownload,
        reset: Reset,
        level: -1,
        theme: localStorage.getItem("CurrentTheme"),
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);

      }).catch((data) => {
        setLoader(false);
      });
  }

  const DownloadDatacomproi = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("comproi/", {
        // data: {
        //   ChannelYears: Years,
        //   ChannelData: ChannelDataAll,
        //   summary: Summary,
        //   Legends: Legends
        // }
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: TimeFrame,
        chartdata: Chartdata,
        category: ChannelCategory, 
        is_all_enabled: IsAllEnabledDownload,
        reset: Reset,
        level: -1,
        theme: localStorage.getItem("CurrentTheme"),
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);

      }).catch((data) => {
        setLoader(false);
      });
  }


  const DownloadPDFltstroi = async (e) => {
    e.preventDefault();
    setLoader(true);
    const chartContainers = [
      document.getElementById("roi_chart"),
    ];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("ltstroi/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: TimeFrame,
          chartdata: Chartdata,
          category: ChannelCategory, 
          is_all_enabled: IsAllEnabledDownload,
          reset: Reset,
          level: -1,
          theme: localStorage.getItem("CurrentTheme"),
          download_type: "PDF",
        });
        // setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post("download_pdf/create/", {
          images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
          document: {
            table1_data: response.data.table1_data,
            titlename: "ROI",
            // textcontent:
              // "We take digital marketing analytics one step further with unparalleled prescriptive deep-dive. Our innovative end-to-end analytics solution gives you relevant data & helps in strategy formulation & planning at the lowest cuts.",
            // header_content: "Loreal Haircolor Garnier- Sales",
            footer_content: "Private & Confidential",
            table_heading1: "Total ROI",
            image_heading1: "Total ROI",
            username: userName,
            timeframe: TimeFrame,
            start_date: "",
            end_date: "",
            category: ChannelCategory,
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            chartdata: Chartdata,
            tabname: "roi"
          },
        });
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };

  const DownloadPDFcomproi = async (e) => {
    e.preventDefault();
    setLoader(true);
      try {
        const response = await axiosDownload.post("comproi/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: TimeFrame,
          chartdata: Chartdata,
          category: ChannelCategory, 
          is_all_enabled: IsAllEnabledDownload,
          reset: Reset,
          level: -1,
          theme: localStorage.getItem("CurrentTheme"),
          download_type: "PDF",
        });

        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post("download_pdf/create/", {
          images: [],
          document: {
            table1_data: response.data.table1_data,
            titlename: "ROI",
            // textcontent:
              // "We take digital marketing analytics one step further with unparalleled prescriptive deep-dive. Our innovative end-to-end analytics solution gives you relevant data & helps in strategy formulation & planning at the lowest cuts.",
            footer_content: "Private & Confidential",
            table_heading1: "Comparitive ROI",
            username: userName,
            timeframe: TimeFrame,
            start_date: "",
            end_date: "",
            category: ChannelCategory,
            chartdata: Chartdata,
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            tabname: "roi"
          },
        });
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
    }
  };

  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight)
    }
  }, [Series])

  // When Activefilter change : prepare chartdata for input of get api call
  useEffect(() => {
    const chartdata = {};
    const ChartdataTemp = structuredClone(Chartdata);
    // console.log("ChartdataTemp: ", ChartdataTemp);

    if (Fixed.length !== 0) {
      for (var r = 0; r < Fixed.length; r++) {
        const Filter = [];
        Filter.push(Fixed[r].value)
        chartdata[Fixed[r].id] = {
          selection: [],
          Filter: Filter,
        };
      }
    }

    if (ActiveFilters.length !== 0) {
      for (var i = 0; i < ActiveFilters.length; i++) {
        const selection = [];
        if ("Selected" in ActiveFilters[i]) {
          if (ActiveFilters[i]["Selected"].length > 0) {
            setResetFilterFlag(true)
          }

          for (var j = 0; j < ActiveFilters[i].Selected.length; j++) {
            var temp_arr = ActiveFilters[i].Selected[j].value.split("-");
            selection.push(temp_arr[temp_arr.length - 1]);
          }
        }

        chartdata[ActiveFilters[i].name] = {
          selection: typeof Chartdata[ActiveFilters[i].name] !== 'undefined' ? Chartdata[ActiveFilters[i].name].selection : [],
          Filter: selection,
        };
      }
    }

    for (var k = 0; k < CompActiveFilters.length; k++) {
      const selection = [];
      if ("Selected" in CompActiveFilters[k]) {
        if (CompActiveFilters[k]["Selected"].length > 0) {
          setResetFilterFlag(true)
        }

        for (var l = 0; l < CompActiveFilters[k].Selected.length; l++) {
          selection.push(CompActiveFilters[k].Selected[l].value);
        }
      }
      chartdata[CompActiveFilters[k].name] = {
        selection: [],
        Filter: selection,
      };
    }


    const keys = Object.keys(chartdata);
    const lastKeyIndex = keys.length - 1;
    
    for (let i = 0; i < lastKeyIndex; i++) {
      const key = keys[i];
      if (ChartdataTemp.hasOwnProperty(key)) {
        chartdata[key].selection = ChartdataTemp[key].selection;
      }
    }


    // for (const key in ChartdataTemp) {
    //   if(chartdata.hasOwnProperty(key)) {
    //     chartdata[key].selection = ChartdataTemp[key].selection;
    //   }
    // }



    setChartdata(chartdata);
    setFetchCall(true);
  }, [ActiveFilters, CompActiveFilters]);

  //HEIRARCHY FILTERS 
  const GetOption = (current_level, selection, destination_index) => {
    setLoader(true);
    axios
      .post("get_hirarchy_dropdown_options/", {
        hirarchy: Analyse.Brand,
        current_level: current_level,
        old_selection: selection !== "all" ? selection : ""
      })
      .then((response) => {
        const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
        var sort_arr = [...AllFilters];
        for (var i = 0; i < sort_arr.length; i++) {
          if (parseInt(sort_arr[i].level) === current_level) {
            var obj = sort_arr[i];
            obj['filters'] = response.data.data.options;
            obj['Selected'] = [];

            const temp_index = sort_arr.indexOf(sort_arr[i]);
            if (temp_index > -1) { // only splice array when item is found
              sort_arr.splice(temp_index, 1); // 2nd parameter means remove one item only
            }

            ActiveFiltersTemp.splice(
              destination_index,
              0,
              obj
            );
          }
        }
        let data = [...ActiveFiltersTemp];
        for (let k = 0; k < ActiveFiltersTemp.length; k++) {
          if (destination_index < k) {
            data[k]['filters'] = [];
            data[k]['Selected'] = [];
          }
        }
        setActiveFilters(data);

        //Set analytical filters data in localstorage
        const AnalyticFilterTemp = {
          AllFilters: AllFilters,
          Fixed: Fixed,
          ActiveFilters: data
        };

        localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

        setLoader(false);
      }).catch((data) => {
        setLoader(false);
      });
  }

  const AddHeirarchyFilter = (index) => {

    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    let selection_value = [];
    var i = ((ActiveFiltersCompTemp.length) - 1);
    while (i >= 0) {
      if (ActiveFilters[i]['Selected'].length !== 0) {
        for (var j = 0; j < ActiveFilters[i]['Selected'].length; j++) {
          selection_value.push(ActiveFilters[i]['Selected'][j].value === "all" ? "" : ActiveFilters[i]['Selected'][j].value);
        }
      }
      i--;
    }

    const selection = selection_value.toString().replaceAll(",", ";");
    GetOption(parseInt(AllFilters[index].level), selection, ActiveFiltersCompTemp.length)

    let filter = AllFilters[index];
    filter.Selected = [];

    // setActiveFilters(ActiveFiltersCompTemp);
    setFilterTabShow(true);
  };

  const onChangeFilter = (selected, index, data) => {

    const ActiveFiltersTemp = data ? data : JSON.parse(JSON.stringify(ActiveFilters));

    ActiveFiltersTemp[index].Selected = selected;

    let next_level_arr = [];
    for (var j = 0; j < ActiveFiltersTemp.length; j++) {
      if (j > index) {
        next_level_arr.push(ActiveFiltersTemp[j].level);
      }
    }

    const next_level = next_level_arr.toString().replaceAll(",", ";");

    setLoader(true);

    var selection_value = [];
    for (var i = 0; i < selected.length; i++) {
      selection_value.push(selected[i].value);
    }

    const selection = selection_value.toString().replaceAll(",", ";");
    axios
      .post("hirarchy_dropdown_child_options/", {
        hirarchy: Analyse.Brand,
        "selection": selection !== "all" ? selection : "",
        "next_level": next_level
      })

      .then((response) => {
        var data = [...ActiveFiltersTemp]
        for (var i = 0; i < ActiveFiltersTemp.length; i++) {

          if (response.data.data[data[i].level]) {
            data[i]['Selected'] = []
            data[i]['filters'] = response.data.data[data[i].level];
          }
        }
        setActiveFilters(data);

        //Set analytical filters data in localstorage
        const AnalyticFilterTemp = {
          AllFilters: AllFilters,
          Fixed: Fixed,
          ActiveFilters: data
        };

        localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

        setLoader(false);
      }).catch((data) => {
        setLoader(false);
      });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "inactive"
    ) {

      let selection_value = [];

      var i = ((result.destination.index) - 1);
      while (i >= 0) {
        if (ActiveFilters[i]['Selected'].length !== 0) {
          for (var j = 0; j < ActiveFilters[i]['Selected'].length; j++) {
            selection_value.push(ActiveFilters[i]['Selected'][j].value === "all" ? "" : ActiveFilters[i]['Selected'][j].value);
          }
        }
        i--;
      }
      const selection = selection_value.toString().replaceAll(",", ";");
      GetOption(parseInt(result.draggableId), selection, result.destination.index)
    }

    if (
      result.destination.droppableId === "inactive" &&
      result.source.droppableId === "active"
    ) {
      const AllFiltersTemp = JSON.parse(JSON.stringify(AllFilters));
      AllFiltersTemp.splice(
        result.destination.index,
        0,
        ActiveFilters[result.source.index]
      );
      setAllFilters(AllFiltersTemp);

      const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
      ActiveFiltersTemp.splice(result.source.index, 1);
      setActiveFilters(ActiveFiltersTemp);

      // //Set analytical filters data in localstorage
      // const AnalyticFilter = JSON.parse(localStorage.getItem("AnalyticFilter"));
      // const AnalyticFilterTemp = {
      //   AllFilters: AllFiltersTemp,
      //   Fixed: AnalyticFilter.Fixed,
      //   ActiveFilters: ActiveFiltersTemp
      // };
      // localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    }

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "active"
    ) {
      const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));

      ActiveFiltersTemp.splice(result.source.index, 1);
      ActiveFiltersTemp.splice(
        result.destination.index,
        0,
        ActiveFilters[result.source.index]
      );

      onChangeFilter(ActiveFiltersTemp[result.destination.index].Selected, result.destination.index, ActiveFiltersTemp);

    }

    if (
      result.destination.droppableId === "inactive" &&
      result.source.droppableId === "inactive"
    ) {
      const AllFiltersTemp = JSON.parse(JSON.stringify(AllFilters));
      AllFiltersTemp.splice(result.source.index, 1);
      AllFiltersTemp.splice(
        result.destination.index,
        0,
        AllFilters[result.source.index]
      );
      setAllFilters(AllFiltersTemp);
    }

  };

  const RemoveHeirarchyFilter = (item) => {

    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersCompTemp
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

  };

  const IsInAllHeirarchyFilters = (item) => {
    for (let i = 0; i < ActiveFilters.length; i++) {
      if (ActiveFilters[i].name === item.name) {
        return true
      }
    }
    return false
  }

  //CHANNEL FILTERS
  const AddCompFilter = (index) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    let filter = CompAllFilters[index];
    filter.Selected = [filter.filters[0]];

    ActiveFiltersCompTemp.push(filter);
    setCompActiveFilters(ActiveFiltersCompTemp);
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp
    };

    localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));
  };

  const RemoveCompFilter = (item) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setCompActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp
    };

    localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));
  };

  const IsInAllCompFilters = (item) => {
    for (let i = 0; i < CompActiveFilters.length; i++) {
      if (CompActiveFilters[i].name === item.name) {
        return true
      }
    }
    return false
  }

  const onCompDragEnd = (result) => {
    if (!result.destination) return;

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "active"
    ) {
      const ActiveFiltersCompTemp = JSON.parse(
        JSON.stringify(CompActiveFilters)
      );
      ActiveFiltersCompTemp.splice(result.source.index, 1);
      ActiveFiltersCompTemp.splice(
        result.destination.index,
        0,
        CompActiveFilters[result.source.index]
      );
      setCompActiveFilters(ActiveFiltersCompTemp);

      //Set campaign filter data in localstorage
      const CampaignAnalyticFilter = {
        CompAllFilters: CompAllFilters,
        CompActiveFilters: ActiveFiltersCompTemp
      };

      localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));
    }
  };

  const onCompChangeFilter = (selected, index) => {
    const CompActiveFiltersTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    // if (selected.length === 0) {
    //   return;
    // }
    CompActiveFiltersTemp[index].Selected = selected;
    setCompActiveFilters(CompActiveFiltersTemp);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: CompActiveFiltersTemp
    };

    localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));
  };

  
//  prepare BreadCrumbs......................................
  useEffect(() => {
   if(JSON.stringify(Chartdata) !== "{}") {
    const cloneOfChartdata =structuredClone(Chartdata);
    // console.log("cloneOfChartdata: ", cloneOfChartdata);
    const allKeysAlreadyInChartdata = Object.keys(cloneOfChartdata);
    const listToStoreLength = [];
    for(const key in cloneOfChartdata) {
      const list = cloneOfChartdata[key]["selection"];
      // console.log("list: ", list);
      if(list.length === 0) {
        break;
      }
      for(const name of list) {
        const arr = name.split("-");
        listToStoreLength.push(arr.length);
      }
    }
    


    // console.log("ARR: ", ARR);

    // console.log("listToStoreLength: ", listToStoreLength);

    let maxNumber = Math.max(...listToStoreLength)

    if(listToStoreLength.length === 0) {
      maxNumber = 0
    }

    // console.log("maxNumber: ", maxNumber);
    

    setBreadCrumbs(allKeysAlreadyInChartdata.slice(0, maxNumber + 1));

  }

  }, [Chartdata])


  // for point click
  const DrillDown = (category) => {
    // console.log("clicked category: ", category);


    const splitNameList = category.split("-");
    const Length = splitNameList.length;


    // console.log("Length: ", Length);


    const allKeysAlreadyInChartdata = Object.keys(Chartdata);
    // console.log("allKeysAlreadyInChartdatra: ", allKeysAlreadyInChartdata);

    const keyToAddSelectionFilterInChartdata = allKeysAlreadyInChartdata[Length - 1];

    // console.log("keyToAddSelectionFilterInChartdata: ", keyToAddSelectionFilterInChartdata);
   

    let cloneOfChartdata = structuredClone(Chartdata);

  

    if(cloneOfChartdata.hasOwnProperty(keyToAddSelectionFilterInChartdata)) {
      cloneOfChartdata[keyToAddSelectionFilterInChartdata].selection.push(category);
    } 


    setChartdata(cloneOfChartdata);
    setFetchCall(true);


    // setClickedCategory(Category);
    setIsAllEnabledDownload(0);
  }; 

  // for label click
  const DrillUp = (category) => {
    // console.log("category: ", category.value);
    var span = document.createElement("span");
    span.innerHTML = category.value;
    const label = span.firstChild.getAttribute("title");
    // console.log("label: ", label);

    
    const ChartdataTemp = structuredClone(Chartdata);

    for (const key in ChartdataTemp) {
      // Remove any values containing "glico-pejoy" from the selection array
      ChartdataTemp[key].selection = ChartdataTemp[key].selection.filter(
        (value) => !value.includes(label)
      );
    }

    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0);
  };

  // for down arrow
  const DrillDownAll = () => {
      // new code ................
      const keysAlreadyInChartData = Object.keys(Chartdata);

      // console.log("keysAlreadyInChartData Trend: ", keysAlreadyInChartData);
  
      // console.log("not allow list: ", NotAllow);
  
      const ChartdataTemp = structuredClone(Chartdata);
  
      function createArrays(numberOfArrays) {
        const arrays = [];
      
        for (let i = 0; i < numberOfArrays; i++) {
          arrays.push([]);
        }
      
        return arrays;
      }
      
      // Example usage:
      const result = createArrays(keysAlreadyInChartData.length);
      // console.log("numbers of arrays: ", result);
  
  
     result.forEach(item => {
      return item.push(...NotAllow);
     })
  
  
  
      const payloadArray = result.map((item, index) => {
           const itemArray = item.map(key => {
             return key.split("-").slice(0, index + 1).join("-");
           })
           return [...new Set(itemArray)]
      })
  
  
      // console.log("output arrays payload: ", payloadArray);

      const LENGTH = BreadCrumbs.length;

      const chartDataKeysToAddSelection = keysAlreadyInChartData.slice(0 , keysAlreadyInChartData.length - 1);

      // console.log("chartDataKeysToAddSelection: ", chartDataKeysToAddSelection);
  
      // chartDataKeysToAddSelection.slice(0, LENGTH).forEach((key, index) => {
      //   if(ChartdataTemp.hasOwnProperty(key)) {
      //     // console.log("keyssssssssssssss: ", key);
      //     ChartdataTemp[key].selection = payloadArray[index];
      //   }
      // })

      for (let index = 0; index < LENGTH && index < chartDataKeysToAddSelection.length; index++) {
        const key = chartDataKeysToAddSelection[index];
        
        if (ChartdataTemp.hasOwnProperty(key)) {
            if (Chartdata[key].selection.length < payloadArray[index].length) {
                ChartdataTemp[key].selection = payloadArray[index];
                break;
            } else {
                ChartdataTemp[key].selection = payloadArray[index];
            }
        }
    }
    

      // new code ended..............
  
     setChartdata(ChartdataTemp);
     setFetchCall(true);
     setIsAllEnabledDownload(0);

  }

   // for up arrow
  const DrillUpAll = () => {
     // new code ................
      const keysAlreadyInChartData = Object.keys(Chartdata);

      // console.log("keysAlreadyInChartData Trend: ", keysAlreadyInChartData);
  
      // console.log("not allow list: ", NotAllow);
  
      const ChartdataTemp = structuredClone(Chartdata);
      // console.log("output arrays payload: ", payloadArray);

      const LENGTH = BreadCrumbs.length;

      // console.log("LENGTH: ", LENGTH);

      const chartDataKeysToRemoveSelection = keysAlreadyInChartData.slice(0 , keysAlreadyInChartData.length - 1);

      // console.log("chartDataKeysToSelection: ", chartDataKeysToRemoveSelection);
  
      chartDataKeysToRemoveSelection.slice(LENGTH - 2, LENGTH).forEach((key) => {
        if(ChartdataTemp.hasOwnProperty(key)) {
          // console.log("keyssssssssssssss: ", key);
          ChartdataTemp[key].selection = [];
        }
      })

      // new code ended..............
  
     setChartdata(ChartdataTemp);
     setFetchCall(true);
     setIsAllEnabledDownload(0);
  };


  // for tree button
  const DrillAll = () => {
    // let ChartdataTemp = {};
    // for (const filter in Chartdata) {
    //   ChartdataTemp[filter] = Chartdata[filter];
    //   ChartdataTemp[filter].selection = [];
    // }

    // console.log("NotAllow: ", NotAllow);

     // new code ................
     const keysArrayOfChartData = Object.keys(Chartdata);

     // console.log("keysArrayOfChartData Trend: ", keysArrayOfChartData);
 
     // console.log("not allow list: ", NotAllow);
 
     const ChartdataTemp = structuredClone(Chartdata);
 
     function createArrays(numberOfArrays) {
       const arrays = [];
     
       for (let i = 0; i < numberOfArrays; i++) {
         arrays.push([]);
       }
     
       return arrays;
     }
     
     // Example usage:
     const result = createArrays(keysArrayOfChartData.length);
     // console.log("numbers of arrays: ", result);
 
     // result.map((item) => {
     //   item.push(...NotAllow);
     //   return "";
     // });
 
    result.forEach(item => {
     return item.push(...NotAllow);
    })
 
     // console.log("resulted array: ", result)
 
 
     // const payloadArray = result.map((item, index) => {
     //   return [item[0].split("-").slice(0, index + 1).join("-")];
     // })
 
     const payloadArray = result.map((item, index) => {
          const itemArray = item.map(key => {
            return key.split("-").slice(0, index + 1).join("-");
          })
 
         //  return itemArray;
        return [...new Set(itemArray)]
     })
 
 
     // console.log("output arrays payload: ", payloadArray);
 
     keysArrayOfChartData.slice(0, keysArrayOfChartData.length - 1).forEach((key, index) => {
       if(ChartdataTemp.hasOwnProperty(key)) {
         // console.log("keyssssssssssssss: ", key);
         ChartdataTemp[key].selection = payloadArray[index];
       }
     })
 
     // console.log("ChartdataTrendsTemp: ", ChartdataTrendsTemp);
 
     // new code ended..............
 
    setIsAllEnabled(1);
    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(1);
  };


  // for reset button
  const ResetAll = () => {
    let ChartdataTemp = {};
    for (const filter in Chartdata) {
      ChartdataTemp[filter] = Chartdata[filter];
      ChartdataTemp[filter].selection = [];
    }

    setReset(1);
    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0);

  };




  // new filters functions end..........

  const ResetFilters = () => {
    var active_filter = []
    for (let i = 0; i < ActiveFilters.length; i++) {
      var obj = ActiveFilters[i];
      obj['Selected'] = [];
      active_filter.push(obj)
    }

    var comp_active_filter = []
    for (let k = 0; k < CompActiveFilters.length; k++) {
      var obj1 = CompActiveFilters[k];
      obj1['Selected'] = [];
      comp_active_filter.push(obj1)
    }

    setResetFilterFlag(false)
    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: active_filter
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: comp_active_filter
    };

    localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));

    setActiveFilters(active_filter)
    setCompActiveFilters(comp_active_filter)
  }


  return (
    <>
      {loader ? <Loader /> : null}

      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w pt-0">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {Analyse && Analyse.Menubar
                      ? Analyse.Menubar.map((item) => {
                        return "/" + item.key === location.pathname
                          ? item.value
                          : null;
                      })
                      : null}
                  </h1>
                </div>
              </div>
              <div className="row pr-0 mt-1" style={{ alignItems: "center" }}>
                <AllHeirarchyFilter OnlyHeirarchyFilter={false} Fixed={Fixed} ActiveFilters={ActiveFilters} AllFilters={AllFilters} AddHeirarchyFilter={AddHeirarchyFilter} RemoveHeirarchyFilter={RemoveHeirarchyFilter} IsInAllHeirarchyFilters={IsInAllHeirarchyFilters} Chartdata={[Chartdata]} />
                <AllCampaignFilter CompActiveFilters={CompActiveFilters} CompAllFilters={CompAllFilters} AddCompFilter={AddCompFilter} RemoveCompFilter={RemoveCompFilter} IsInAllCompFilters={IsInAllCompFilters} Chartdata={[Chartdata]} />
                <div className="col-xl-2 pr-0">
                  <div className="card my-1">
                    <div className="card-body cursor-pointer d-flex align-items-center justify-content-center custom-selected-filter" onClick={() => {
                      setFilterTabShow(true);
                    }} >
                      <h6 className="m-0 text-center primary-text"><i className="fa fa-filter" aria-hidden="true"></i> Selected Filter</h6>
                      {ResetFilterFlag ?
                        <h6 className="m-0 text-center primary-text cursor-pointer custom-reset" >
                          <p className="mx-2 m-0" title="Reset Filter" onClick={() => {
                            ResetFilters();
                          }}> &#8635; </p>
                        </h6>
                        : null
                      }
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div
            className="container-fluid main-content-w pt-1"
            style={{ paddingTop: "10px"}}
          >
            <div className="row">

              {
                FilterTabShow ?
                  <ActiveHeirarchyAndCampaignFilter OnlyHeirarchyFilter={false} onCompChangeFilter={onCompChangeFilter} onChangeFilter={onChangeFilter} onDragEnd={onDragEnd} ActiveFilters={ActiveFilters} onCompDragEnd={onCompDragEnd} setFilterTabShow={setFilterTabShow} CompActiveFilters={CompActiveFilters} CompAllFilters={CompAllFilters} Fixed={Fixed} />
                  : null
              }
             
              <div className={parseInt(ShowSummary) === 1 ? "col-xl-9" : "col-xl-12"}>
                <div className="card card-min-height synergy-table-height" ref={DivRef}>
                  <div className="card-body brand-card ef-drivers my-2" >
                    <div className="row align-items-center justify-content-sm-between">
                      <div className="col-sm-4 d-flex">
                        <select
                          className="form-select custom-input-box"
                          onChange={(e) => {
                            setTimeFrame(e.target.value);
                            setChannelCategory("")
                            setCategoryFlag(true)
                          }
                          }
                          value={TimeFrame}
                        >
                          {TimeFramesAll?.map((item) => {
                            return (
                              <option key={item.key} value={item.key}>
                                {item.value}
                              </option>
                            );
                          })}
                        </select>
                        {AllChannelCategory.length !== 0 ? (
                          <select
                            className="form-select custom-input-box mx-3"
                            onChange={(e) => {
                              setChannelCategory(e.target.value);
                              setFetchCall(true);
                            }}
                            value={ChannelCategory}
                          >
                            {AllChannelCategory.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        ) : null}
                      </div>

                      <div className="col-sm-5">
                        <p className="card-title-lable" style={{ paddingLeft: (ActiveTab === "lt_st") ? "134px" : "0px" }}>
                          {ChartTitle}
                        </p>
                      </div>
                      <div className="col-sm-3 d-flex align-items-center justify-content-sm-end">
                        {
                          Tabs.map((item) => {
                            return (
                               <p
                                 key={item.tab}
                                 style={{ cursor: "pointer" }}
                                 className={
                                   ActiveTab === item.tab
                                     ? "m-0 px-2 mx-2 lt-st custom-input-box"
                                     : "m-0 px-2 mx-2 text-lable custom-input-box"
                                 }
                                 onClick={() => setActiveTab(item.tab)}
                               >
                                 {item.tab_name}
                               </p>
                            )
                          })
                        }
                         {/* <p
                          style={{ cursor: "pointer" }}
                          className={
                            ActiveTab === "halo"
                              ? "m-0 px-2 mx-2 lt-st custom-input-box"
                              : "m-0 px-2 mx-2 text-lable custom-input-box"
                          }
                          onClick={() => setActiveTab("halo")}
                          >
                            {"Halo"}
                        </p> */}
                        {
                          Notes !== "" ?
                            <>
                               <p className="m-0 px-2">
                                <svg src={InfoIcon} id="notes" xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-info-circle" viewBox="0 0 16 16">
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                </svg>
                              </p>

                              <Tooltip anchorSelect="#notes" clickable>
                                {/* <div className="api-notes">
                                  {Notes}
                                </div> */}
                                <div
                                  className="api-notes"
                                  dangerouslySetInnerHTML={{ __html: Notes }}
                                >
                                </div>
                              </Tooltip>
                            </> : null
                        }

                      { downloadButtonFlag() && <div className="flex-shrink-0 dropdown">
                          <Link
                            to="#"
                            className="d-flex align-items-center text-decoration-none dropdown-toggle text-white"
                            id="dropdownUser2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <p className="m-0 px-2">
                              <svg src={Download} title="Download" xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                              </svg>
                            </p>
                          </Link>
                          <ul
                            className="dropdown-menu text-small shadow"
                            aria-labelledby="dropdownUser2"
                          >
                           { ActiveTab !== "comp" && downloadFlag.chart && <li onClick={(e) => { }}>
                              <Link className="dropdown-item" onClick={(e) => {
                                e.preventDefault();
                                if (Categories.length > 0) {
                                  // setDownloadchart(true)
                                  // setLoader(true)
                                  setIsDownloadingChart(true);
                                  setDownloadingChartTitle("ROI")
                                  setChartContainerID("roi_chart");
                                  setDownloadedImageName("ROI");
                                } else {
                                  toast.error("Chartdata is not available.")
                                }
                              }}>
                                Download Chart
                              </Link>
                            </li>
                            }

                           {downloadFlag.data && <li onClick={(e) => { }}>
                              {ActiveTab === "lt_st" ? (
                                <Link className="dropdown-item" onClick={(e) => DownloadDataltstroi(e)} >Download Data</Link>
                              ) : null}
                              {ActiveTab === "comp" ? (
                                <Link className="dropdown-item" onClick={(e) => DownloadDatacomproi(e)}>Download Data</Link>
                              ) : null}
                            </li>}

                            {/* download pdf */}
                            {downloadFlag.pdf &&<li onClick={(e) => { }}>
                              {ActiveTab === "lt_st" ? (
                                <Link className="dropdown-item" onClick={(e) => DownloadPDFltstroi(e)} >Download PDF</Link>
                              ) : null}
                              {ActiveTab === "comp" ? (
                                <Link className="dropdown-item" onClick={(e) => DownloadPDFcomproi(e)}>Download PDF</Link>
                              ) : null}
                            </li>}
                            {/* download pdf */}
                          </ul>
                        </div>}

                      </div>
                    </div>

                    {ActiveTab === "lt_st" && Categories.length !== 0 ? (
                      <div className="h-600 mt-1">
                        <div className="col-sm-12 py-2">
                          <div className="row align-items-center sorting-btn-ui">
                            <div className="col-sm-3">
                              <ul>
                                <li onClick={() => {
                                  DrillUpAll()
                                }} title="Drill Up">
                                  <i className="fa fa-arrow-up" aria-hidden="true"></i>
                                </li>
                                <li onClick={() => {
                                  DrillDownAll()
                                }} title="Drill Down">
                                  <i
                                    className="fa fa-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </li>
                                <li onClick={() => {
                                  DrillAll()
                                }} title="Expand All">
                                  <i className="fa fa-sitemap" aria-hidden="true"></i>
                                </li>
                                <li onClick={() => {
                                  ResetAll()
                                }} title="Reset">
                                  <i className="fa" aria-hidden="true">
                                    &#8635;
                                  </i>
                                </li>
                              </ul>
                            </div>
                            <div className="col-sm-9 custom-brdcrum">
                              {BreadCrumbs.join(" / ")}
                            </div>
                          </div>
                        </div>

                        <div className="roi-chart-height" ref={refGraphDiv}>
                          <LTSTROI BreadCrumbs={BreadCrumbs} ActiveFilters={ActiveFilters} Brand={Analyse.brand} Fixed={Fixed} Timeframe={TimeFrame} setLoader={setLoader} Xtitle={Xtitle} Ytitle={Ytitle} Categories={Categories} Series={Series} height={ChartHeight} DrillDown={DrillDown} DrillUp={DrillUp} NotAllow={NotAllow} Chartdata={Chartdata} Downloadchart={Downloadchart} setDownloadchart={setDownloadchart} />
                        </div>
                      </div>
                    ) : ActiveTab === "lt_st" ? <div className="mt-2"><h6 style={{ textAlign: "center" }}>No Data Found</h6></div> : null}

                    {ActiveTab === "comp" ? (
                      <ComparativeROI
                        Xtitle={Xtitle}
                        Ytitle={Ytitle}
                        ChannelYears={Years}
                        ChannelData={ChannelDataAll}
                        Legends={Legends}
                        height={ChartHeight}
                        showOpenLevel={showOpenLevel}
                        setShowOpenLevel={setShowOpenLevel}
                      />
                    ) : ActiveTab === "comp" ? <div className="mt-2"><h6 style={{ textAlign: "center" }}>No Data Found</h6></div> : null}
                    {/* Halo tab start */}
                    {/* {ActiveTab === "halo" ? <div>Halo</div> : <div>Halo</div>} */}
                    {/* Halo tab end */}
                  </div>
                </div>
              </div>

              {
                parseInt(ShowSummary) === 1 ?
                  <div className="col-xl-3">

                    <div className="card card-min-height" >
                      <div className="card-body brand-card" style={{ height: DivHeight + "px" }}>

                        <div className="row align-items-center justify-content-sm-between">
                          <h3 className="data-spends my-2">Summary</h3>
                          <div className="spends-list my-2">
                            <ol>
                              {Summary.map((item, index) => {
                                return (
                                  <li key={item + index} className="spends-list-li">
                                    {item}
                                  </li>
                                );
                              })}
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  : null
              }
            </div>

            <div className="row custom-note">
              <div className="col-sm-1">
                <div className="col-sm-1">
                  {
                    FooterNotes !== "" ?
                      <>
                        <i
                          className="fas fa-mail-bulk chart_info_icon footer-notes-icon"
                          id="clickable"
                        ></i>
                        <Tooltip anchorSelect="#clickable" clickable>
                          {/* <div className="footer-notes">
                            {FooterNotes}
                          </div> */}
                           <div
                             className="footer-notes"
                             dangerouslySetInnerHTML={{ __html: FooterNotes }}
                             >
                           </div>
                        </Tooltip>
                      </>
                      : null
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
