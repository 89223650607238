// export default BaseURL = 'http://15.206.194.153'
// export default BaseURL = 'http://demo-1.skewb.ai'
// export default BaseURL = 'http://13.126.233.140'

// const BaseURL = 'http://43.204.176.201'
// const BaseURL ='http://13.127.224.137'
// const BaseURL = "http://3.108.210.176";
// const BaseURL = "http://52.66.195.32";
// const BaseURL = "http://newdemo.skewb.ai";
// const BaseURL = "http://me.skewb.ai";
// const BaseURL = "http://43.205.63.175";
// const BaseURL = "";
// const BaseURL = "https://dev.angeleye.in";
// const BaseURL="http://15.207.123.147:8007"

// const BaseURL = "http://glicodev.skewb.ai";
// const BaseURL = "http://newtdc.skewb.ai";
// const BaseURL = "http://brezza.skewb.ai";
// const BaseURL = "http://msxiaomi.skewb.ai";

const getBaseURL = () => {
    const hostname = window.location.hostname;
    console.log("host name",hostname);
 
    // URL mapping object
    const urlMap = {
      // 'devdemo.skewb.ai': 'https://devdemo.skewb.ai',
      // 'newdevtest.skewb.ai': 'https://newdevtest.skewb.ai',
      // 'stagingnew.skewb.ai': 'https://stagingnew.skewb.ai',
      // 'phonepe.skewb.ai': 'https://phonepe.skewb.ai',
      // 'mmm.angeleye.in': 'https://mmm.angeleye.in',
      // 'proddemo.skewb.ai': 'https://proddemo.skewb.ai',
      // 'amazon.skewb.ai': 'https://amazon.skewb.ai',
      // 'newdevme.skewb.ai': 'https://newdevme.skewb.ai',
      // 'newdevtdc.skewb.ai': 'https://newdevtdc.skewb.ai',
      // 'msildemo.skewb.ai': 'https://msildemo.skewb.ai',
      // 'brand-amazon.skewb.ai':'https://brand-amazon.skewb.ai',
      'localhost': 'https://dev.angeleye.in' // Default for local development
    };
 
   
    // Return the matching URL or default to localhost URL
    return urlMap[hostname]||`https://${hostname}`;
  };
 
  export const BaseURL = getBaseURL();

export default BaseURL;
