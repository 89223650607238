const LightThemeHexCode = Object.freeze({
  Lavender: "#7866B0",
  Sapphire: "#47539B",
  Teal: "2D6C70",
  Cyan: "#167581",
  Goldenrod: "#D48B32",
  null: "",
});

export default LightThemeHexCode;

// LightThemeColorCode: [localStorage.getItem("ColorCode"), LightThemeHexCode[localStorage.getItem("ColorCode")]],

