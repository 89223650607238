import React, { useState } from "react";
import { Box, Stack, Typography, useTheme, useMediaQuery } from "@mui/material";
import ColumnLineChart from "./ColumnLineChart";
import StackContainer from "./StackContainer";
import CampaignContainer from "./CampaignContainer";
import LineChart from "./LineChart";
import FunnelChart from "./FunnelChart";
import IndiaMap from "./IndiaMap";
import MatchStackChartContainer from "./MatchStackChartContainer";
import BaselineChartShimmer from "./BaseLineShimmer";
import Funnel from "./FunnelContainer/Funnel";
import ColumnLineContainer from "./ColumnLineContainer";

const Tab = (props) => {
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
  const [viewToggle,setViewToggle]=useState(true);
  
  const returnChart = (activeTab) => {
    switch (activeTab) {
      case "tab1":
        return props.isBaseLineLoading ? (
          <BaselineChartShimmer />
        ) : (
          <ColumnLineContainer
            isBaseLineLoading={props.isBaseLineLoading}
            baseLineSeries={props.baseLineSeries}
            baseLineData={props.baseLineData}
            viewToggle={viewToggle}
            setViewToggle={setViewToggle}
          />
        );
 
      case "tab2":
        return (
          <StackContainer
            stackCategories={props.stackCategories}
            stackSeries={props.stackSeries}
            NotAllow={props.NotAllow}
            DrillDownTrends={props.DrillDownTrends}
            DrillAllTrends={props.DrillAllTrends}
            ResetAllTrends={props.ResetAllTrends}
            DrillDownAllTrends={props.DrillDownAllTrends}
            DrillUpAllTrends={props.DrillUpAllTrends}
            activeTab={props.activeTab}
            showPerformance={props.showPerformance}
            setShowPerformance={props.setShowPerformance}
            setFetchCallTrends={props.setFetchCallTrends}
          />
        );
      case "tab3":
        return (
          <CampaignContainer
            campData={props.campData}
            DrillAllCamp={props.DrillAllCamp}
            ResetAllCamp={props.ResetAllCamp}
            DrillUpAllCamp={props.DrillUpAllCamp}
            DrillDownCamp={props.DrillDownCamp}
            DrillDownAllCamp={props.DrillDownAllCamp}
            DrillUpCamp={props.DrillUpCamp}
          />
        );
      case "tab4":
        return <LineChart lineData={props.lineData} />;
      case "tab5":
        return <FunnelChart funnelChartData={props.funnelChartData} />;
      case "tab6":
        return (
          <MatchStackChartContainer
            matchStackData={props.matchStackData}
            DrillDownMatch={props.DrillDownMatch}
            DrillAllMatch={props.DrillAllMatch}
            ResetAllMatch={props.ResetAllMatch}
            DrillDownAllMatch={props.DrillDownAllMatch}
            DrillUpAllMatch={props.DrillUpAllMatch}
          />
        );
      case "tab7":
        return <Funnel funnelData={props.funnelData} />;
      case "tab8":
        return <IndiaMap view={"state"} stateData={props.mapData} />
      case "tab9":
        return <Funnel funnelData={props.funnelOrganicData} />;
      default:
        return null;
    }
  };

  return <Box sx={{ height: "90%" }}>{returnChart(props.activeTab)}</Box>;
};

const Tabs = (props) => {
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
  return (
    <Box sx={{ height: "100%" }}>
      {props.tabNames && (
        <Stack
          gap={"1%"}
          height={"100%"}
          style={
            {
              // border:"1px solid red"
            }
          }
        >
          <Stack
            direction="row"
            justifyContent={isSmall || isMedium ? " space-between" : "space-between"}
            alignItems={"center"}
            // flexWrap={"wrap"}
            // gap={5}
            height={isSmall || isMedium ? "10" : "9%"}
            sx={{ backgroundColor: "#3E5056" }}
          >
            {props.tabNames.map((tab, index) => {
              return (
                <>
                  {tab.flag ? (
                    <Typography
                      key={index}
                      onClick={() => props.setActiveTab(tab.tab_short)}
                      sx={{
                        padding: "0.15rem 0.45rem",
                        cursor: "pointer",
                        fontFamily: "Inter, sans-serif",
                        color:
                          props.activeTab === tab.tab_short
                            ? "#d6ff41"
                            : "#eff2f7",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.85rem",
                          textAlign: "center",
                          textDecoration:
                            props.activeTab === tab.tab_short
                              ? "underline"
                              : "none",
                        }}
                      >
                        {tab.tab_name}
                      </Typography>
                    </Typography>
                  ) : null}
                </>
              );
            })}
          </Stack>
          <Tab {...props} />
        </Stack>
      )}
    </Box>
  );
};

export default React.memo(Tabs);
