const findAllChangedParents = (userInputKey, flatObject) => {
  let VALUE; // for user input key --> l-hco-gar-trad-tv
  let brandId; // flatObj[0] l-hco-gar-trad-tv --> l-hco-gar
  let brandList; // [l-hco, l]
  let channelList; // [trad-tv, trad]
  let changedParentForUserInputKey = []; //which parent is changed for a userinputkey

  Object.keys(flatObject).forEach((item) => {
    if (item === userInputKey) {
      VALUE = flatObject[item];
      brandId = VALUE[0];
      brandList = brandId.split("_");
    }
  });

  const generateParentBrandNames = (brandId) => {
    const parts = brandId?.split("_");
    const result = [];

    for (let i = 1; i < parts?.length; i++) {
      const brandName = parts?.slice(0, parts.length - i).join("_");
      result.push(brandName);
    }

    return result;
  };

  brandList = generateParentBrandNames(brandId);
  // console.log("brandlist", brandList);

  const findChannelParent = (foundKey) => {
    let str = foundKey.replace(`${brandId}_`, "");
    const parts = str.split("_");
    const output = [];

    for (let i = parts.length - 1; i > 0; i--) {
      const subArray = parts.slice(0, i);
      output.push(subArray.join("_"));
    }

    return output;
  };

  channelList = findChannelParent(userInputKey);
  const channelID = userInputKey.replace(`${brandId}_`, "");
  channelList.unshift(channelID);
  brandList.unshift(brandId);

  let allParentsForUserInputKey = []; // all parents  for userinputkey

  // code for find the all parents for userinputkey....start........
  brandList?.forEach((brand) => {
    // console.log("brand", brand);
    channelList?.forEach((channel) => {
      // console.log("channel", channel);
      let str = `${brand}_${channel}`;
      Object.keys(flatObject).forEach((item) => {
        if (item === str && str !== userInputKey) {
          allParentsForUserInputKey.push(item);
        }
      });
    });
  });

  allParentsForUserInputKey?.forEach((parent) => {
    // console.log("ppppp", parent)
    Object.keys(flatObject).forEach((innerkey) => {
      if (innerkey === parent) {
        if (flatObject[innerkey][3] === true) {
          // console.log("changed parent", parent);
          changedParentForUserInputKey.push(parent);
        }
      }
    });
  });

  return changedParentForUserInputKey;
};

export default findAllChangedParents;
