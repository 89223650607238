import React from 'react'

const AllCampaignFilter = (props) => {
  // console.log("props.CompActiveFilters: ", props.CompActiveFilters);
  // console.log("props.CompAllFilters: ", props.CompAllFilters);

  const bgColor = (node) => {
    if (props.Chartdata?.length === 1) {
      if (props.Chartdata[0].hasOwnProperty(node)) {
        if (props.Chartdata[0][node].Filter?.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    } else if (props.Chartdata?.length === 2) {
      if (props.Chartdata[0].hasOwnProperty(node)) {
        if (props.Chartdata[0][node].Filter?.length > 0) {
          return true;
        } else {
          if (props.Chartdata[1].hasOwnProperty(node)) {
            if (props.Chartdata[1][node].Filter?.length > 0) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }
  };


  return (
    <div className="col-xl-5">
      <div className="card my-1">
        <div className="card-body allFilter">
          <h6 className="m-0 text-center cm-filter-title" >Channel Filter</h6>
          <ul id="scroller" className="p-1 mx-1">
            {
              props.CompActiveFilters.map((item, index) => {
                return (
                  <li key={index} className={false ? "cm-filter-option-li-filter-active" : "cm-filter-option-li"} onClick={() => props.RemoveCompFilter(item)}>
                    <span className="btn cm-drag-btn p-1 mx-2 active">
                      <p className="m-0 text-left px-1">{item.name}</p>
                      <p className="m-0 px-1">
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </p>
                    </span>
                  </li>
                )
              })
            }
            {
              props.CompAllFilters.map((item, index) => {
                return (
                  (!props.IsInAllCompFilters(item)) ? <li key={index} className="cm-filter-option-li" onClick={() => props.AddCompFilter(index)}>
                    <span className="btn cm-drag-btn p-1 mx-2">
                      <p className="m-0 text-left px-1">{item.name}</p>
                      <p className="m-0 px-1">
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </p>
                    </span>
                  </li> : null
                )
              })
            }
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AllCampaignFilter