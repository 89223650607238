import { React, useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import HC_more from "highcharts/highcharts-more";
import CustomEvents from "highcharts-custom-events";
import axiosChartDownload from "../../axiosChartDownload";
import { toast } from "react-toastify";
import domtoimage from "dom-to-image";

// import HighchartsGroupedCategories from "highcharts-grouped-categories";
// HighchartsGroupedCategories(Highcharts);
CustomEvents(Highcharts);
HC_more(Highcharts);

const Waterfall = (props) => {
  const refChart = useRef()

  const [Cursor, setCursor] = useState('pointer')
  const [Cat, setCat] = useState(props.Categories)
  const [Height, setHeight] = useState('')
  const [Width, setWidth] = useState('')
  // console.log("props.Categories: ", props.Categories);
  // console.log("props.WaterfallData: ", props.WaterfallData);

  // useEffect(() => {
  //   if (props.Downloadchart) {
  //       const chartContainer = document.getElementById("growth_driver_chart");
  //       domtoimage
  //         .toPng(chartContainer)
  //         .then(function (dataUrl) {
  //           var string = "";
  //           for (var i = 0; i < props.BreadCrumbs.length; i++) {
  //             string += props.BreadCrumbs[i];
  //             if (i !== props.BreadCrumbs.length-1) {
  //               string += ",";
  //             }
  //           }
      
  //           props.setLoader(true);
  //           axiosChartDownload.post("download_chart/create-image/",
  //             {
  //               "title": "Growth Drivers",
  //               "filter": props.Brand,
  //               "hierarchy": string,
  //               "timeframe": props.Timeframe,
  //               "start_date": "",
  //               "end_date": "",
  //               "base64_image": dataUrl.split(",")[1],
  //               theme:localStorage.getItem("CurrentTheme")
    
  //             }
    
  //           ).then((response) => {
  //             if (response.data.error === 1) {
  //               toast.error(response.data.erroMsg);
  //               props.setLoader(false);
  //             } else {
  //               var link = document.createElement("a");
  //               link.target = "_blank";
  //               link.href = response.data.data.combined_graph_url;
  //               document.body.appendChild(link);
  //               link.click();
  //               document.body.removeChild(link);
  //               props.setLoader(false);
  //             }
  //           }).catch((data) => {
  //             props.setLoader(false);
  //           });
  //         })
  //         .catch(function (error) {
  //           props.setLoader(false)
  //           console.error("Error while generating chart image:", error);
  //         });
  //     props.setDownloadchart(false)
  //   }
  // }, [props])


  window.addEventListener("load", (event) => {
    setHeight(window.innerHeight)
    setWidth(window.innerWidth)
  });

  window.addEventListener('resize', function (event) {
    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(function () {
      if (Height !== window.innerHeight || Width !== window.innerWidth) {
        setCat([])
        setTimeout(() => {
          setHeight(window.innerHeight)
          setWidth(window.innerWidth)
          setCat(props.Categories)
        }, 500);
      }
    }, 250);
  });

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "waterfall",
      backgroundColor: 'rgba(0,0,0,0)',
      // backgroundColor:  localStorage.getItem('CurrentTheme') === "Dark" ? "#1c2427" : "",
      height: props.height,
    },
    title: {
      text: "",
      style: {
        color: "#D6FF41",
        fontSize: "14px",
      },
    },
    xAxis: {
      categories: Cat,
      title: {
        text: props.xtitle,
        style: {
          color: "#D6FF41",
        },
      },
      labels: {
        useHTML: true,
        formatter: function () {
          // setTest(!Test)
          let fulltext = "";
          if (typeof this.value === "string") {
            const SeriesList = [];
            function chnnellist(list) {
              if (list.name.split(",")[0] !== "") {
                SeriesList.push(list.name.split(",")[0]);
              }
              if ("parent" in list && list.parent !== undefined) {
                chnnellist(list.parent);
              }
            }
            chnnellist(this.axis.categories[this.pos]);

            // console.log("SeriesList: ", SeriesList);

            const val = this.value.split(",");
            let title = SeriesList.reverse().join("-");
            title = title.substring(0, title.indexOf(val[0]) + val[0].length);

            let short = "";
            if (val[1]) {
              if (val[1] === "-1") {
                short = val[0];
              } else if (val[1] === "0") {
                short =
                  val[0] !== "" && val[0].length > 5
                    ? val[0].slice(0, 5) + ".."
                    : val[0];
              } else {
                short =
                  val[0] !== "" && val[0].length > 5
                    ? val[0].slice(0, 5) + ".."
                    : val[0];
              }
            } else {
              short = val[0];
            }
            return (

              localStorage.getItem('CurrentTheme') === "Dark" ? '<div class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title=' +
                title +
                ">" +
                short +
                "</div>" :
                '<div class="cursor-pointer" style="color:#1C2427; background-color:white; z-index:99999" title=' +
                title +
                ">" +
                short +
                "</div>"

            );
          } else {
            return (

              localStorage.getItem('CurrentTheme') === "Dark" ?
                '<div class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title=' +
                fulltext +
                ">" +
                this.value +
                "</div>"
                :
                '<div class="cursor-pointer" style="color:black; background-color:white; z-index:99999" title=' +
                fulltext +
                ">" +
                this.value +
                "</div>"
            );
          }
        },
        rotation: 0,
        style: {
          color: "#FFFFFF",
        },
        events: {
          click: function () {
            props.DrillUp(this);
          },
        },
      },
    },
    yAxis: {
      min: props.WaterfallData.length > 0 ? Math.floor(props.WaterfallData[0].y) - 15 : 0,
      gridLineColor: "#7d9ca6",
      gridLineDashStyle: "ShortDash",
      visible: true,
      lineWidth: 1,
      title: {
        text: props.ytitle,
        style: {
          color: "#D6FF41",
        },
      },
      labels: {
        formatter: function () {
          return this.value + "%";
        },
        style: {
          color: localStorage.getItem('CurrentTheme') === "Dark" ? "#FFFFFF" : "#1C2427",
        },
      },
    },
    tooltip: {
      formatter: function () {
        var label = this.point.id
        function removeDash(label) {
          label = label.slice(0, -1);
          return label[label.length - 1] === "-" ? removeDash(label) : label;
        };

        if (label[label.length - 1] === "-") {
          label = removeDash(label);
        }

        // return label + " " + this.y.toFixed(2) + "%";
        return '<b>' + label + '</b>' +
                " " + this.point.abs_y + props.Unit + '<br/>' + 
               "Spends: " + this.point.spends;
      },
      distance: 25,
      shared: false,
      useHTML: false,
      borderColor: "#ffffff",
      backgroundColor: "#ffffff",
      borderRadius: 8,
      hideDelay: 0
    },
    plotOptions: {
      series: {
        stickyTracking: true,
        cursor: Cursor,
        point: {
          events: {
            // click: function () {
            //   props.DrillDown(this);
            // },
            click: function () {
              const SeriesList = [];
              function chnnellist(list) {
                if (list.name.split(",")[0] !== "") {
                  SeriesList.push(list.name.split(",")[0]);
                }
                if ("parent" in list && list.parent !== undefined) {
                  chnnellist(list.parent);
                }
              }
              chnnellist(this.category);

              let label = SeriesList.reverse().join("-");
              // console.log("label: ", label);

              if (!props.NotAllow.includes(label)) {
                // props.DrillDown(this);
                props.DrillDown(label);
              }
            },
            mouseOver: function (e) {
              var label = e.target.id

              function removeDash(label) {
                label = label.slice(0, -1);
                return label[label.length - 1] === "-" ? removeDash(label) : label;
              };

              if (label[label.length - 1] === "-") {
                label = removeDash(label);
              }

              // window.document.getElementsByTagName('body')[0].style.cursor = "pointer"
              if ((props.NotAllow.includes(label))) {
                // window.document.getElementsByTagName('body')[0].style.cursor = "not-allowed"
                setCursor('not-allowed')
              } else {
                setCursor('pointer')
              }

              props.dataplotRollOverHandler(label);
            },
            mouseOut: function () {
              // window.document.getElementsByTagName('body')[0].style.cursor = "default"
            }
          },
        },
      },
      waterfall: {
        animation: false,
        borderColor: "none",
        dataLabels: {
          headerFormat: '<div style="padding:0;background-color:#ffffff;color:#000000;"><table>',
          pointFormat: '<td style="padding:0;"><b>{point.y:.2f}%</b></td></tr>',
          footerFormat: "</table></div>",
          enabled: true,
          verticalAlign: "top",
          color: "#ffffff",
          align: "center",
          x: 0,
          y: -20,
        },
      },
    },
    legend: {
      enabled: false,
      itemHoverStyle: {
        color: "#ffffff",
      },
      itemStyle: {
        color: "#ffffff",
      },
      symbolRadius: 0,
    },
    series: [{ data: props.WaterfallData }],
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          enabled: false,
          className: "highcharts-contextbutton",
          symbol: "menu",
          symbolStroke: "#ffffff",
          theme: {
            fill: "#1c2427",
          },
          titleKey: "",
          menuItems: ["viewFullscreen"],
        },
      },
    },
  };
  return (
    <>

      {Cat.length > 0 ?
        <div id="growth_driver_chart">
          {
            props.height ?
              <HighchartsReact ref={refChart} highcharts={Highcharts} options={options} />
              : null
          }
        </div>
        : null}
    </>

  );
};

export default Waterfall;
