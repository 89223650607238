// import { React, useEffect, useRef, useState } from "react";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
// // import axiosChartDownload from "../../axiosChartDownload";
// // import { toast } from "react-toastify";
// // import domtoimage from "dom-to-image";
// import { useMediaQuery, useTheme } from "@mui/material";

// const CampaignChart = (props) => {
//   // console.log(
//   //   "divWidth: ",
//   //   props.divWidth,
//   //   props.campData.series[0].data.length
//   // );
//   const [isClicked, setisClicked] = useState(false);
//   const themes = useTheme();
//   const isMedium = useMediaQuery(themes.breakpoints.down("md"));
//   const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
//   const refChart = useRef(null);

//   // console.log("props.height: ", props.height);

//   // useEffect(() => {
//   //   const chart = refChart.current.chart;
//   //   if (chart) chart.reflow();

//   //   // return () => chart.destroy();
//   // }, [props.campData]);
//   const calculateWidth = (categories, containerWidth, minColumnWidth) => {
//     // by a column
//     let converedWidthByColumns = minColumnWidth * categories + categories * 50;

//     // console.log("result: ", Math.max(containerWidth, converedWidthByColumns));
//     return Math.max(containerWidth, converedWidthByColumns);
//   };

//   // useEffect(() => {
//   //   calculateWidth(props.campData.series[0].data.length, props.divWidth, 40);
//   // }, [props.campData, props.divWidth]);

//   const options = {
//     chart: {
//       zoomType: "xy",
//       type: "column",
//       // backgroundColor: "rgba(0,0,0,0)",
//       backgroundColor: "#1c2427",
//       // backgroundColor: localStorage.getItem('CurrentTheme') === "Dark" ? "#1c2427" : "",
//       showAxes: true,
//       height: props.height - 36,
//       resetZoomButton: {
//         theme: {
//           fill: "",
//           stroke: "#d6ff41",
//           style: {
//             color: "white",
//           },
//         },
//       },
//     },

//     rangeSelector: {
//       selected: 1,
//     },

//     title: {
//       text: "",
//     },
//     credits: {
//       enabled: false,
//     },
//     subtitle: {
//       text: "",
//     },
//     xAxis1: {
//       categories: props.campData.categories,

//       title: {
//         text: props.Xtitle,
//         style: {
//           color: "#FFFFFF",
//         },
//       },
//       crosshair: false,
//       labels: {
//         useHTML: true,
//         formatter: function () {
//           let fulltext = "";
//           if (typeof this.value === "string") {
//             const SeriesList = [];
//             function chnnellist(list) {
//               if (list.name.split(",")[0] !== "") {
//                 SeriesList.push(list.name.split(",")[0]);
//               }
//               if ("parent" in list && list.parent !== undefined) {
//                 chnnellist(list.parent);
//               }
//             }
//             chnnellist(this.axis.categories[this.pos]);

//             const val = this.value.split(",");
//             // console.log("val: ", val);
//             let title = SeriesList.reverse().join("-");
//             // title = title.substring(0, title.indexOf(val[0]) + val[0].length);

//             let short = "";
//             short = val[0];
//             // console.log("short: ", short);
//             // if (val[1]) {
//             //   if (val[1] === "-1") {
//             //     short = val[0];
//             //   } else if (val[1] === "0") {
//             //     short =
//             //       val[0] !== "" && val[0].length > 3
//             //         ? val[0].slice(0, 110) + "."
//             //         : val[0];
//             //   } else {
//             //     short =
//             //       val[0] !== "" && val[0].length > 3
//             //         ? val[0].slice(0, 110) + "."
//             //         : val[0];
//             //   }
//             // } else {
//             //   short = val[0];
//             // }
//             return localStorage.getItem("CurrentTheme") === "Dark"
//               ? '<div class="cursor-pointer" style="height: 80px;background-color:#1C2427;z-index:99999;position:relative;bottom:10;" title=' +
//                   title +
//                   ">" +
//                   short +
//                   "</div>"
//               : '<div class="cursor-pointer" style="height: 80px;color:#2f3c41; background-color:white; z-index:99999;position:relative;bottom:10" title=' +
//                   title +
//                   ">" +
//                   short +
//                   "</div>";
//           } else {
//             return localStorage.getItem("CurrentTheme") === "Dark"
//               ? '<div class="cursor-pointer" style="height: 80px;background-color:#1C2427;z-index:99999;position:relative;bottom:10" title=' +
//                   fulltext +
//                   ">" +
//                   this.value.name.split(",")[0] +
//                   "</div>"
//               : '<div class="cursor-pointer" style="height: 80px;color:#2f3c41; background-color:white; z-index:99999;position:relative;bottom:10" title=' +
//                   fulltext +
//                   ">" +
//                   this.value.name.split(",")[0] +
//                   "</div>";
//           }
//         },
//         rotation: -80,
//         style: {
//           color: "#FFFFFF",
//         },
//         events: {
//           click: function () {
//             props.DrillUpCamp(this);
//           },
//         },
//       },
//     },

//     xAxis: {
//       categories: props.campData.categories,
//       title: {
//         text: props.Xtitle,
//         style: {
//           color: "#FFFFFF",
//         },
//       },
//       crosshair: false,
//       labels: {
//         useHTML: true,
//         formatter: function () {
//           const getValuePath = (value, list = []) => {
//             // console.log("value: ", value);
//             if (value && typeof value === "object") {
//               list.unshift(value.name);
//               if (value.parent) {
//                 getValuePath(value.parent, list);
//               }
//             } else if (typeof value === "string") {
//               list.unshift(value);
//             }
//             return list;
//           };

//           let title = "";

//           if (typeof this.value === "string") {
//             const SeriesList = [];
//             function chnnellist(list) {
//               // console.log("list: ", list);
//               if (list.name.split(",")[0] !== "") {
//                 SeriesList.push(list.name.split(",")[0]);
//               }
//               if ("parent" in list && list.parent !== undefined) {
//                 chnnellist(list.parent);
//               }
//             }
//             chnnellist(this.axis.categories[this.pos]);

//             // console.log("SeriesList: ", SeriesList);

//             title = SeriesList.reverse().join("-");
//           }

//           const path = getValuePath(this.value);
//           // console.log("path: ", path);
//           const label = path.join("-").split(",")[0];

//           return `<div class="cursor-pointer" title="${title}" style="color: #FFFFFF; background-color: #1C2427; z-index: 99999;">${label}</div>`;
//         },

//         rotation: 0,
//         style: {
//           color: "#FFFFFF",
//         },
//         events: {
//           click: function () {
//             props.DrillUpCamp(this);
//           },
//         },
//       },
//     },

//     yAxis: {
//       gridLineWidth: 0,
//       // min: 0,
//       min: null,
//       max: null,
//       gridLineColor: "#7d9ca6",
//       gridLineDashStyle: "ShortDash",
//       visible: true,
//       lineWidth: 1,
//       title: {
//         text: props.campData.ytitle,
//         style: {
//           color:
//             localStorage.getItem("CurrentTheme") === "Dark"
//               ? "#FFFFFF"
//               : "#1C2427",
//         },
//       },
//       labels: {
//         style: {
//           color:
//             localStorage.getItem("CurrentTheme") === "Dark"
//               ? "#FFFFFF"
//               : "#1C2427",
//         },
//       },
//     },
//     // tooltip: {
//     //   formatter: function () {
//     //     const SeriesList = [];
//     //     function chnnellist(list) {
//     //       if (list.name.split(",")[0] !== "") {
//     //         SeriesList.push(list.name.split(",")[0]);
//     //       }
//     //       if ("parent" in list && list.parent !== undefined) {
//     //         chnnellist(list.parent);
//     //       }
//     //     }
//     //     chnnellist(this.x);

//     //     let label = SeriesList.reverse().join("-");
//     //     return label + " " + this.y.toFixed(2) + "";
//     //   },

//     //   shared: true,
//     //   useHTML: false,
//     //   borderColor: "#ffffff",
//     //   backgroundColor: "#ffffff",
//     //   borderRadius: 8,
//     // },

//     tooltip: {
//       formatter: function () {
//         const SeriesList = [];
//         function chnnellist(list) {
//           if (list.name.split(",")[0] !== "") {
//             SeriesList.push(list.name.split(",")[0]);
//           }
//           if ("parent" in list && list.parent !== undefined) {
//             chnnellist(list.parent);
//           }
//         }
//         chnnellist(this.x);
//         // console.log("tooltip: ", this);

//         let label = SeriesList.reverse().join("-");
//         return label + " " + this.y.toFixed(2) + "%";
//         // var series = this.series;
//         // var spends = series.userOptions.spends[this.point.index];
//         // var contribution = series.userOptions.contribution[this.point.index];
//         // return '<b>' + label + '</b><br/>' +
//         //        series.name + ': ' + this.y + '<br/>' +
//         //        'Spends: ' + spends + '<br/>' +
//         //        'Contribution: ' + contribution;
//       },
//       borderColor: "#ffffff",
//       backgroundColor: "#ffffff",
//       borderRadius: 8,
//     },

//     plotOptions: {
//       column: {
//         pointPadding: 0,
//         pointWidth: null,
//         borderWidth: 0,
//         dataLabels: {
//           enabled: props.full ? true : false,
//           allowOverlap: true,
//           format: "{point.y:.2f}%",
//         },
//       },
//       series: {
//         // cursor: 'pointer',
//         dataLabels: {
//           enabled: false,
//         },
//         point: {
//           events: {
//             click: function () {
//               const SeriesList = [];
//               function chnnellist(list) {
//                 if (list.name.split(",")[0] !== "") {
//                   SeriesList.push(list.name.split(",")[0]);
//                 }
//                 if ("parent" in list && list.parent !== undefined) {
//                   chnnellist(list.parent);
//                 }
//               }
//               chnnellist(this.category);

//               let label = SeriesList.reverse().join("-");
//               // console.log("label: ", label);

//               if (!props.campData.nonclicklist.includes(label)) {
//                 // props.DrillDown(this);
//                 props.DrillDownCamp(label);
//               }
//             },

//             mouseOver: function () {
//               const SeriesList = [];
//               function chnnellist(list) {
//                 if (list.name.split(",")[0] !== "") {
//                   SeriesList.push(list.name.split(",")[0]);
//                 }
//                 if ("parent" in list && list.parent !== undefined) {
//                   chnnellist(list.parent);
//                 }
//               }
//               chnnellist(this.category);

//               let label = SeriesList.reverse().join("-");

//               window.document.getElementsByTagName("body")[0].style.cursor =
//                 "pointer";
//               if (props.campData.nonclicklist.includes(label)) {
//                 window.document.getElementsByTagName("body")[0].style.cursor =
//                   "not-allowed";
//               }
//             },
//             mouseOut: function () {
//               window.document.getElementsByTagName("body")[0].style.cursor =
//                 "default";
//             },
//           },
//         },
//       },
//     },
//     legend: {
//       enabled: false,
//       layout: "horizontal", // Set the layout to vertical
//       align: "right", // Align the legend to the right
//       verticalAlign: "top", // Center the legend vertically

//       itemHoverStyle: {
//         color:
//           localStorage.getItem("CurrentTheme") === "Dark"
//             ? "#FFFFFF"
//             : "#1C2427",
//       },
//       itemStyle: {
//         color:
//           localStorage.getItem("CurrentTheme") === "Dark"
//             ? "#FFFFFF"
//             : "#1C2427",
//       },
//       symbolRadius: 0,
//     },
//     series: props.campData.series,
//     exporting: {
//       enabled: false,
//       buttons: {
//         contextButton: {
//           className: "highcharts-contextbutton",
//           enabled: true,
//           symbol: "download",
//           symbolStroke: "#ffffff",
//           theme: {
//             fill: "#1c2427",
//           },
//           titleKey: "",
//         },
//       },
//     },
//   };
//   return (
//     <div
//       onClick={() => {
//         // setisClicked(!isClicked);
//       }}
//     >
//       {!isClicked && (isSmall || isMedium) ? (
//         <HighchartsReact
//           ref={refChart}
//           highcharts={Highcharts}
//           options={options}
//           {...props}
//         />
//       ) : (
//         <div
//           id="roi_chart1"
//           style={{
//             height: "100%",
//             width: isMedium || isSmall ? "700px" : "",
//             overflow: isMedium || isSmall ? "scroll" : "scroll",
//           }}
//         >
//           <HighchartsReact
//             ref={refChart}
//             highcharts={Highcharts}
//             options={options}
//             {...props}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default CampaignChart;

// // calculateWidth(
// //   props.campData.series[0].data.length,
// //   props.divWidth,
// //   40
// // )
// //

// #####################

// import React, { useRef, useEffect } from "react";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";

import { Stack, Box, useTheme, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useLayoutEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsAccessibility from "highcharts/modules/accessibility";
// Initialize Highcharts modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

const LTSTROI = (props) => {
  const [isClicked, setisClicked] = useState(false);
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  const refChart = useRef(null);

  const divRef = useRef(null);

  const calculateWidth = (categories, containerWidth, minColumnWidth) => {
    // by a column
    let converedWidthByColumns = minColumnWidth * categories + categories * 50;

    // console.log("result: ", Math.max(containerWidth, converedWidthByColumns));
    return Math.max(containerWidth, converedWidthByColumns);
  };

  useEffect(() => {
    const chart = divRef.current.chart;
    if (chart) chart.reflow();

    // return () => chart.destroy();
  }, [props.campData]);

  useEffect(() => {
    Highcharts.chart(divRef.current, {
      chart: {
        zoomType: "xy",
        type: "column",
        backgroundColor: "#1c2427",
        showAxes: true,
        height: props.height - 56,
        resetZoomButton: {
          theme: {
            fill: "",
            stroke: "#d6ff41",
            style: {
              color: "white",
            },
          },
        },
      },

      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        categories: props.campData.categories,
        title: {
          text: props.Xtitle,
          style: {
            color: "#FFFFFF",
          },
        },
        crosshair: false,
        labels: {
          useHTML: true,
          formatter: function () {
            const getValuePath = (value, list = []) => {
              // console.log("value: ", value);
              if (value && typeof value === "object") {
                list.unshift(value.name);
                if (value.parent) {
                  getValuePath(value.parent, list);
                }
              } else if (typeof value === "string") {
                list.unshift(value);
              }
              return list;
            };

            let title = "";

            if (typeof this.value === "string") {
              const SeriesList = [];
              function chnnellist(list) {
                // console.log("list: ", list);
                if (list.name.split(",")[0] !== "") {
                  SeriesList.push(list.name.split(",")[0]);
                }
                if ("parent" in list && list.parent !== undefined) {
                  chnnellist(list.parent);
                }
              }
              chnnellist(this.axis.categories[this.pos]);

              // console.log("SeriesList: ", SeriesList);

              title = SeriesList.reverse().join("-");
            }

            const path = getValuePath(this.value);
            // console.log("path: ", path);
            const label = path.join("-").split(",")[0];

            return `<div class="cursor-pointer" title="${title}" style="color: #FFFFFF; background-color: #1C2427; z-index: 99999;">${label}</div>`;
          },

          rotation: props.full
            ? 0
            : props.campData.series[0].data.length > 3
            ? -45
            : 0,
          style: {
            color: "#FFFFFF",
          },
          events: {
            click: function () {
              props.DrillUpCamp(this);
            },
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        // min: 0,
        min: null,
        max: null,
        gridLineColor: "#7d9ca6",
        gridLineDashStyle: "ShortDash",
        visible: true,
        lineWidth: 1,
        title: {
          text: props.campData.ytitle,
          style: {
            color:
              localStorage.getItem("CurrentTheme") === "Dark"
                ? "#FFFFFF"
                : "#1C2427",
          },
        },
        labels: {
          style: {
            color:
              localStorage.getItem("CurrentTheme") === "Dark"
                ? "#FFFFFF"
                : "#1C2427",
          },
        },
      },

      tooltip: {
        formatter: function () {
          const SeriesList = [];
          function chnnellist(list) {
            if (list.name.split(",")[0] !== "") {
              SeriesList.push(list.name.split(",")[0]);
            }
            if ("parent" in list && list.parent !== undefined) {
              chnnellist(list.parent);
            }
          }
          chnnellist(this.x);
          // console.log("tooltip: ", this);

          let label = SeriesList.reverse().join("-");
          // return label + " " + this.y.toFixed(2) + "%";
          var series = this.series;
          // var spends = series.userOptions.spends[this.point.index];
          var contribution = series.userOptions.contribution[this.point.index];
          return '<b>' + label + '</b><br/>' +
                 series.name + ': ' + this.y + ' | ' +
                //  'Spends: ' + spends + '<br/>' +
                  contribution;
        },
        borderColor: "#ffffff",
        backgroundColor: "#ffffff",
        borderRadius: 8,
      },

      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0,
          dataLabels: {
            enabled: props.full ? true : false,
            allowOverlap: true,
            format: "{point.y:.2f}%",
          },
        },
        series: {
          // cursor: 'pointer',
          point: {
            events: {
              click: function () {
                const SeriesList = [];
                function chnnellist(list) {
                  if (list.name.split(",")[0] !== "") {
                    SeriesList.push(list.name.split(",")[0]);
                  }
                  if ("parent" in list && list.parent !== undefined) {
                    chnnellist(list.parent);
                  }
                }
                chnnellist(this.category);

                let label = SeriesList.reverse().join("-");
                // console.log("label: ", label);

                if (!props.campData.nonclicklist.includes(label)) {
                  // props.DrillDown(this);
                  props.DrillDownCamp(label);
                }
              },

              mouseOver: function () {
                const SeriesList = [];
                function chnnellist(list) {
                  if (list.name.split(",")[0] !== "") {
                    SeriesList.push(list.name.split(",")[0]);
                  }
                  if ("parent" in list && list.parent !== undefined) {
                    chnnellist(list.parent);
                  }
                }
                chnnellist(this.category);

                let label = SeriesList.reverse().join("-");

                window.document.getElementsByTagName("body")[0].style.cursor =
                  "pointer";
                if (props.campData.nonclicklist.includes(label)) {
                  window.document.getElementsByTagName("body")[0].style.cursor =
                    "not-allowed";
                }
              },
              mouseOut: function () {
                window.document.getElementsByTagName("body")[0].style.cursor =
                  "default";
              },
            },
          },
        },
      },
      legend: {
        enabled: false,
        layout: "horizontal", // Set the layout to vertical
        align: "right", // Align the legend to the right
        verticalAlign: "top", // Center the legend vertically

        itemHoverStyle: {
          color:
            localStorage.getItem("CurrentTheme") === "Dark"
              ? "#FFFFFF"
              : "#1C2427",
        },
        itemStyle: {
          color:
            localStorage.getItem("CurrentTheme") === "Dark"
              ? "#FFFFFF"
              : "#1C2427",
        },
        symbolRadius: 0,
      },
      series: props.campData.series,
      exporting: {
        enabled: false,
        buttons: {
          contextButton: {
            className: "highcharts-contextbutton",
            enabled: true,
            symbol: "download",
            symbolStroke: "#ffffff",
            theme: {
              fill: "#1c2427",
            },
            titleKey: "",
          },
        },
      },
    });
  }, [props.campData, props.height, isClicked, props.full]);

  return (
    <div
      onClick={() => {
        // setisClicked(!isClicked);
      }}
    >
      {/* {!isClicked && (isSmall || isMedium) ? (
        <div
          id="container"
          ref={divRef}
          style={{
            height: isMedium || isSmall ? "400px" : "",

            // width: (isMedium||isSmall)?"700px":"",
            // overflow:(isMedium||isSmall)?"scroll":""
          }}
        ></div>
      ) : ( */}
        <div
          id="container"
          ref={divRef}
          style={{
            height: isMedium || isSmall ? "400px" : "",
            // border: "2px solid red",
            width:
             props.full
                ? calculateWidth(
                    props.campData.series[0].data.length,
                    props.divWidth,
                    40
                  )
                : "",
            //  width:"100%",
            overflowX: isMedium || isSmall ? "scroll" : "",
          }}
        ></div>
      {/* )} */}
    </div>
  );
};

export default LTSTROI;

//  calculateWidth(
//   props.campData.series[0].data.length,
//  props.divWidth,
//  40
//  )
