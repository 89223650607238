import React from "react";
import "./ChartSkeleton.css"; // External CSS for shimmer effect

const ChartSkeleton = () => {
  return (
    <div className="chart-skeleton-container">
      {/* Legend Skeleton */}
      <div className="legend-skeleton">
        {["Total Closure", "Total Leads", "Installs"].map((item, index) => (
          <div key={index} className="legend-item">
            <div className="legend-dot shimmer"></div>
            <div className="legend-text shimmer"></div>
          </div>
        ))}
      </div>

      {/* Grid Lines */}
      <div className="grid-lines">
        {[...Array(15)].map((_, i) => (
          <div key={i} className="grid-line"></div>
        ))}
      </div>

      {/* Shimmer Bars */}
      {/* <div className="chart-bars">
        {[...Array(8)].map((_, i) => (
          <div key={i} className="bar shimmer" style={{ height: `${30 + i * 5}px` }}></div>
        ))}
      </div> */}

      {/* X-Axis Labels */}
      {/* <div className="x-axis-labels">
        {[...Array(8)].map((_, i) => (
          <div key={i} className="x-label shimmer"></div>
        ))}
      </div> */}
    </div>
  );
};

export default ChartSkeleton;
