import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
// import axios from "axios";
import axios from "../../axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MultiSelect from "./MultiSelect";

import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Stack, Box, Typography, useTheme, useMediaQuery } from "@mui/material";

const UserAccessPopUp = (props) => {
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  const [loader, setLoader] = useState(false);

  // console.log("props.tabNames", props.tabNames);
  // console.log("props.UserAccess: ", props.UserAccess);

  const [firstName, setFirstName] = useState(props.UserAccess.firstname);
  const [lastName, setLastName] = useState(props.UserAccess.lastname);
  const [userName, setUserName] = useState(props.UserAccess.username);
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [ddate, setDDate] = useState(
    new Date(props.UserAccess.deactivation_date)
  );

  const MyContainer = ({ className, children }) => {
    return (
      <div style={{ padding: "16px", color: "#fff" }}>
        <CalendarContainer className={className}>
          <div style={{ background: "#ffff", color: "#ffff" }}></div>
          <div style={{ position: "relative", background: "#0000" }}>
            {children}
          </div>
        </CalendarContainer>
      </div>
    );
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const [payload, setPayload] = useState({});

  // console.log("payload: ", payload);

  const [firstNamePayload, setFirstNamePayload] = useState("");
  const [lastNamePayload, setLastNamePayload] = useState("");
  const [userNamePayload, setUserNamePayload] = useState("");
  const [passwordPayload, setPasswordPayload] = useState("");
  const [datePayload, setDatePayload] = useState("");

  useEffect(() => {
    setPayload({
      ...firstNamePayload,
      ...lastNamePayload,
      ...userNamePayload,
      ...passwordPayload,
      ...datePayload,
      ...props.tabPayload,
    });
  }, [
    firstNamePayload,
    lastNamePayload,
    userNamePayload,
    passwordPayload,
    datePayload,
    props.tabPayload,
  ]);

  const HandleSubmit = (id) => {
    const formattedDate = ddate.toISOString().slice(0, 10);

    if (password === cPassword) {
      setLoader(true);
      axios
        .post("/update_details/", {
          // username: userName,
          // firstname: firstName,
          // lastname: lastName,
          // password: password,
          // tabaccess: props.selectedOptionsTabs,
          // deactivation_date: formattedDate,
          username: userName,
          ...payload,
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
          } else {
            props.setUserAccess({});
            props.setGetData(true);
            props.setSelectedOptionsTabs([]);
          }
          setLoader(false);
        });
    }
  };

  return (
    <>
      {loader ? <Loader /> : null}
      <div
        className="modal fade bs-example-modal-center show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
        style={{
          display: "block",
          // border:"2px solid red"
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: isSmall || isMedium ? "100%" : "40%" }}
        >
          <div
            className="modal-content"
            style={{
              width: "100%",
              //  border:"2px solid green"
            }}
          >
            <div className="modal-body modal-body-custom custom-summary">
              {/* form */}
              <div style={{ width: "100%" }}>
                <div className="row align-items-center ">
                  <div className="col-sm-5 ">
                    <h3 className="model-result-popup-h3 ">First Name :</h3>
                  </div>
                  <div className="col-sm-7 ">
                    <input
                      className="form-control text-transform-none"
                      style={{ border: "1px solid white", color: "white" }}
                      type="text "
                      placeholder="First name"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setFirstNamePayload({ firstname: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-sm-5"></div>
                  <div className="col-sm-7">
                    <span className="error">{props.FirstNameError}</span>
                  </div>
                </div>
                <div className="row align-items-center ">
                  <div className="col-sm-5 ">
                    <h3 className="model-result-popup-h3 ">Last Name :</h3>
                  </div>
                  <div className="col-sm-7 ">
                    <input
                      className="form-control text-transform-none"
                      style={{ border: "1px solid white", color: "white" }}
                      type="text "
                      placeholder="Last name"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setLastNamePayload({ lastname: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-sm-5"></div>
                  <div className="col-sm-7">
                    <span className="error">{props.LastNameError}</span>
                  </div>
                </div>
                <div className="row align-items-center ">
                  <div className="col-sm-5 ">
                    <h3 className="model-result-popup-h3 ">User Name :</h3>
                  </div>
                  <div className="col-sm-7 ">
                    <input
                      style={{ border: "1px solid white", color: "white" }}
                      disabled
                      className="form-control text-transform-none"
                      type="text "
                      placeholder="User name"
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                        setUserNamePayload({ username: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-sm-5"></div>
                  <div className="col-sm-7">
                    <span className="error">{props.UserNameError}</span>
                  </div>
                </div>
                <div className="row align-items-center ">
                  <div className="col-sm-5 ">
                    <h3 className="model-result-popup-h3 ">Password :</h3>
                  </div>
                  <div className="col-sm-7 ">
                    <input
                      style={{ border: "1px solid white", color: "white" }}
                      className="form-control text-transform-none"
                      type="password"
                      placeholder="Password"
                      autoComplete="new-password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordPayload({ password: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-sm-5"></div>
                  <div className="col-sm-7">
                    <span className="error">{props.PasswordError}</span>
                  </div>
                </div>
                <div className="row align-items-center ">
                  <div className="col-sm-5 ">
                    <h3 className="model-result-popup-h3 ">
                      Confirm Password :
                    </h3>
                  </div>
                  <div className="col-sm-7 ">
                    <input
                      style={{ border: "1px solid white", color: "white" }}
                      className="form-control text-transform-none"
                      type="password"
                      placeholder="Confirm Password"
                      value={props.cPassword}
                      onChange={(e) => setCPassword(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-5"></div>
                  <div className="col-sm-7">
                    <span className="error">{props.CPasswordError}</span>
                  </div>
                </div>

                <div className="row align-items-center ">
                  <div className="col-sm-5 ">
                    <h3 className="model-result-popup-h3 ">
                      Deactivation Date :
                    </h3>
                  </div>
                  <div className="col-sm-7 ">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={ddate}
                      onChange={(date) => {
                        setDDate(date);
                        setDatePayload({ deactivation_date: formatDate(date) });
                      }}
                      calendarContainer={MyContainer}
                      minDate={ddate}
                    />
                  </div>
                  <div className="col-sm-5"></div>
                  <div className="col-sm-7">
                    <span className="error">{props.DeactivationDateError}</span>
                  </div>
                </div>

                {/* new code */}
                <div className="row align-items-center ">
                  <div className="col-sm-5 ">
                    <h3 className="model-result-popup-h3 ">Tab Access :</h3>
                  </div>
                  <div className="col-sm-7 ">
                    <MultiSelect
                      setTabAccess={props.setTabAccess}
                      selectedOptionsTabs={props.selectedOptionsTabs}
                      AddUserMultiTabs={props.AddUserMultiTabs}
                      tabNames={props.tabNames}
                    />
                  </div>
                </div>
                {/* new code */}
              </div>
              {/* form */}
            </div>

            <div className="row mb-5">
              <div className="col-6 ">
                <button
                  className=" btn btn-modal-primary"
                  style={{
                    float: "right",
                    backgroundColor: "#d6ff41",
                    color: "black",
                  }}
                  onClick={(e) => {
                    HandleSubmit();
                  }}
                >
                  Save
                </button>
              </div>
              <div className="col-6">
                <button
                  className=" btn btn-modal-secondary"
                  style={{ backgroundColor: "#3E5056", color: "white" }}
                  data-bs-dismiss="modal"
                  onClick={() => {
                    props.setUserAccess({});
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAccessPopUp;
