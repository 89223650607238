import React, { useEffect } from "react";
import ColumnLineChart from "./ColumnLineChart";
import ToggleButton from "react-toggle-button";
import { Stack, Box, Typography, useTheme, useMediaQuery } from "@mui/material";

const ColumnLineContainer = (props) => {

    console.log("props.baseLineSeries ",props.baseLineSeries)

    return (
        <>
        <Stack
         direction={"row"}
         gap={1}
         justifyContent={"flex-end"}
         alignItems={"center"}
        >
         <Typography
                    sx={{
                      color: props.viewToggle ? "#d6ff41" : "white",
                      fontSize: "0.85rem",
                      // opacity: !props.toggle ? 1 : 0.3,
                    }}
                  >
                   Stack View
                  </Typography>
                  <ToggleButton
                    value={props.viewToggle}
                    inactiveLabel=""
                    activeLabel=""
                    onToggle={(value) => {
                        props.setViewToggle(!value);
                    }}
                    colors={{
                      activeThumb: {
                        base: "#d6ff41",
                      },
                      inactiveThumb: {
                        base: "#d6ff41",
                      },
                      active: {
                        base: "#3E5056",
                        // hover: "rgb(177, 191, 215)",
                      },
                      inactive: {
                        base: "#3E5056",
                        // hover: "rgb(95,96,98)",
                      },
                    }}
                  />
            </Stack>
            <ColumnLineChart
                isBaseLineLoading={props.isBaseLineLoading}
                baseLineSeries={
                    props.viewToggle
                ? props.baseLineSeries.filter(item => item.name !== 'Baseline' && item.name !== 'Total') 
                : props.baseLineSeries.filter(item => item.name !== 'Incremental')
                }
                baseLineData={props.baseLineData}
                toggle={props.viewToggle}
            />
        </>
    )

}


export default ColumnLineContainer;