import React, { useState } from 'react';
// import '../assets/ManualCSS/login.css'
import axios from "../axios";
import Loader from "../Components/Loader";
import BaseUrl from "../BaseUrl";
import LogoMark from "../images/logo.png";
import LavenderLogo from "../images/logo-c1.png";
import SapphireLogo from "../images/logo-c3.png";
import TealLogo from "../images/logo-c5.png";
import CyanLogo from "../images/logo-c7.png";
import GoldenrodLogo from "../images/logo-b4.png";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    setLoader(true);
    axios.post("forget_password/", {
      email: email
 
    })
    .then((response) => {
      if (response.data.error === 1) {
        setLoader(false);
      } else {
        setMessage(response?.data?.message);
        setLoader(false);
      }
    })
    .catch((error) => {
      setLoader(false);
      // Assuming the error response contains an error object in data
      const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
      setMessage(errorMessage);
    });
   
    setEmail('');
 
    // For demonstration purposes, we'll just display a success message
 
  };

  return (
    <>

      {loader ? <Loader /> : null}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',alignItems:'center',height:'100vh' }}>
        <div className="wrapper-page my-5"  >
          <div className="container-fluid p-0 m-0 max-w-90">
            <div className="card" style={{ borderRadius: '7px' }}>
              <div className="card-body " style={{ borderRadius: '7px' }}>
                <div className="text-center mt-3">
                  <div className="mb-2">
                    <img
                      src={
                        localStorage.getItem("CurrentTheme") !== null && localStorage.getItem("CurrentTheme") !== "null"
                          ? localStorage.getItem("CurrentTheme") === "Dark"
                            ? `${BaseUrl}/logo/logo-light.png?v=${new Date().getTime()}`
                            : `${BaseUrl}/logo/logo-dark.png`
                          : `${BaseUrl}/logo/logo-light.png`
                      }
                      alt="logo"
                      width="auto"
                      maxwidth="100%"
                      height='75px'
                      display='block'
                      margin='0 auto'
                      objectFit='contain'
                    />
                  </div>
                </div>
                <div className="p-3">
                  <form className="form-horizontal mt-2" onSubmit={handleSubmit}>
                    <div className="form-group row">
                      <div className="col-12">

                        <h3> <label className="login-lable" htmlFor="email">Enter Your Email Address</label></h3>
                        <input
                         className="form-control "
                          type="email"
                          id="email"
                          name="email"
                          autoComplete="off"
                          style={
                            {color:'white'}
                          }
                          placeholder='example@gmail.com'
                          
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          
                        />
                       
                      </div>
                      
                    </div>


                   
                    <div className="form-group mb-1 text-center row mt-3 pt-1">
                      <div className="col-12 " >
                        <button className="btn btn-info" type="submit">Send Reset Link</button>
                      </div>

                    </div>

                  </form>
                  {message && <p className="message" style={{ padding: '7px' }}>{message}</p>}
                </div>
                <div className="text-center">
                  <p className="mb-1 login-footer">
                    Powered by&nbsp;
                    <img
                      src={
                        localStorage.getItem("CurrentTheme") === "Dark"
                          ? LogoMark
                          : CyanLogo
                      }
                      alt="logo"
                      style={{ width: "25%" }}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="forgot-password-container ">

       
       
      </div> */}
    </>
  );
};

export default ForgotPassword;
