import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsAccessibility from "highcharts/modules/accessibility";

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

const WeatherChart = (props) => {
  // console.log("props.lineData: ", props.lineData);
  const [divHeight, setDivHeight] = useState("");
  // console.log("divHeight line chart: ", divHeight);

  const divRef = useRef(null);

  useLayoutEffect(() => {
    const updateDivHeight = () => {
      if (divRef.current) {
        const newHeight = divRef.current.clientHeight;
        setDivHeight(newHeight);
        // console.log("new height: ", newHeight);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);

  useEffect(() => {
    // Create the chart
    Highcharts.chart("container", {
      chart: {
        zoomType: "xy",
        type: "spline",
        height: divHeight,
        // marginTop: 50,
        backgroundColor: "black",
        // backgroundColor: "#1c2427", // Set your desired background color her
        resetZoomButton: {
          theme: {
            fill: "",
            stroke: "#d6ff41",
            style: {
              color: "white",
            },
          },
        },
      },
      title: {
        text: null,
      },

      xAxis: {
        labels: {
          style: {
            color: "#FFFFFF", // Set color for x-axis labels
          },
        },
        categories: props.lineData?.data?.chartdata?.xAxis?.Category, // X-axis categories
      },
      yAxis: {
        min:0,
        gridLineWidth: 0,
        title: {
          text: null, // Set Y-axis title to null
        },
        labels: {
          style: {
            color: "#FFFFFF", // Set color for Y-axis labels
          },
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      legend: {
        enabled: true,
        layout: "horizontal", // Set the layout to vertical
        align: "right", // Align the legend to the right
        verticalAlign: "top", // Center the legend vertically
        itemStyle: {
          color: "#FFFFFF", // Set the color of legend items
        },
        labelFormatter: function () {
          return this.userOptions.display_name || this.name;
        },
      },
      // tooltip: {
      //   shared: true,
      //   formatter: function () {
      //     return (
      //       "<b>" +
      //       (this.point.series.userOptions.display_name ||
      //         this.point.series.name) +
      //       "</b>: " +
      //       this.y
      //     );
      //   },
      // },
      tooltip: {
        shared: true,
        formatter: function () {
          var points = this.points || [];
          var tooltip =
            "<b>" + (points[0] ? points[0].x : "Category") + "</b><br/>";
          points.forEach(function (point) {
            tooltip +=
              '<span style="color:' +
              point.series.color +
              '">\u25CF</span> ' +
              (point.series.userOptions.display_name || point.series.name) +
              ": <b>" +
              point.y +
              "</b><br/>";
          });
          return tooltip;
        },
      },

      series: props.lineData?.data?.series,
    });
  }, [props.lineData, divHeight]); // Empty dependency array for mount/unmount behavior

  return (
    <div className="highcharts-figure" ref={divRef} style={{ height: "100%",
     }}>
      <div id="container" style={{ height: "100%" }}></div>
    </div>
  );
};

export default WeatherChart;
