import React, { useState } from 'react'
import Topbar from "../../Components/Topbar";
import { useNavigate } from 'react-router';
const UserProfile = () => {
    const navigate = useNavigate();
    const [ThemeColor, setThemeColor] = useState(localStorage.getItem('CurrentTheme') ? localStorage.getItem('CurrentTheme') : "Dark");
    const [ColorCode, setColorCode] = useState(localStorage.getItem('ColorCode') ? localStorage.getItem('ColorCode') : "Lavender");

    const HandleTheme = () => {
        localStorage.setItem('CurrentTheme', ThemeColor)
        if (ThemeColor === "Light") {
            localStorage.setItem('ColorCode', ColorCode)
        } else {
            localStorage.removeItem('ColorCode', ColorCode)
        }
        window.Change_Theme();

        navigate(-1);
    }

    return (
        <>
            <Topbar BrandName={""} />
            <div className="main-content">
                <div className="page-content user-pg">
                    <div className="container-fluid main-content-w">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                                    <h1 className="mb-sm-0 theme-color main-title">User Profile</h1>
                                </div>
                            </div>
                            <div className="col-sm-6" style={{ textAlign: "right" }}>
                            </div>
                        </div>
                    </div>
                    <div
                        className="container-fluid main-content-w"
                        style={{ paddingTop: "10px" }}
                    >
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <div className="card p-3">

                                    <h3>{localStorage.getItem("NameSkewb")}</h3>
                                    <hr>
                                    </hr>
                                    <h5>System theme</h5>
                                    <div className="row mt-2">
                                        <div className="col-sm-4">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Dark"
                                                    checked={ThemeColor === "Dark"}
                                                    onChange={(e) =>
                                                        setThemeColor(e.target.value)
                                                    }></input>
                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    Dark Theme (Default)
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Light"
                                                    checked={ThemeColor === "Light"}
                                                    onChange={(e) =>
                                                        setThemeColor(e.target.value)
                                                    }></input>
                                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                    Light Theme
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        ThemeColor === "Light" ?
                                            <>
                                                <div className='mt-3' style={{ display: "flex", flexWrap: "wrap" }}>

                                                    <div style={{ flex: "0 0 100px" }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="c-1" id="c-1" value="Lavender"
                                                                checked={ColorCode === "Lavender"}
                                                                onChange={(e) =>
                                                                    setColorCode(e.target.value)
                                                                }></input>
                                                            <label className="form-check-label" htmlFor="c-1">
                                                                Lavender
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "0 0 100px" }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="c-3" id="c-3" value="Teal"
                                                                checked={ColorCode === "Teal"}
                                                                onChange={(e) =>
                                                                    setColorCode(e.target.value)
                                                                }></input>
                                                            <label className="form-check-label" htmlFor="c-3">
                                                                Teal
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "0 0 100px" }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="c-4" id="c-4" value="Cyan"
                                                                checked={ColorCode === "Cyan"}
                                                                onChange={(e) => 
                                                                    setColorCode(e.target.value)                                                                
                                                                }></input>
                                                            <label className="form-check-label" htmlFor="c-4">
                                                                Cyan
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "0 0 100px" }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="c-5" id="c-5" value="Goldenrod"
                                                                checked={ColorCode === "Goldenrod"}
                                                                onChange={(e) =>
                                                                    setColorCode(e.target.value)
                                                                }></input>
                                                            <label className="form-check-label" htmlFor="c-5">
                                                                Goldenrod
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "0 0 100px" }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="c-2" id="c-2" value="Sapphire"
                                                                checked={ColorCode === "Sapphire"}
                                                                onChange={(e) =>
                                                                    setColorCode(e.target.value)
                                                                }></input>
                                                            <label className="form-check-label" htmlFor="c-2">
                                                                Sapphire
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="row mt-3">
                                                    <h6>Light theme Color</h6>
                                                    <div className="col-sm-2">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="c-1" id="c-1" value="Lavender"
                                                                checked={ColorCode === "Lavender"}
                                                                onChange={(e) =>
                                                                    setColorCode(e.target.value)
                                                                }></input>
                                                            <label className="form-check-label" htmlFor="c-1">
                                                                Lavender
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="c-3" id="c-3" value="Teal"
                                                                checked={ColorCode === "Teal"}
                                                                onChange={(e) =>
                                                                    setColorCode(e.target.value)
                                                                }></input>
                                                            <label className="form-check-label" htmlFor="c-3">
                                                                Teal
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="c-4" id="c-4" value="Cyan"
                                                                checked={ColorCode === "Cyan"}
                                                                onChange={(e) =>
                                                                    setColorCode(e.target.value)
                                                                }></input>
                                                            <label className="form-check-label" htmlFor="c-4">
                                                                Cyan
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="c-5" id="c-5" value="Goldenrod"
                                                                checked={ColorCode === "Goldenrod"}
                                                                onChange={(e) =>
                                                                    setColorCode(e.target.value)
                                                                }></input>
                                                            <label className="form-check-label" htmlFor="c-5">
                                                                Goldenrod
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="c-2" id="c-2" value="Sapphire"
                                                                checked={ColorCode === "Sapphire"}
                                                                onChange={(e) =>
                                                                    setColorCode(e.target.value)
                                                                }></input>
                                                            <label className="form-check-label" htmlFor="c-2">
                                                                Sapphire
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </>

                                            :
                                            null
                                    }
                                    <div className="row mt-3">
                                        <div className="col-sm-12 d-flex ">
                                            <button class="btn brand-btn-info custom-input-box" type="button" onClick={() => {
                                                HandleTheme()
                                            }}>Update Profile</button>
                                            {/* <button class="btn brand-btn-info custom-input-box mx-3 " type="button" onClick={() => {
                                                navigate(-1);
                                            }}>Back Page</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UserProfile