import Select from "react-select";

const SelectBrand = ({ setGetBrand, match }) => {
  // console.log("data: ", data);
  const data = JSON.parse(localStorage.getItem("allBrands"));
  // console.log("parsed data: ", data);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#3E5056" : "#3E5056",
      backgroundColor: "#1C2427",
      fontSize: "var(--regular-font-size)",
      boxShadow: "none",

      "&:hover": {
        borderColor: "#3E5056", // Change to the desired hover color
      },

      height: "26px",
      "min-height": "26px",
      // display: "flex",
      // alignItems: "center"
    }),

    menu: (provided) => ({
      ...provided,
      //   width: 400,
      borderRadius: 8,
      backgroundColor: "#3e5056",
      color: "white",
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white",
      marginTop: -6,
    }),

    valueContainer: (provided) => ({
      ...provided,
      // background: "blue", // Example background color
      // color: "red", // Example text color
      padding: "0 6px", // Example padding
      height: "22px",
      // display: "flex",
      // alignItems: "center"
      marginTop: -10
    }),

    option: (provided, state) => ({
      ...provided,
      //   width: 400,
      backgroundColor: state.isSelected ? "tomato" : "#3e5056",
      color: state.isSelected ? "white" : "white",
      // Custom style for disabled option
      opacity: state.isDisabled ? 0.7 : 1,
      cursor: state.isDisabled ? "not-allowed" : "default",
      ":hover": {
        backgroundColor: "#334155", // Change to the desired hover color
      },
      fontSize: "var(--regular-font-size)",
      height: "25px",
      display: "flex",
      alignItems: "center",
    }),

    singleValue: (base) => ({
      ...base,
      background: "#1C2427",
      // background: "red",
      color: "white",
      // display: "flex",
      // marginTop: -10,
    }),

    input: (provided) => ({
      ...provided,
      color: "white",
      display: "flex",
      // marginTop: -7,
    }),

   
  };

  const flatten = (data) => {
    const result = [
      {
        value: data?.id,
        label: data?.id,
        isDisabled: !data?.access,

        id: data?.id,
        name: data?.name,
        hirarchy_level: data?.hirarchy_level,
        img: data?.img,
      },
    ];

    if (data?.children?.length > 0) {
      data?.children?.forEach((item) => {
        result.push(...flatten(item));
      });
    }
    // console.log("result: ", result);
    return result;
  };
  const optionsData = flatten(data);

  return (
    <>
      <Select
        options={optionsData}
        defaultValue={optionsData.find((option) => option.value === match)}
        isSearchable
        // isClearable
        styles={customStyles}
        onChange={(selectedOption) => setGetBrand(selectedOption)}
      />
    </>
  );
};

export default SelectBrand;
