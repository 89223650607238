import { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import { useTheme, useMediaQuery } from "@mui/material";

// Initialize Highcharts modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

const StackChartT = (props) => {
  // const [isClicked, setisClicked] = useState(false);
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));

  const chartRef = useRef(null);
  // console.log("props.stackSeries: ", props.stackSeries);
  // console.log("props.divHeight stack chart: ", props.divHeight);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.redraw();
    }
  }, [props.stackSeries, props.divHeight]);

  const divRef = useRef(null);

  useEffect(() => {
    const chart = divRef.current.chart;
    if (chart) chart.reflow();

    // return () => chart.destroy();
  }, [props.campData]);

  useEffect(() => {
    Highcharts.chart(divRef.current, {
      credits: {
        enabled: false,
      },
      rangeSelector: {
        selected: 1,
        inputEnabled: true,
      },
      exporting: {
        enabled: false,
      },
      chart: {
        zoomType: "xy",
        type: "column",
        // backgroundColor: "#1c2427",
        backgroundColor: "black",
        height: props.divHeight - 34,
        resetZoomButton: {
          theme: {
            fill: "",
            stroke: "#d6ff41",
            style: {
              color: "white",
            },
          },
        },
      },
      title: {
        text: props.charttitle,
        style: {
          color: "#D6FF41",
          fontSize: "14px",
        },
      },

      // tooltip: {
      //   pointFormat: props.toggle
      //     ? '<span style="color:black">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>'
      //     : '<span style="color:black">{series.name}</span>: <b>{point.y}%</b><br/>',
      // },

      legend: {
        enabled: props.stackSeries.show_legend,
        layout: "horizontal", // Set the layout to vertical
        align: "right", // Align the legend to the right
        verticalAlign: "top", // Center the legend vertically
        itemStyle: {
          color: "#FFFFFF", // Set the color of legend items
        },
      },
      xAxis: {
        categories: props.stackCategories,
        title: props.xtitle,
        crosshair: false,
        labels: {
          style: {
            color:
              localStorage.getItem("CurrentTheme") === "Dark"
                ? "#FFFFFF"
                : "#1C2427",
          },
        },
      },
      yAxis: {
        // min: 0,
        min: null,
        max: props.stackSeries.showtextbox === 1 ? 100 : null,
        gridLineWidth: 0,
        // tickInterval: 0.5,
        // gridLineColor: "#7d9ca6",
        // gridLineDashStyle: "ShortDash",
        visible: true,
        lineWidth: 1,
        title: {
          text: props.stackSeries.ytitle,
          style: {
            color: "white",
            fontSize: "14px",
          },
        },
        // stackLabels: {
        //   enabled: true,
        //   style: {
        //     fontWeight: 'bold',
        //     color: 'white'
        //   }
        // },
        labels: {
          style: {
            color:
              localStorage.getItem("CurrentTheme") === "Dark"
                ? "#FFFFFF"
                : "#1C2427",
          },
        },
      },
      // legend: {
      //   // enabled: props.showLegend,
      //   enabled: true,
      //   // enabled: true,
      // },
      plotOptions: {
        column: {
          stacking: props.toggle ? "percent" : "normal",
          dataLabels: {
            enabled: false,
            format: "{point.percentage:.0f}%",
            style: {
              textOutline: "none", // Remove stroke
            },
          },
          borderWidth: 0,
          // dataLabels: {
          //   enabled: false,
          // },
          point: {
            events: {
              click: function () {
                // console.log("clickedddddddddddd: ", this.series.name);

                // props.DrillDown(this);
                if (!props.NotAllow.includes(this.series.name)) {
                  props.DrillDown(this);
                }
              },
              mouseOver: function () {
                // props.NotAllow.map((item) => {
                //   if (this.series.name === item) {
                //     // console.log(this.series.name, item);
                //     this.graphic.element.style.cursor = "not-allowed";
                //   } else {
                //     this.graphic.element.style.cursor = "pointer";
                //   }
                //   return "";
                // });

                if (props.NotAllow.includes(this.series.name)) {
                  this.graphic.element.style.cursor = "not-allowed";
                } else {
                  this.graphic.element.style.cursor = "pointer";
                }
              },
              mouseOut: function () {
                this.graphic.element.style.cursor = "default";
              },
              // function
            },
          },
        },
      },
      series: props.stackSeries.series,
      tooltip: {
        formatter: function() {
          var series = this.series;
          console.log("series ",series);
          var spends = series.userOptions.kpi_value[this.point.index];
          var contribution = series.userOptions.kpi_abs[this.point.index];
          return '<b>' + this.point.category + '</b><br/>' +
                 series.name + ': ' + this.y + '% | ' +
                //  'kpi_value: ' + spends + '<br>' +
                  contribution
        }
      },  
    });
  }, [
    props.stackSeries,
    props.divHeight,
    props.stackCategories,
    props.toggle,
    props.isClicked,
    // props
  ]);

  return (
    <div
    // onClick={() => {
    //   setisClicked(!isClicked);
    // }}
    >
      {!props.isClicked && (isSmall || isMedium) ? (
        <div
          id="container"
          ref={divRef}
          style={{
            // height: "100%",
            // border:"2px solid red"

            height: "100%",
            // width: (isMedium||isSmall)?"700px":"",
            // overflow:(isMedium||isSmall)?"scroll":"",
            // border:"2px solid red"
          }}
        ></div>
      ) : (
        <div
          id="container"
          ref={divRef}
          style={{
            // height: "100%",
            // border:"2px solid red"

            height: "100%",
            width: isMedium || isSmall ? "700px" : "",
            overflow: isMedium || isSmall ? "scroll" : "",
            // border:"2px solid red"
          }}
        ></div>
      )}
    </div>
  );
};

export default StackChartT;
