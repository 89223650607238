import React, { useEffect, useRef, useState } from "react";
import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import Card from "./Card";
import "./CustomSlider.css";
import { useTheme, useMediaQuery } from "@mui/material";

const SplideCarousel = ({ cards }) => {
  const themes = useTheme();
  const isMedium = useMediaQuery(themes.breakpoints.down("md"));
  const isSmall = useMediaQuery(themes.breakpoints.down("sm"));
  const [firstClick, setFirstClick] = useState(false);
  const splideRef = useRef(null);
  const splideInstanceRef = useRef(null);
  // const [isClicked,setisClicked] = useState(true)
  useEffect(() => {
    if (splideRef.current) {
      const splide = new Splide(splideRef.current, {
        type: "loop",
        drag: "free",
        focus: "center",
        gap: "1rem",
        perPage: isMedium || isSmall ? 1 : 3,
        autoScroll: {
          speed: 1,
        },
      });

      // Add auto-scroll extension
      splide.mount({ AutoScroll });

      splideInstanceRef.current = splide;

      splide.on("moved", () => {
        // Ensure auto-scroll is playing after navigation
        splideInstanceRef.current.Components.AutoScroll.play();
      });

      // Pause auto-scroll when the slider container is clicked
      splideRef.current.addEventListener("click", handleSliderClick);

      // Add event listeners to navigation buttons
      const navigationButtons =
        splideRef.current.querySelectorAll(".splide__arrow");
      navigationButtons.forEach((button) => {
        button.addEventListener("click", handleNavigationClick);
      });

      return () => {
        splide.destroy();
        // splideRef.current.removeEventListener("click", handleSliderClick);
        navigationButtons.forEach((button) => {
          button.removeEventListener("click", handleNavigationClick);
        });
      };
    }
  }, [cards]);

  // useEffect(()=>{
  //   handleSliderClick()
  // },[firstClick])

  const handleSliderClick = () => {
    console.log("clicked");
    const autoScroll = splideInstanceRef.current.Components.AutoScroll;

    if (firstClick) {
      autoScroll.pause();
      // setFirstClick(false);
      console.log("first clicked");
    } else {
      if (!autoScroll.isPaused()) {
        console.log("pause");
        autoScroll.pause();
      } else {
        console.log("play");
        autoScroll.play();
      }
    }
  };

  const handleNavigationClick = () => {
    const autoScroll = splideInstanceRef.current.Components.AutoScroll;
    if (autoScroll.isPaused()) {
      autoScroll.play();
    }
  };

  return (
    <div className="splide" ref={splideRef} style={{ width: "100%" }}>
      <div className="splide__track">
        <ul className="splide__list">
          {cards?.map((card, index) => {
            return (
              <li
                className="splide__slide"
                key={index}
                style={{
                  padding: isSmall || isMedium ? "5%" : "",
                }}
              >
                <Card
                  header1={card.header1}
                  value={card.value}
                  header2={card.header2}
                  percent={card.percent}
                  width={isSmall || isMedium ? "" : "160px"}
                  height={isSmall || isMedium ? "" : "160px"}
                  absval={card.absval}
                  header3={card.header3}
                  basevalue={card.basevalue}
                  marginLeft={1}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(SplideCarousel);
