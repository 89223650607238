import React, { useState } from "react";
// import Vector from "../../images/vector7.png";
import { useNavigate } from "react-router";

const Brand = (props) => {
  // console.log(props.data);
  const navigate = useNavigate();
  const [KPI, setKPI] = useState(props.data.kpi[0].kpi_short_code);
  const [tag1, settag1] = useState(props.data.kpi[0].tag1);
  const [tag_unit1, settag_unit1] = useState(props.data.kpi[0].tag_unit1);
  const [tag_val1, settag_val1] = useState(props.data.kpi[0].tag_val1);
  const [tag2, settag2] = useState(props.data.kpi[0].tag2);
  const [tag_unit2, settag_unit2] = useState(props.data.kpi[0].tag_unit2);
  const [tag_val2, settag_val2] = useState(props.data.kpi[0].tag_val2);
  const [analytics, setAnalytics] = useState(props.data.kpi[0].analysis_tools);
  const [Tool, setTool] = useState(props.data.kpi[0].analysis_tools[0].key);
  // console.log(analytics);
  const KPIHandler = (e) => {
    const value = e.target.value;
    var SelectedKPI = null;
    for (var i = 0; i < props.data.kpi.length; i++) {
      if (value === props.data.kpi[i].kpi_short_code) {
        SelectedKPI = i;
        break;
      }
    }

    settag1(props.data.kpi[SelectedKPI].tag1);
    settag_unit1(props.data.kpi[SelectedKPI].tag_unit1);
    settag_val1(props.data.kpi[SelectedKPI].tag_val1);
    settag2(props.data.kpi[SelectedKPI].tag2);
    settag_unit2(props.data.kpi[SelectedKPI].tag_unit2);
    settag_val2(props.data.kpi[SelectedKPI].tag_val2);
    setAnalytics(props.data.kpi[SelectedKPI].analysis_tools);
    setKPI(value);
    props.setSelectedBrands(props.data.brand_short_code)
  };

  const SubmitAnalyse = () => {

    var KPIName = '';
    for (var i = 0; i < props.data.kpi.length; i++) {
      if (KPI === props.data.kpi[i].kpi_short_code) {
        KPIName = props.data.kpi[i].kpi_display_name;
        break;
      }
    }

    const Analyse = {
      KPI: KPI,
      KPIName: KPIName,
      AnalyticsTool: Tool,
      Brand: props.data.brand_short_code,
      BrandName: props.data.brand_display_name,
      Menubar: analytics,
    };
    localStorage.setItem("Analyse", JSON.stringify(Analyse));
    navigate("/" + Tool);
  };

  return (
    <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
      <div className="card">
        <div className="card-body welcome-brand-card" style={(props.data.brand_short_code === props.SelectedBrands) ? { background: 'radial-gradient(66.07% 63.32% at 50% -45.72%, rgba(220, 255, 91, 0.7) 0%, rgba(28, 36, 39, 0) 100%)' } : {}}>
          <div className="row mb-2">
            <div className="col-sm-5">
              <span className="text-primary rounded-3">
                <img
                  src={props.data.brand_logo}
                  className="card-brand-img"
                  alt={props.data.brand_display_name}
                />
              </span>
            </div>
            <div className="col-sm-7 p-0">
              <div className="flex-grow-1">
                <h4 className="mb-2 theme-color card-brand-name">
                  {props.data.brand_display_name}
                </h4>
              </div>
            </div>
          </div>
          <div className="row pt-2">
            <div className="col-sm-5 b-right">
              <div className="brand-exp-txt">
                <p className="m-0">
                  <u> {tag1}</u>
                </p>
                <p className="m-0">
                  {tag_val1} {tag_unit1}
                </p>
              </div>
            </div>
            <div className="col-sm-7">
              <div className="brand-exp-txt">
                <p className="m-0">
                  <u> {tag2}</u>
                </p>
                <p className="m-0">
                  {/* <img src={Vector} className="p-1" alt="" /> */}
                  {tag_val2} {tag_unit2}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group mb-3 row">
                <div className="col-12">
                  <h3>
                    <br />
                    <label
                      className="login-lable brand-menu-label m-0"
                      htmlFor="select-example"
                    >
                      Select KPI
                    </label>
                  </h3>
                  <select
                    className="form-select"
                    value={KPI}
                    onChange={KPIHandler}
                  >
                    {props.data.kpi.map((item) => {
                      return (
                        <option
                          value={item.kpi_short_code}
                          key={item.kpi_short_code}
                        >
                          {item.kpi_display_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group mb-3 row">
                <div className="col-12">
                  <h3>
                    <label
                      className="login-lable brand-menu-label m-0"
                      htmlFor="select-example"
                    >
                      Select Analytics Tool
                    </label>
                  </h3>
                  <select
                    id="select-example"
                    className="form-select"
                    aria-label="Default select example"
                    value={Tool}
                    onChange={(e) => {
                      setTool(e.target.value)
                      props.setSelectedBrands(props.data.brand_short_code)
                    }}
                  >
                    {analytics.map((item, index) => {
                      return (
                        <option key={index} value={item.key}>
                          {item.value}
                        </option>
                      );
                    })}
                    {/* <option value="">Lorem Ipsum </option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option> */}
                  </select>
                </div>
              </div>
              <div className="form-group text-center row ">
                <div className="col-12 mt-3">
                  <button
                    className="btn brand-btn-info"
                    type="button"
                    onClick={SubmitAnalyse}
                  >
                    Analyse
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brand;
