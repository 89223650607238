import { useEffect, useState } from "react";
 
import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import baseURL from "../../BaseURL2";
 
import Header from "./Header";
import Body from "./Body";
 
import {
  Stack,
  Box,
  useTheme,
  useMediaQuery,
  CssBaseline,
  Typography,
} from "@mui/material";
 
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
 
import Loader from "./Loader";
 
let theme = createTheme({
  palette: {
    background: {
      default: "black",
    },
    themeColor: "#d6ff41",
    bgColor: "#1c2427",
    borderColor: "#3E5056",
  },
 
  typography: {
    fontFamily: ["Inter"].join(","),
  },
 
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
 
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "26px", // Set default size for all icons
        },
      },
    },
  },
});
 
theme = responsiveFontSizes(theme);
 
const Main = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [disabledFlag, setDisabledFlag] = useState(true);
 
  const [fetchCall, setFetchCall] = useState(true);
 
  // states for timeperiod
  const [defaultStartDate, setDefaultStartDate] = useState("");
  // console.log("defaultStartDate: ", defaultStartDate);
  const [defaultEndDate, setDefaultEndDate] = useState("");
  // console.log("defaultEndDate: ", defaultEndDate);
  const [endDate, setEndDate] = useState("");
  // console.log("endDate: ", endDate);
  const [startDate, setStartDate] = useState("");
  // console.log("startDate: ", startDate);
 
  const [checkedList, setCheckedList] = useState([]);
 
  const [cascaderKPIData,setCascaderKPIData]=useState([]);
  const[CascasderDefaultValue,setCascaderDefaultValue]=useState("");
  const[getBrand,setGetBrand]=useState("");
  const [geoData, setGeoData] = useState([]);
 
  // post timeperiod
  useEffect(() => {
    // console.log("time period called")
    const fetchData = async () => {
      try {
        const response = await axios.post(`timeperiod/`, {
          tabid: "nonipl",
        });
        const data = await response.data; // Use await to get the data
        // console.log(data);
        setDefaultStartDate(data.data.timeperiods[0].defaultstartdate);
        setDefaultEndDate(data.data.timeperiods[0].defaultenddate);
        setStartDate(data.data.timeperiods[0].startdate);
        setEndDate(data.data.timeperiods[0].enddate);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
 
    fetchData();
  }, []);
 
 
  useEffect(()=>{
    const fetchGeoData = async () =>{
      try{
        const response = await axios.post(`geo_filter/`,{});
        setGeoData(response.data.data);
      }
      catch(err){
        console.log(err);
      }
    }
 
    // fetchGeoData();
   
  },[])
 
  // kpi
 
  // get kpi filters option
  // kpiFilterList,
  // setDefaultKpiFilter2,
  // setDefauLabelKpiFilter2,
  // defaulLabelKpiFilter2,
  // dropDownClick,
  // setDropDownClick,
 
  const [dropDownClick, setDropDownClick] = useState(false);
  const [kpiFilterList1, setKpiFilterList1] = useState([]);
  const [defaultKpiFilter1, setDefaultKpiFilter1] = useState("");
  // console.log("defaultKpiFilter1: ", defaultKpiFilter1);
 
  const [defaultKpiFilter2, setDefaultKpiFilter2] = useState("");
  // console.log("defaultKpiFilter2: ", defaultKpiFilter2);
  const [kpiFilterList2, setKpiFilterList2] = useState([]);
  const [defaulLabelKpiFilter2, setDefauLabelKpiFilter2] = useState("");
 
  // console.log("defaulLabelKpiFilter2: ", defaulLabelKpiFilter2);
 
  useEffect(() => {
    function getKPIsByValue(value) {
      const selectedData = kpiFilterList1.find((item) => item.value === value);
      return selectedData ? selectedData.kpis : [];
    }
 
    // Example usage:
    setDefaultKpiFilter2(getKPIsByValue(defaultKpiFilter1)[0]?.value);
    setDefauLabelKpiFilter2(getKPIsByValue(defaultKpiFilter1)[0]?.label);
    setKpiFilterList2(getKPIsByValue(defaultKpiFilter1)); // Output: [{ value: 'install', label: 'Downloads' }, { value: 'dtl', label: 'Install to Leads%' }]
  }, [defaultKpiFilter1]);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`option_kpi/`, {
          tabid: "nonipl",
          geo_filter: checkedList,
        });
        const data = await response.data; // Use await to get the data
        // console.log(data);
        setDefaultKpiFilter1(data.olddata[0].value);
        setKpiFilterList1(data.olddata);
        setCascaderKPIData(data.data);
        setCascaderDefaultValue(data.defaultid);
        setDefaultKpiFilter2(data.olddata[0].kpis[0].value);
        setKpiFilterList2(data.olddata[0].kpis);
 
        // setPrimaryAxisPayload(extractValues(data.kpi.all_value));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
 
    fetchData();
  }, [checkedList]);
 
  // baseline chart
 
  const [baseLineData, setBaseLineData] = useState([]);
  const [baseLineSeries, setBaseLineSeries] = useState([]);
 
  // tab names
  const [activeTabName, setActiveTabName] = useState("baseshift");
  const [toggle, setToggle] = useState(false);
 
  const [isTooltip, setIsTooltip] = useState(true);
 
 
  useEffect(() => {
    const fetchData = async () => {
      if (
        defaultStartDate &&
        defaultEndDate &&
        CascasderDefaultValue &&
        fetchCall &&
        isSubmit &&
        checkedList
      ) {
        try {
          setIsLoading(true);
          const response = await axios.post(`newbaselinechart/`, {
            kpi: getBrand?.id?.split("-")?.pop() || CascasderDefaultValue?.split("-")?.pop(),
            match_id: "All Matches",
            geo_filter:checkedList,
            startdate: defaultStartDate,
            enddate: defaultEndDate,
            team1: "All Teams",
            weekend_flag: "All Days",
            city: "All Locations",
            time: "All Dayparts",
            tabid: activeTabName,
            togid:
              activeTabName === "brandcarryover"
                ? toggle
                  ? "percent"
                  : "absolute"
                : "",
          });
          const data = await response.data;
          // console.log("data of baseline");
          setBaseLineData([data]);
          setIsTooltip(true);
          const filterData = data.series.map((ele) => {
            return {
              name: ele.name,
              type: ele.type,
              data: ele.data,
              tooltip: ele.tooltip,
            };
          });
          setBaseLineSeries(filterData);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      }
    };
 
    fetchData();
  }, [
    defaultStartDate,
    defaultEndDate,
    CascasderDefaultValue,
    getBrand,
    fetchCall,
    isSubmit,
    activeTabName,
    checkedList,
    toggle,
  ]);
 
  function flattenDataToObject(data) {
    let result = {};
 
    function flatten(item) {
      result[item?.id] = item?.name;
      if (item?.children) {
        item?.children?.forEach(flatten);
      }
    }
 
    data?.forEach(flatten);
    return result;
  }
 
  // const flattenedData = ;
  function getTeaName(id, flattenedData=flattenDataToObject(cascaderKPIData)) {
    console.log("getTeaName ",flattenedData);
    return flattenedData[id] || id; // Directly return the name for the given ID
  }
 
  return (
    <>
      {isLoading ? <Loader /> : null}
      <Menubar />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div
          style={{
            position: "relative",
            top: 20,
            height: "calc(100vh - 60px)",
            width: "100%",
          }}
        >
          <Stack
            justifyContent={"space-between"}
            sx={{ height: "100%", width: "100%" }}
          >
            <Header
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            setGetBrand={setGetBrand}
            geoData={geoData}
            CascasderDefaultValue={CascasderDefaultValue}
            cascaderKPIData={cascaderKPIData}
              startDate={startDate}
              endDate={endDate}
              defaultStartDate={defaultStartDate}
              setDefaultStartDate={setDefaultStartDate}
              defaultEndDate={defaultEndDate}
              setDefaultEndDate={setDefaultEndDate}
              // kpi
              dropDownClick={dropDownClick}
              setDropDownClick={setDropDownClick}
              kpiFilterList={kpiFilterList1}
              defaultKpiFilter1={defaultKpiFilter1}
              setDefaultKpiFilter2={setDefaultKpiFilter2}
              defaultKpiFilter2={defaultKpiFilter2}
              setDefauLabelKpiFilter2={setDefauLabelKpiFilter2}
              defaulLabelKpiFilter2={defaulLabelKpiFilter2}
              setFetchCall={setFetchCall}
              disabledFlag={disabledFlag}
              setDisabledFlag={setDisabledFlag}
              setIsSubmit={setIsSubmit}
              activeTabName={activeTabName}
              setActiveTabName={setActiveTabName}
              setToggle={setToggle}
            />
            <Body
              baseLineData={baseLineData}
              getBrand={getBrand || CascasderDefaultValue}
              CascasderDefaultValue={CascasderDefaultValue}
              baseLineSeries={baseLineSeries}
              setDropDownClick={setDropDownClick}
              defaulLabelKpiFilter2={defaulLabelKpiFilter2}
              setActiveTabName={setActiveTabName}
              activeTabName={activeTabName}
              toggle={toggle}
              getTeaName={getTeaName}
              setToggle={setToggle}
              isTooltip={isTooltip}
              setIsTooltip={setIsTooltip}
            />
          </Stack>
        </div>
      </ThemeProvider>
    </>
  );
};
 
export default Main;
 
 
 