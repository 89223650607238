import React, { useState} from 'react'
import Piechart from './Piechart'

const SummaryAdstock = (props) => {
    const [FilterType, setFilterType] = useState(true)

    return (
        <>
            <div className="col-xl-3" >
                <div className="card custom-summary mb-0" style={{ height: props.DivHeight + "px" }}>
                    <div className="card-body p-0 card-min-height" style={{height: "100%",}}>
                        <div className="card-3" style={{height: "100%", }}>
                            {
                                <div className="custom-header mb-1" style={{height: "10%", }}>
                                    <span className="header-title cursor-pointer" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} onClick={() => parseInt(props.ShowSummary) === 1 ? setFilterType(!FilterType) : ""}>
                                        <h3 className="data-spends my-2 px-2"> Indirect Effect</h3>
                                        {
                                            FilterType ? <h3 className="m-0"><i className="fa fa-minus" aria-hidden="true"></i>&nbsp;</h3> : parseInt(props.ShowSummary) === 1 ? <h3 className="m-0"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;</h3> : null
                                        }
                                    </span>
                                </div>
                            }

                            {
                                parseInt(props.ShowSummary) === 1 ?
                                    <div className={!FilterType ? "custom-footer active mb-1" : "custom-footer mb-1"} style={{ height: "10%"}}>
                                        <span className="footer-title cursor-pointer" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} onClick={() => setFilterType(!FilterType)}>
                                            <h3 className="data-spends my-2 px-2">Summary</h3>
                                            {
                                                !FilterType ? <h3 className="m-0"><i className="fa fa-minus" aria-hidden="true"></i>&nbsp;</h3> : <h3 className="m-0"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;</h3>
                                            }
                                        </span>
                                    </div> : null
                            }

                            {
                                FilterType ?

                                    <div className="custom-body px-3 py-1" style={{height: parseInt(props.ShowSummary) === 0 ?  "90%" : "78%"}}>
                                        <div className="row" style={{height: "100%",  }}>
                                            <div className="col-sm-12">
                                                <h3 className="data-spends my-2">{props.Summary.id}</h3>
                                                {props.Summary.self &&
                                                    JSON.stringify(props.Summary.self) !== "{}" ? (
                                                    <ol className='gd-summary-ol' >
                                                        <li>
                                                            Total Contribution: &nbsp;
                                                            <span className='gd-summary-span'
                                                            >
                                                                {props.Summary.y.toFixed(2)}%
                                                            </span>
                                                            <br />
                                                        </li>
                                                        <li style={{ marginTop: "5px" }}>
                                                            Total Direct Contribution: &nbsp;
                                                            <span className='gd-summary-span'>
                                                                {(
                                                                    (props.Summary.self.value * 100) /
                                                                    props.Summary.y
                                                                ).toFixed(2)}
                                                                % ({props.Summary.self.value.toFixed(2)}% of{" "}
                                                                {props.Summary.y.toFixed(2)}%)
                                                            </span>
                                                            <br />
                                                        </li>
                                                        <li style={{ marginTop: "5px" }}>
                                                            Total InDirect Contribution: &nbsp;
                                                            <span className='gd-summary-span'>
                                                                {(
                                                                    100 -
                                                                    (props.Summary.self.value * 100) / props.Summary.y
                                                                ).toFixed(2)}
                                                                % (
                                                                {(props.Summary.y - props.Summary.self.value).toFixed(
                                                                    2
                                                                )}
                                                                % of {props.Summary.y.toFixed(2)}%)
                                                            </span>
                                                            <br />
                                                        </li>
                                                    </ol>
                                                ) : (
                                                    <p
                                                        className='spends-list-li'
                                                    >
                                                        {props.AlternateNotes}

                                                        Indirect effect not applicable.
                                                        <br />
                                                    </p>
                                                )}
                                            </div>

                                            <div className="row align-items-center justify-content-sm-between" style={{height: "80%", }}>
                                                {props.Channel.length ? (
                                                    <div style={{height: "100%"}}>
                                                        <div className="col-sm-12" style={{height: "100%",}}>
                                                            <span
                                                                className="cursor-pointer text-white chart-zoom-icon"
                                                                title="Full Screen"
                                                                onClick={() => props.setShowPopup(true)}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="currentColor"
                                                                    className="bi-fullscreen"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
                                                                </svg>
                                                            </span>
                                                            <Piechart
                                                                Channel={props.Channel}
                                                                SelectedLabel={props.SelectedLabel}
                                                                height={"100%"}
                                                                Unit={props.Unit}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    :

                                    parseInt(props.ShowSummary) === 1 ?

                                        <div className="custom-body px-3 py-1" style={{height: "75%"}}>
                                            <div className="row" style={{height: "100%"}}>
                                                <div className="col-sm-12" style={{height: "100%"}}>
                                                    <div className="p-0" style={{ height: "100%",  }}>
                                                        <ul>
                                                            {
                                                                props.SummaryFetched.map((item, index) => {
                                                                    return (
                                                                        <li key={index} className="spends-list-li">{item}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SummaryAdstock;