import React, { useEffect, useRef } from "react";
import "ol/ol.css";
import { Map, View, Overlay } from "ol";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { XYZ } from "ol/source";
import { fromLonLat, transformExtent } from "ol/proj";
import { Feature } from "ol";
import { Point } from "ol/geom";
import { Vector as VectorSource } from "ol/source";
import { createEmpty, extend } from "ol/extent";
import { Style, Icon, Text, Fill, Stroke } from "ol/style";
import GeoJSON from "ol/format/GeoJSON";
 
// India GeoJSON boundary data (simplified for performance)
const indiaGeoJSON = {
  type: "Feature",
  geometry: {
    type: "Polygon",
    coordinates: [
      [
        [77.837451, 35.494009],
        [78.169367, 35.011419],
        [78.458446, 34.821857],
        [78.912269, 34.321936],
        [78.811086, 33.506198],
        [79.208892, 32.994395],
        [79.176129, 32.483148],
        [78.458446, 32.618164],
        [78.738894, 31.515906],
        [79.121094, 31.210718],
        [79.721367, 30.882715],
        [80.324896, 30.585164],
        [81.111256, 30.183481],
        [80.476721, 29.729865],
        [80.088425, 28.794434],
        [81.057203, 28.416095],
        [81.999987, 27.925479],
        [82.671219, 27.647420],
        [83.304249, 27.364506],
        [84.012695, 27.254629],
        [84.675018, 27.234901],
        [85.251779, 26.726198],
        [85.866699, 26.667095],
        [86.024393, 26.630985],
        [86.742188, 26.488704],
        [87.227472, 26.397898],
        [87.561035, 26.382028],
        [88.060238, 26.414615],
        [88.174804, 26.810405],
        [88.043133, 27.445819],
        [88.120441, 27.876542],
        [88.730326, 28.086865],
        [88.814248, 27.299316],
        [88.835643, 27.098966],
        [89.744528, 26.719403],
        [90.373275, 26.875724],
        [90.966797, 26.825230],
        [91.217513, 26.808648],
        [91.669922, 26.824071],
        [92.033484, 26.83831],
        [92.103712, 27.452614],
        [91.696657, 27.771742],
        [92.503119, 27.896876],
        [93.413348, 28.640629],
        [94.56599, 29.277438],
        [95.404802, 29.031717],
        [96.117679, 29.452802],
        [96.586591, 28.83098],
        [96.248833, 28.411031],
        [97.327114, 28.261583],
        [97.402561, 27.882536],
        [97.051989, 27.699059],
        [97.133999, 27.083774],
        [96.419366, 27.264589],
        [95.124768, 26.573572],
        [95.155153, 26.001307],
        [94.603249, 25.162495],
        [94.552658, 24.675238],
        [94.106742, 23.850741],
        [93.325188, 24.078556],
        [93.286327, 23.043658],
        [93.060294, 22.703111],
        [93.166128, 22.27846],
        [92.672721, 22.041239],
        [92.146035, 23.627499],
        [91.869928, 23.624346],
        [91.706475, 22.985264],
        [91.158963, 23.503527],
        [91.46773, 24.072639],
        [91.915093, 24.130414],
        [92.376202, 24.976693],
        [91.799596, 25.147432],
        [90.872211, 25.132601],
        [89.920693, 25.26975],
        [89.832481, 25.965082],
        [89.355094, 26.014407],
        [88.563049, 26.446526],
        [88.209789, 25.768066],
        [88.931554, 25.238692],
        [88.306373, 24.866079],
        [88.084422, 24.501657],
        [88.69994, 24.233715],
        [88.52977, 23.631142],
        [88.876312, 22.879146],
        [89.031961, 22.055708],
        [88.888766, 21.690588],
        [88.208497, 21.703172],
        [87.505371, 21.584305],
        [86.975704, 21.495562],
        [87.033169, 20.743308],
        [86.499351, 20.151638],
        [85.739746, 19.766703],
        [85.060266, 19.478579],
        [84.462891, 18.854310],
        [83.941006, 18.30201],
        [83.189217, 17.671221],
        [82.192792, 17.016636],
        [82.191242, 16.556664],
        [81.692719, 16.310219],
        [81.166992, 16.109332],
        [80.791999, 15.951972],
        [80.324896, 15.899185],
        [80.025069, 15.136415],
        [80.233274, 13.835771],
        [80.286294, 13.006261],
        [79.862547, 12.056215],
        [79.857999, 10.357275],
        [79.340512, 10.308854],
        [78.885345, 9.546136],
        [79.18972, 9.216544],
        [78.277941, 8.933047],
        [77.941165, 8.252959],
        [77.539898, 7.965535],
        [76.992188, 8.407168],
        [76.592979, 8.899276],
        [76.130061, 10.29963],
        [75.959473, 10.833306],
        [75.746467, 11.308251],
        [75.396101, 11.781245],
        [75.090332, 12.297068],
        [74.864816, 12.741936],
        [74.616717, 13.992583],
        [74.443859, 14.617222],
        [73.969727, 15.284185],
        [73.534199, 15.990652],
        [73.300781, 16.972741],
        [73.119909, 17.92857],
        [72.949219, 18.979026],
        [72.820909, 19.208234],
        [72.824475, 20.419503],
        [72.630533, 21.356009],
        [71.850586, 21.002471],
        [71.175273, 20.757441],
        [70.470459, 20.877331],
        [69.741211, 21.453069],
        [69.16413, 22.089298],
        [69.644928, 22.450775],
        [69.349597, 22.84318],
        [68.774414, 23.241346],
        [68.176645, 23.691965],
        [68.842599, 24.359134],
        [71.04324, 24.356524],
        [70.844699, 25.215102],
        [70.282873, 25.722229],
        [70.168927, 26.491872],
        [69.514393, 26.940966],
        [70.037842, 27.514162],
        [70.616496, 27.989196],
        [71.777666, 27.91318],
        [72.358398, 28.431886],
        [72.823752, 28.961592],
        [73.450638, 29.976413],
        [74.069824, 30.524413],
        [74.42138, 30.979815],
        [74.405929, 31.692639],
        [74.816895, 32.026706],
        [75.258642, 32.271105],
        [74.451559, 32.7649],
        [74.104294, 33.441473],
        [73.749948, 34.317699],
        [74.240203, 34.748887],
        [75.090332, 34.669359],
        [75.757061, 34.504923],
        [76.396484, 34.560859],
        [76.871722, 34.653544],
        [77.387695, 35.103999],
        [77.837451, 35.494009]
      ]
    ]
  }
};
 
// Test Data
// const stateData = {
//   "uttar pradesh": {
//     cities: [
//       {
//         name: "agra",
//         value: 23,
//         closure: "23.0  Closures",
//         coordinates: [78.008072, 27.17667],
//         cities: [],
//       },
//       {
//         name: "ayodhya",
//         value: 0,
//         closure: "0.0  Closures",
//         coordinates: [82.198555, 26.787964],
//         cities: [],
//       },
//       {
//         name: "lucknow",
//         value: 1089,
//         closure: "1089.0  Closures",
//         coordinates: [80.946167, 26.846695],
//         cities: [],
//       },
//     ],
//     value: 1227,
//     coordinates: [80.946159, 26.846708],
//     closure: "1227.0  Closures",
//   },
// };
 
const IndiaMap = ({ view,stateData }) => {
  const mapRef = useRef(null);
  const tooltipRef = useRef(null);
 
  // Helper function to format display values
  const formatValue = (value) => {
    if (value === null) return "N/A";
    if (value === "") return "Not specified";
    if (typeof value === "boolean") return value ? "Yes" : "No";
    if (typeof value === "number") return value.toLocaleString();
    return value;
  };
 
  // Helper function to create tooltip content from filtered properties
  const createTooltipContent = (properties) => {
    const excludedKeys = [
      "geometry",
      "name",
      "type",
      "cities",
      "coordinates",
      "value",
    ];
    const filteredEntries = Object.entries(properties)
      .filter(([key]) => !excludedKeys.includes(key))
      .map(([key, value]) => {
        const formattedKey = key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return `<strong>${formattedKey}:</strong> ${formatValue(value)}`;
      });
 
    return filteredEntries.length > 0
      ? filteredEntries.join("<br>")
      : "No additional information available";
  };
 
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.innerHTML = "";
    }
 
    // Create features based on view
    let initialFeatures;
    if (view === "city") {
      initialFeatures = Object.values(stateData).flatMap((state) =>
        state.cities.map((city) => {
          const cityFeature = new Feature({
            geometry: new Point(fromLonLat(city.coordinates)),
            name: city.name,
            type: "city",
            ...city,
          });
 
          cityFeature.setStyle(
            new Style({
              image: new Icon({
                src: "https://upload.wikimedia.org/wikipedia/commons/e/ec/RedDot.svg",
                scale: city.value * 0.00002,
              }),
              text: new Text({
                text: city.name.charAt(0).toUpperCase() + city.name.slice(1),
                offsetY: -18,
                fill: new Fill({ color: "#fff" }),
                stroke: new Stroke({ color: "#222", width: 3 }),
                font: "14px sans-serif",
              }),
            })
          );
          return cityFeature;
        })
      );
    } else {
      initialFeatures = Object.entries(stateData).map(([stateName, data]) => {
        const feature = new Feature({
          geometry: new Point(fromLonLat(data?.coordinates)),
          name: stateName,
          type: "state",
          cities: data?.cities,
          coordinates: data?.coordinates,
          ...data,
        });
 
        feature.setStyle(
          new Style({
            image: new Icon({
              src: "https://upload.wikimedia.org/wikipedia/commons/e/ec/RedDot.svg",
              scale: data.value * 0.00002,
            }),
            text: new Text({
              text: stateName
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),
              offsetY: -20,
              fill: new Fill({ color: "#fff" }),
              stroke: new Stroke({ color: "#222", width: 3 }),
              font: "14px sans-serif",
            }),
          })
        );
        return feature;
      });
    }
 
    const markerSource = new VectorSource({
      features: initialFeatures,
    });
 
    const indiaExtent = transformExtent(
      [68, 6, 98, 38],
      "EPSG:4326",
      "EPSG:3857"
    );
 
    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new XYZ({
            url: "https://{a-c}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png",
          }),
        }),
        new VectorLayer({
          source: markerSource,
        }),
      ],
      view: new View({
        center: fromLonLat([78.9629, 22.5937]),
        zoom: 4.5,
        extent: indiaExtent,
        constrainOnlyCenter: true, // Prevent panning outside India
        multiWorld: false,
      }),
    });
 
    // const indiaLayer = new VectorLayer({
    //   source: new VectorSource({
    //     features: new GeoJSON().readFeatures(indiaGeoJSON, {
    //       featureProjection: "EPSG:3857",
    //     }),
    //   }),
    //   style: new Style({
    //     stroke: new Stroke({ color: "#fff", width: 1 }),
    //     // fill: new Fill({ color: "rgba(255, 0, 0, 0.1)" }),
    //   }),
    // });
 
    // map.addLayer(indiaLayer);
 
    // Tooltip Overlay
    const tooltipElement = document.createElement("div");
    tooltipElement.style.position = "absolute";
    tooltipElement.style.background = "#1c2427";
    tooltipElement.style.color = "white";
    tooltipElement.style.padding = "8px 12px";
    tooltipElement.style.borderRadius = "6px";
    tooltipElement.style.fontSize = "13px";
    tooltipElement.style.fontWeight = "bold";
    tooltipElement.style.boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.3)";
    tooltipElement.style.display = "none";
    tooltipElement.style.transition = "opacity 0.3s ease-in-out";
    tooltipElement.style.maxWidth = "300px";
    tooltipElement.style.wordWrap = "break-word";
    tooltipRef.current = tooltipElement;
    document.body.appendChild(tooltipElement);
 
    map.on("pointermove", (event) => {
      const feature = map.forEachFeatureAtPixel(event.pixel, (feat) => feat);
 
      if (feature) {
        const properties = feature.getProperties();
        const name = properties.name
          ?.split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        const tooltipContent = createTooltipContent(properties);
 
        tooltipElement.innerHTML = `<strong>${name}</strong><br>${tooltipContent}`;
        tooltipElement.style.display = "block";
        tooltipElement.style.opacity = "1";
        tooltipElement.style.left = `${event.originalEvent.pageX + 10}px`;
        tooltipElement.style.top = `${event.originalEvent.pageY - 10}px`;
      } else {
        tooltipElement.style.opacity = "0";
        setTimeout(() => (tooltipElement.style.display = "none"), 300);
      }
    });
 
    // Click handler for state view
    map.on("click", (event) => {
      if (view !== "state") return;
 
      const feature = map.forEachFeatureAtPixel(event.pixel, (feat) => feat);
      if (feature && feature.get("type") === "state") {
        const cities = feature.get("cities");
 
        if (cities.length === 0) {
          console.log("No cities available for this state");
          return;
        }
 
        let extent = [Infinity, Infinity, -Infinity, -Infinity];
        cities.forEach((city) => {
          const coord = fromLonLat(city.coordinates);
          extent[0] = Math.min(extent[0], coord[0]);
          extent[1] = Math.min(extent[1], coord[1]);
          extent[2] = Math.max(extent[2], coord[0]);
          extent[3] = Math.max(extent[3], coord[1]);
        });
 
        map.getView().fit(extent, {
          duration: 1000,
          padding: [100, 100, 100, 100],
        });
 
        markerSource.clear();
        const cityFeatures = cities.map((city) => {
          const cityFeature = new Feature({
            geometry: new Point(fromLonLat(city.coordinates)),
            name: city.name,
            type: "city",
            ...city,
          });
 
          cityFeature.setStyle(
            new Style({
              image: new Icon({
                src: "https://upload.wikimedia.org/wikipedia/commons/e/ec/RedDot.svg",
                scale: city.value * 0.00002,
              }),
              text: new Text({
                text: city.name.charAt(0).toUpperCase() + city.name.slice(1),
                offsetY: -18,
                fill: new Fill({ color: "#fff" }),
                stroke: new Stroke({ color: "#222", width: 3 }),
                font: "14px sans-serif",
              }),
            })
          );
          return cityFeature;
        });
        markerSource.addFeatures(cityFeatures);
 
        const backButton = document.createElement("button");
        backButton.innerHTML = "Back to States View";
        backButton.className = "back-button";
        backButton.onclick = () => {
          markerSource.clear();
          markerSource.addFeatures(initialFeatures);
          map.getView().animate({
            center: fromLonLat([78.9629, 22.5937]),
            zoom: 4.5,
            extent: indiaExtent,
            duration: 1000,
          });
          backButton.remove();
        };
        mapRef.current.appendChild(backButton);
      }
    });
 
    return () => {
      map.setTarget(null);
      document.body.removeChild(tooltipElement);
    };
  }, [view]);
 
  return (
    <>
      <div
        ref={mapRef}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "12px",
          overflow: "hidden",
          // border: "2px solid rgba(255, 255, 255, 0.2)",
          margin: "auto",
          // boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.5)",
          position: "relative",
        }}
      />
      <style>
        {`
          .back-button {
            position: absolute;
            top: 10px;
            left: 10px;
            padding: 4px 8px;
            background: #1c2427;
            font-size: 14px;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            z-index: 1000;
          }
          .back-button:hover {
            background: #2c3437;
          }
        `}
      </style>
    </>
  );
};
 
export default IndiaMap;
 
 
 