import { useState } from "react"; // Import useState hook
import { Stack, Typography, Box } from "@mui/material";
import ToggleButton from "./ToggleButton";
// import { BsSortDown } from "react-icons/bs";
// import { BsSortDown } from "react-icons/bs";
import { FaSortAlphaDown } from "react-icons/fa";
import { FaSortAlphaUpAlt } from "react-icons/fa";
// import { FaSortAmountDown } from "react-icons/fa";
// import { FaSortAmountDownAlt } from "react-icons/fa";

import { CgSortAz } from "react-icons/cg";
import { CgSortZa } from "react-icons/cg";
import { IoArrowDownOutline } from "react-icons/io5";
// import { FaArrowUpLong } from "react-icons/fa6";
import { IoArrowUp } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import ATOZ from "./AtoZ.png"
import ZTOA from "./ZtoA.png"

const Header = ({
  headerTabNames,
  Length,
  defaultView,
  setClickSort,
  toggleStates,
  handleToggleChange,
  clickSort,
}) => {
  console.log("click sort on header", clickSort);

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 999,
        backgroundColor: "bgColor",
        borderBottom: "1px dashed #3E5056",
        // border:"2px solid red"
        // width:"1000px"
      }}
    >
      <Box position={"relative"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          // width="1000px"
          // border="2px solid green"
        >
          <Box
            sx={{
              width: "10%",
              padding: "1rem 0",
              borderRight: "1px dashed #3E5056",
              // border:"2px solid red"
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontSize: "0.9rem",
                fontWeight: 700,
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              {defaultView}
              {/* ---------------------------------------------- */}
              <span
                onClick={() => {
                  setClickSort({ name: "name", action: "aTOz" });
                }}
                style={{
                  fontSize: "19px",
                  color:
                    clickSort.name === "name" && clickSort.action === "aTOz"
                      ? "green"
                      : "white",
                  cursor: "pointer",
                }}
              >
                {" "}
                <IoArrowDownOutline />{" "}
                {/* <img src={ATOZ} /> */}
              </span>

              <span
                // onClick={() => {
                //   setClickSort({ name: "name", action: "aTOz" });
                // }}
                style={{
                  fontSize: "19px",
                  color:
                    clickSort.name === "name" && clickSort.action === "aTOz"
                      ? "green"
                      : "white",
                  cursor: "pointer",
                }}
              >
                
                {clickSort.action === "zTOa" ? <img src={ZTOA} /> : <img src={ATOZ} />}
                {/* <img src={ATOZ} /> */}
              </span>

              <span
                onClick={() => {
                  setClickSort({ name: "name", action: "zTOa" });
                }}
                style={{
                  fontSize: "19px",
                  color:
                    clickSort.name === "name" && clickSort.action === "zTOa"
                      ? "green"
                      : "white",
                  cursor: "pointer",
                }}
              >
                {" "}
                <IoArrowUp /> {" "}
              </span>
            </Typography>
          </Box>
          {headerTabNames?.map((tabName, index) => {
            const tabArr = tabName.display_name.split(" ");
            const lastName = tabArr[tabArr.length - 1];

            return (
              <Box
                key={index}
                sx={{
                  width: "30%",
                  padding: "1rem 0",
                  borderRight: "1px dashed #3E5056",
                  textAlign: "center",
                  // border:"2px solid green"
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    // border:"2px solid red",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  {tabName.display_name}{" "}
                  <div>
                    <span
                      onClick={() => {
                        setClickSort({
                          name: tabName.name,
                          action: "increasing",
                        });
                      }}
                      style={{
                        fontSize: "19px",
                        color:
                          clickSort.action === "increasing" &&
                          clickSort.name === tabName.name
                            ? "green"
                            : "white",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      <IoArrowUp />{" "}
                    </span>

                    {
                      !(tabName.name===clickSort.name)? <span
                      // onClick={() => {
                      //   setClickSort({
                      //     name: tabName.name,
                      //     action: "decreasing",
                      //   });
                      // }}
                      style={{
                        fontSize: "13px",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      {/* {clickSort.name === tabName.name &&
                      clickSort.action === "decreasing" ? (
                        <CgSortAz />
                      ) : (
                        <CgSortZa />
                      )} */}
                      <RxHamburgerMenu />
                    </span>: <span
                      // onClick={() => {
                      //   setClickSort({
                      //     name: tabName.name,
                      //     action: "decreasing",
                      //   });
                      // }}
                      style={{
                        fontSize: "19px",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      {clickSort.name === tabName.name &&
                      clickSort.action === "decreasing" ? (
                        <CgSortAz />
                      ) : (
                        <CgSortZa />
                      )}
                    </span>
                    }

                    {/* <span
                      // onClick={() => {
                      //   setClickSort({
                      //     name: tabName.name,
                      //     action: "decreasing",
                      //   });
                      // }}
                      style={{
                        fontSize: "19px",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      {clickSort.name === tabName.name &&
                      clickSort.action === "decreasing" ? (
                        <CgSortAz />
                      ) : (
                        <CgSortZa />
                      )}
                    </span> */}
                    <span
                      onClick={() => {
                        setClickSort({
                          name: tabName.name,
                          action: "decreasing",
                        });
                      }}
                      style={{
                        fontSize: "19px",
                        color:
                          clickSort.action === "decreasing" &&
                          clickSort.name === tabName.name
                            ? "green"
                            : "white",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      <IoArrowDownOutline />{" "}
                    </span>
                  </div>
                  {/* <span
                    onClick={() => {
                      setClickSort({name:tabName.name,action:"decreasing"});
                    }}
                    style={{
                      fontSize: "19px",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    <FaSortAmountDown />{" "}
                  </span>
                  <span
                    onClick={() => {
                      setClickSort({name:tabName.name,action:"increasing"});
                    }}
                    style={{
                      fontSize: "19px",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    <FaSortAmountDownAlt />{" "}
                  </span> */}
                  {tabName.edit_flag === 1 && (
                    <span>
                      <ToggleButton
                        checked={toggleStates[index]} // Use state corresponding to current index
                        onChange={() => handleToggleChange(index)} // Pass index to handle function
                      />
                    </span>
                  )}
                </Typography>
              </Box>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};

export default Header;

{
  /* <ToggleButton
            checked={editMode}
            onChange={(e) => setEditMode(e.target.checked)}
          /> */
}
